export const selectorFilterRequests = (requests, filter) => {
   let filtered = []
 
   if (requests && Object.keys(requests).length) {
      Object.keys(requests).forEach(function (i) {
         if(requests[i].status === filter) {
            filtered.push(requests[i])
         }
      })
   } 

   filtered.sort((a,b) => b.dateTimeLastUpdated - a.dateTimeLastUpdated) // b - a for reverse sort
   return filtered
}
