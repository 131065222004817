import { Link } from 'react-router-dom'

import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import IconTwitter from '@mui/icons-material/Twitter'
import IconInstagram from '@mui/icons-material/Instagram'
import IconFacebook from '@mui/icons-material/Facebook'
import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'

function Home({handleNewRequest}) {
   return (
      <Container maxWidth='lg' style={{ padding: 10, marginTop:50, marginBottom:50 }}>
         <Grid container justifyContent='center' spacing={2}>
            <Grid item xs={12} sm={12} md={10} >
               <div style={{textAlign:'center', maxWidth:500, margin:'40px auto'}}>
                  <img src='/images/logo-big.png' style={{width:'100%'}}/>
               </div>
               <Paper elevation={6} style={{border:'2px solid black', maxWidth:700, margin:'0 auto', padding: 20 }} >
                  <Text variant='body1'>
                     Welcome to <b>TellTommi.com</b> - the local auto parts finder.
                     Looking for a part but having a hard time finding it? Tell Tommi what you're looking for and let me get to work! 
                  </Text> 
                  <div style={{maxWidth:550, margin:'0 auto', padding:20}}>
                     <Grid container justifyContent='center' spacing={4}>
                        <Grid item xs={12} sm={6}>
                          <Button fullWidth variant="contained" onClick={handleNewRequest}>Submit A New Request</Button> 
                        </Grid>
                        <Grid item xs={12} sm={6} >  
                          <Button fullWidth variant="outlined" component={Link} to='/feed/requests'>View Previous Requests</Button>
                        </Grid>
                     </Grid>
                  </div>
               </Paper>
               <div style={{maxWidth:700, margin:'0 auto', textAlign:'right'}}>
                  <Button variant='text' component={Link} to='/about' style={{color:'white', textTransform: 'none'}}>Learn More About TellTommi</Button>
               </div>
               <div style={{textAlign:'center', maxWidth:500, margin:'10px auto', marginTop:100}}>
                  <Text variant='h6' color='white'>@TellTommi&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;#TellTommi</Text>
                  <a href='https://www.twitter.com/telltommi' target='_blank'>
                     <IconButton><IconTwitter style={{fontSize:'2em', color:'white'}} /></IconButton>
                  </a>
                  <a href='https://www.instagram.com/telltommi' target='_blank'>
                     <IconButton><IconInstagram style={{fontSize:'2em', color:'white'}} /></IconButton>
                  </a>
                  <a href='https://www.facebook.com/telltommi' target='_blank'>
                     <IconButton><IconFacebook style={{fontSize:'2em', color:'white'}} /></IconButton>
                  </a>
               </div>  
            </Grid>
         </Grid>
      </Container>      
   )
}
export default Home
