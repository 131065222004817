import { useState } from 'react'

import { chkEmail }  from '../../../includes/functions'

import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import LinearProgress from '@mui/material/LinearProgress'
import Stack from '@mui/material/Stack'
import Text from '@mui/material/Typography'
import TextField from '@mui/material/TextField'

function SubmitEmail({handleAction, submitEmail, requestId}) {
   const [step, setStep] = useState('email') // processing, error, email, submitted
   const [email, setEmail] = useState('')
   const [message, setMessage] = useState('')

   const handleChange = (f,v) => {
      f(v)
      setMessage('')
   }
   const handleTryAgain = () => {
      setMessage('') 
      setStep('email')
   } 
   const handleSubmitEmail = (e) => {
      e.preventDefault()
      if (!chkEmail(email)) {
         return setMessage('Sorry, a valid email address is required.')
      }
      setStep('processing')
      submitEmail(email, requestId)
      .then(() => {
         setStep('submitted')
      })
      .catch((err) => {
         setMessage(err.message)
         setStep('error')
      })
   }
   return (
      <div>
         {(step === 'processing') ? (
            <div style={{paddingTop:50, paddingBottom:50, maxWidth:400, margin:'0 auto'}}>
               Processing your request...
               <LinearProgress color='inherit' />
            </div>
         ):( null )}            

         {(step === 'error') ? (
            <div style={{paddingTop:50, paddingBottom:50, maxWidth:400, margin:'0 auto'}}>
               <Alert severity='error'>
                  {message || 'Sorry, an error occured, please try again.'}
               </Alert>
               <div style={{ marginTop:20 }}><Button onClick={() => { handleTryAgain() }} variant='outlined'>try again</Button></div>
            </div>
         ):( null )} 

         {(step === 'email') ? (
            <div style={{paddingTop:20, paddingBottom:20, maxWidth:400, margin:'0 auto'}}>
               <Text variant='h6'>REQUEST SUBMITTED</Text>
               <Text color='text.secondary'> 
                  Your request has been submitted, please enter your email address to receive responses.
               </Text>
               <form onSubmit={handleSubmitEmail}>
                  <Stack spacing={2}>
                     <TextField fullWidth size='small' label='Email Address' type='text' margin='normal'
                        value={email} onChange={(e) => {handleChange(setEmail, e.target.value) }}
                     />
                     <Text style={{ marginTop: 10, color: 'red', height: 15 }} component='p' align='center'>
                        <i>{ message }</i>
                     </Text>
                     <Stack direction='row' spacing={2} justifyContent='flex-end' divider={<Divider orientation='vertical' flexItem />} >
                        <Button onClick={() => { handleAction('cancel') }} color='primary' autoFocus>
                           Cancel
                        </Button>
                        <Button variant='contained' color='primary' type='submit'>
                           submit email address
                        </Button>
                     </Stack>                
                  </Stack>
               </form>
            </div>
         ):( null )} 

         {(step === 'submitted') ? (
            <div style={{paddingTop:20, paddingBottom:20, maxWidth:400, margin:'0 auto'}}>
               <Text variant='h6'>EMAIL SUBMITTED</Text>
               <Text color='text.secondary'> 
                  Your email address has been submitted, please check your email for
                  activation link to begin receiving responses.
               </Text>
               <div style={{textAlign:'right', paddingTop:20}} >
                  <Button onClick={() => handleAction('cancel')} color='primary' autoFocus>
                     Close
                  </Button>
               </div> 
            </div>
         ):( null )} 

      </div>
   )
}
export default SubmitEmail
