import { useEffect, useState } from 'react' 
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

import Loader from '../components/Loader'

import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import Text from '@mui/material/Typography'
import TextField from '@mui/material/TextField'

function SelectBusiness({userInfo, createBusiness, selectBusiness}) {
   const [loading, setLoading] = useState(false)
   const [loadingTitle, setLoadingTitle] = useState('')
   const [loadingText, setLoadingText] = useState('')
   const [businesses, setBusinesses] = useState([]) 
   const [name, setName] = useState('')
   const [message, setMessage] = useState('')
   const navigate = useNavigate()

   useEffect(() => {
      let list = (userInfo.businesses) ? userInfo.businesses : []
      setBusinesses(list)
   },[userInfo])

   const handleChange = (f,v) => {
       f(v)
       setMessage('')
   }
   const handleCreateBusiness = () => {
      if (name === '') {
         return setMessage('Please Enter Business Name')
      }
      Swal.fire({
         title: 'Create Business',
         text: 'Are you sure you would like to create the business - '+ name,
         showCancelButton: true,
         showConfirmButton: true,
         confirmButtonText: 'Create'
      }).then((result) => {
         if (result.isConfirmed) {
            setLoadingTitle('Processing')
            setLoadingText('Creating the Business - ' + name)
            setLoading(true)
            createBusiness(name)
            .then(() => {
               setLoading(false)
               Swal.fire({
                  title: 'Business Created', 
                  text: 'Your business was Created, please complete your business profile.',
                  showConfirmButton: true
               })
               navigate('/business/account')
            })
            .catch((e) => {
               setLoading(false)
               Swal.fire({
                  title: e.title, 
                  text: e.message,
                  showConfirmButton: true
               })
            })
         }
      })
   }
   const handleSelectBusiness = (id) => {
      navigate('/business/select')
      setLoadingTitle('Processing')
      setLoadingText('Processing your selected business...')
      setLoading(true)
      selectBusiness(id)
      .then(() => {
         setLoading(false)
         navigate('/business/feed')
      })
      .catch((e) => {
         setLoading(false)
         Swal.fire({
            title: e.title, 
            text: e.message,
            showConfirmButton: true
         })
      })
   }

   return (
      <div style={{marginTop:20}}>
         <Loader loading={loading} title={loadingTitle} text={loadingText}/>
         <div align='center' style={{margin:0, padding:10, backgroundColor:'#f1f1f1'}}>
            <Text variant='body1' color='textSecondary'><b>No Business Profile Selected</b></Text>
            <Text variant='body2' color='textSecondary'>Select or Create one below</Text>
         </div>
         <div style={{margin:'0 auto', marginTop:20, maxWidth:450, padding:10}}>
            <Text variant='h5'>Select Business</Text>
            {(businesses.length) ? (
               <List>
                  {businesses.map((biz, index) => {
                     return (                        
                        <ListItem 
                           key={index}
                           secondaryAction={
                             <Button onClick={() => { handleSelectBusiness(biz.id) }} size='small'>
                               select
                             </Button>
                           }
                           style={{borderBottom:'2px solid #f1f1f1'}}
                        >
                           <ListItemText 
                              primary={biz.name}
                              primaryTypographyProps={{
                                fontSize: 18,
                                fontWeight: 'normal',
                                lineHeight: '20px',
                                mb: '2px',
                              }}
                           />
                        </ListItem>   
                     )
                  })}
               </List>
            ):(
               <div align='center' style={{margin:0, padding:10, backgroundColor:'#f1f1f1'}}>
                  No Business Created
               </div>
            )}
         </div>
         <div style={{margin:'0 auto', marginTop:20, maxWidth:450, padding:10}}>
            <Text variant='h5'>Create Business</Text>
            <div align='center' style={{margin:0, padding:10, border:'2px solid #f1f1f1'}}>
               <Stack spacing={1} style={{maxWidth:400}}>
                  <TextField fullWidth 
                     id='name' 
                     label='Business Name' 
                     value={name} 
                     onChange={(e) => { handleChange(setName, e.target.value) }}
                  />
                  <div style={{ textAlign:'center'}}><Text variant='body1' style={{color:'red'}}><i>{message}</i></Text></div>
                  <Button onClick={handleCreateBusiness} fullWidth variant='contained'>Create Business</Button>
               </Stack>
            </div>
         </div>
      </div> 
   )
}
export default SelectBusiness
