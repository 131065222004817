import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { respondToRequest } from '../../../redux/business/actions'
import ImageSelector from '../../components/ImageSelector'

import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import IconClose from '@mui/icons-material/Close'
import LinearProgress from '@mui/material/LinearProgress'
import Stack from '@mui/material/Stack'
import Text from '@mui/material/Typography'
import TextField from '@mui/material/TextField'


import Menu from '@mui/material/Menu';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
//import Divid from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconExpand from '@mui/icons-material/ExpandCircleDownOutlined';

function ResponsePopup({ openWindow, setOpenWindow, requestId, loggedIn, businessId, businessProfile, respondToRequest }){
   const [step, setStep] = useState('error') // processing, error, respond
   const [menuEl, setMenuEl] = useState(null)
   const [errorTitle, setErrorTitle] = useState('') 
   const [errorMessage, setErrorMessage] = useState('') 
   const [disableSubmitButton, setDisableSubmitButton] = useState(false)
   const [displayMessage, setDisplayMessage] = useState('Yes we have it.')
   const [responseMessage, setResponseMessage] = useState('Yes we have it.') 
   const [businessCustomMessage, setBusinessCustomMessage] = useState('')
   const [responseStatus, setResponseStatus] = useState('complete') 
   const [expiryDate, setExpiryDate] = useState('') 
   const [optionalImage, setOptionalImage] = useState(false) 

  // [START]: ImageSelector Variables & Function :::::::::
    const [selectedImage, setSelectedImage] = useState('/images/defaultBusinessImage.jpg') // ImageSelector variable
    const images = [ '/images/defaultBusinessImage.jpg' ] // ImageSelector variable
    const handleSelectImage = (src) => {
      setSelectedImage(src)
    }
  // [END]: ImageSelector Variables & Function ::::::::

   useEffect(() => {
      handleStartUp()   
   },[businessProfile])

   const handleStartUp = () => {
      // 1. If businessId not set, close window ........
         if (!businessId) {
            setOpenWindow(false)
         }
      // 2. Set business custom message ................
         if (businessProfile && businessProfile.businessCustomMessage) {
            setDisplayMessage(businessProfile.businessCustomMessage)  
            setResponseMessage(businessProfile.businessCustomMessage) 
            setBusinessCustomMessage(businessProfile.businessCustomMessage)  
         }
      // 3. Disable submit button if subscription is invalid
         //let currentDateTime = Date.now()
         //if (currentDateTime > businessProfile.subscriptionExpiryDate) {
         //   setDisableSubmitButton(true)
         //}
      // 4. setup for response ...........................
         setStep('respond')
   }   

   const handleChange = (f,v) => {
      f(v)
      if(v === ''){
         setDisableSubmitButton(true)
      } else {
         setDisableSubmitButton(false)
      }
   }
   const handleImage = (action) => {
      if(action === 'addImage'){
         setOptionalImage(true)
      } 
      if(action === 'removeImage'){
         setOptionalImage(false)
      }
      console.log(action)
      setSelectedImage('/images/defaultBusinessImage.jpg')
   }
   const handleResponse = (action) => {
      setMenuEl(false)
      if(action === 'business') {
         setDisplayMessage(businessCustomMessage)
         setResponseMessage(businessCustomMessage)
         setDisableSubmitButton(false)
         setResponseStatus('complete')
      }
      if(action === 'default') {
         setDisplayMessage('Yes we have it.')
         setResponseMessage('Yes we have it.')
         setDisableSubmitButton(false)
         setResponseStatus('complete')
      }
      if(action === 'custom') {
         setDisplayMessage('Make a custom response.')
         setResponseMessage('')
         setDisableSubmitButton(true)
         setResponseStatus('engaged')
         setOptionalImage(false)
      }
   }
   const handleSubmitResponse = (message) => {
      let businessResponse = {
         status: responseStatus,
         message: responseMessage,
         image: (selectedImage === '/images/defaultBusinessImage.jpg') ? '' : selectedImage
      }
      console.log(businessResponse)
      setStep('processing')
      respondToRequest(requestId, businessResponse)
      .then(()=> {
         setOpenWindow(false) 
      })
      .catch((e) => {
         setErrorTitle(e.title)
         setErrorMessage(e.message)
         setStep('error') 
      })
   }

   return (
      <Dialog fullWidth scroll='body' open={openWindow} onClose={() => setOpenWindow(false) } style={{maxWidth:400, margin:'0 auto'}}>
         <DialogTitle>
            <Text style={{color:'#999'}}>Select & Submit Response</Text>
            <IconButton onClick={() => setOpenWindow(false) } sx={{ position: 'absolute', right: 8, top: 8, color: '#999'}}>
               <IconClose />
            </IconButton>
         </DialogTitle >
         <DialogContent style={{padding:10}} dividers>
            
            {(step === 'processing') ? (
               <div style={{paddingTop:20, paddingBottom:50, maxWidth:400, minHeight:300, margin:'0 auto'}}>
                 Processing your request...
                 <LinearProgress color='inherit' />
               </div>
            ):( null )}
         
            {(step === 'error') ? (
               <div style={{paddingTop:20, paddingBottom:50, maxWidth:400, minHeight:300, margin:'0 auto'}}>
                 <Alert severity='error'>
                     <AlertTitle>{errorTitle || 'Error'}</AlertTitle>
                     {errorMessage || 'Sorry, an error occured, please close try again.'}
                  </Alert>
                  <div style={{ marginTop:20 }}>
                     <Button onClick={() => handleStartUp() } variant='outlined' color='secondary'>try again</Button>
                  </div>
               </div>
            ):( null )}  
           
            {(step === 'respond') ? (
               <div style={{ paddingBottom:50, maxWidth:400, margin:'0 auto'}}>
                  <Stack spacing={2}>
                     <List style={{padding:0, margin:0, cursor:'pointer'}} dense={true} onClick={ (event) => { setMenuEl(event.currentTarget) }}>
                        <ListItem secondaryAction={ <IconButton edge='end'><IconExpand /></IconButton> }>
                           <Text variant='h6' style={{lineHeight:1.2}}>{displayMessage}</Text>
                        </ListItem>
                     </List>
                     <Menu dense='true' style={{width:'100%', cursor:'pointer'}} 
                        anchorEl={menuEl} 
                        open={Boolean(menuEl)} 
                        onClose={() => { setMenuEl(null) }}
                     >
                        {(businessCustomMessage !== '') ? (
                           <ListItem onClick={ ()=>{ handleResponse('business') }}>
                              <Text variant='h6' style={{lineHeight:1.2}}>{businessCustomMessage}</Text>
                           </ListItem>
                        ):(null)}
                        <Divider/>
                        <ListItem onClick={ ()=>{ handleResponse('default') }}>
                           <Text variant='h6' style={{lineHeight:1.2}}>Yes we have it.</Text>
                        </ListItem>
                        <Divider/>
                        <ListItem onClick={ ()=>{ handleResponse('custom') }}>
                           <Text variant='h6' style={{lineHeight:1.2}}>Make a custom response.</Text>
                        </ListItem>
                     </Menu>
                     <Divider/>

                     {(displayMessage === 'Make a custom response.') ? (
                           <Stack>
                              <TextField 
                                 fullWidth multiline rows={3}
                                 size='small' label='Custom Response' type='text' margin='normal'
                                 value={responseMessage} onChange={(e) => {handleChange(setResponseMessage, e.target.value) }}
                              />
                              {(optionalImage) ? (
                                 <div align='right'>
                                    <div style={{marginTop:10, marginBottom:0}}>
                                       {/** ImageSelector Starts Here */}
                                       <div align='center' style={{ margin:'0 auto'}}>
                                          <ImageSelector 
                                             imgBorder='1px solid #ccc'
                                             imgBorderRadius={10}
                                             imgPadding={5}
                                             imgRatio='16:9' 
                                             images={images}
                                             currentImage={selectedImage}
                                             selectImage={handleSelectImage}
                                             hideSelector={true}
                                          />
                                       </div>
                                       {/** ImageSelector Ends Here */} 
                                    </div>
                                    <Button onClick={()=>{ handleImage('removeImage') }} variant='text' size='small'>Remove Image</Button>
                                 </div>
                              ):(
                                 <div align='right'><Button onClick={()=>{ handleImage('addImage') }} variant='text' size='small'>Add Image</Button></div>
                              )}
                           </Stack>
                     ):(null)}

                     <Button style={{marginTop:50}} disabled={disableSubmitButton} onClick={() => handleSubmitResponse() } color='primary' variant='contained'>
                        submit response
                     </Button>
                     <Button onClick={() => setOpenWindow(false) } color='secondary' variant='outlined'>
                        cancel
                     </Button>
                  </Stack>
               </div>
            ):( null )} 

         </DialogContent>
      </Dialog>
   )
}

const mapStateToProps = (state) => ({
   loggedIn: state.auth.loggedIn || false,
   businessId: state.business.id || null,
   businessProfile: state.business.profile || {},
})
const mapDispatchToProps = {
   respondToRequest
}
export default connect(mapStateToProps, mapDispatchToProps)(ResponsePopup)
