import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom'
import { checkAdminStatus, loadStats, loadUsers, loadBusinesses, loadEmails, loadRequests, approveRequests, deleteRequests } from '../../redux/admin/actions'

import Businesses from './Businesses'
import Emails from './Emails'
import Home from './Home'
import Loader from '../components/Loader'
import Requests from './Requests'
import Users from './Users'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import IconMenu from '@mui/icons-material/Menu'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'

function AdminContainer({loggedIn, isAdmin, stats, users, requests, businesses, subscriberEmails, businessEmails, checkAdminStatus, loadStats, loadUsers, loadBusinesses, loadEmails, loadRequests, approveRequests, deleteRequests}) {
   const [anchorEl, setAnchorEl] = useState(null)
   const [openMenu, setOpenMenu] = useState(false)
   const [loading, setLoading] = useState(false)
   let navigate = useNavigate()
   let location = useLocation()

   useEffect(() => {
      setTimeout(() => {
         checkAdminStatus()
      },2000) 
   },[loggedIn, location])

   const handleGoTo = (link) => {
      navigate(link) 
      setAnchorEl(null)
   }
   return (
      <Container maxWidth='lg' style={{ padding: 10, marginTop:50, marginBottom:50 }}>
         <Loader loading={loading} title='Processing' text='processing your request...'/>
         <Grid container justifyContent='center' spacing={2}>
            <Grid item xs={12} sm={12} md={10} >
               <div align='center' style={{padding:20}}>
                  <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center'}}>
                     <IconButton variant='h4' style={{color:'#fff'}} onClick={(e) => setAnchorEl(e.currentTarget)}><IconMenu/></IconButton>
                     <Text variant='h4' style={{color:'#fff'}}><b>TellTommi Admin</b></Text>
                  </Box>
                  <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => { setAnchorEl(null) }}>
                    <MenuItem onClick={() => handleGoTo('/admin')}>Stats</MenuItem>
                    <MenuItem onClick={() => handleGoTo('/admin/users')}>Users</MenuItem>
                    <MenuItem onClick={() => handleGoTo('/admin/businesses')}>Businesses</MenuItem>
                    <MenuItem onClick={() => handleGoTo('/admin/requests')}>Active Requests</MenuItem>
                    <MenuItem onClick={() => handleGoTo('/admin/emails')}>Emails</MenuItem>
                  </Menu>
               </div>
               <Paper elevation={6} style={{border:'2px solid black', maxWidth:1000, marginBottom:100, padding:10, paddingBottom:100 }} >
                  {(!isAdmin || !loggedIn) ? (
                     <div align='center'>Sorry, there was an error confirming your access.</div> 
                  ):(
                  <Routes>
                     <Route path='/users' element={
                        <Users
                           users={users}
                           loadUsers={loadUsers}   
                        />
                     } />
                     <Route path='/businesses' element={
                        <Businesses
                           businesses={businesses}
                           loadBusinesses={loadBusinesses}
                        />
                     } />
                     <Route path='/emails' element={
                        <Emails
                           subscriberEmails={subscriberEmails}
                           businessEmails={businessEmails}
                           loadEmails={loadEmails}
                        />
                     } />
                     <Route path='/requests' element={
                        <Requests
                           requests={requests}
                           loadRequests={loadRequests} 
                           approveRequests={approveRequests} 
                           deleteRequests={deleteRequests}   
                        />
                     } />
                     <Route path='/deny' element={<div>Sorry, there was an error confirming your access.</div>} />
                     <Route path='/' element={
                        <Home 
                           stats={stats}
                           loadStats={loadStats}
                        />
                     } />
                  </Routes>
                  )} 
               </Paper>
            </Grid>
         </Grid>  
      </Container>
   )
}

const mapStateToProps = (state) => ({
   loggedIn: state.auth.loggedIn || false,
   isAdmin: state.admin.isAdmin || false,
   stats: state.admin.stats || {},
   users: state.admin.users || [],
   requests: state.admin.requests || [],
   businesses: state.admin.businesses || [],
   subscriberEmails: state.admin.subscriberEmails || [],
   businessEmails: state.admin.businessEmails || []
})
const mapDispatchToProps = {
   checkAdminStatus,
   loadStats,
   loadUsers,
   loadBusinesses,
   loadEmails,
   loadRequests, 
   approveRequests, 
   deleteRequests
}
export default connect(mapStateToProps, mapDispatchToProps)(AdminContainer)
