import { useEffect, useState } from 'react'
import GoogleMapReact from 'google-map-react'

import Loader from '../components/Loader'
import ImageSelector from '../components/ImageSelector'
import Subscription from '../Subscription/_Container'

import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Text from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconMarker from '@mui/icons-material/Room'

const MapMarker = ({ text }) => <div style={{marginRight:20}}><IconMarker color='primary'/>{text}</div>

function Account({userInfo, businessId, businessProfile, wakeupBusinessUpdate, updateBusinessInfo, updateBusinessLocation, updateBusinessTeam}) {
   const [loading, setLoading] = useState(false) 
   const [message, setMessage] = useState('')
   const [businessName, setBusinessName] = useState((businessProfile && businessProfile.businessName) ? businessProfile.businessName : '')
   const [businessAddress, setBusinessAddress] = useState((businessProfile && businessProfile.businessAddress) ? businessProfile.businessAddress : '')
   const [businessPhone, setBusinessPhone] = useState((businessProfile && businessProfile.businessPhone) ? businessProfile.businessPhone : '')
   const [businessCustomMessage, setBusinessCustomMessage] = useState((businessProfile && businessProfile.businessCustomMessage) ? businessProfile.businessCustomMessage : '')
   //const [businessLocation, setBusinessLocation] = useState((businessProfile && businessProfile.businessLocation) ? businessProfile.businessLocation : '')

   const [locationLatitude, setLocationLatitude] = useState((businessProfile && businessProfile.businessLocation && businessProfile.businessLocation !== '') ? businessProfile.businessLocation.latitude : '')
   const [locationLongitude, setLocationLongitude] = useState((businessProfile && businessProfile.businessLocation && businessProfile.businessLocation !== '') ? businessProfile.businessLocation.longitude : '')
   const [profileButtonDisabled, setProfileButtonDisabled] = useState(false)
   const [locationMessage, setLocationMessage] = useState('')

   // [START]: ImageSelector Variables & Function :::::::::
      const [selectedImage, setSelectedImage] = useState((businessProfile && businessProfile.businessImage) ? businessProfile.businessImage : '/images/defaultBusinessImage.jpg') // ImageSelector variable
      const images = [ (businessProfile && businessProfile.businessImage) ? businessProfile.businessImage : '/images/defaultBusinessImage.jpg' ] // ImageSelector variable      
      const handleSelectImage = (src) => {
         if (businessProfile.businessImage !== src) {
           setProfileButtonDisabled(false)
         } else {
           setProfileButtonDisabled(true)
         } 
         setSelectedImage(src)
      }
   // [END]: ImageSelector Variables & Function ::::::::

   useEffect(() => {
      wakeupBusinessUpdate() 
   }, [businessId])

   useEffect(() => {
      let b = (businessProfile) ? businessProfile : {}
      if (
         businessName ===  b.businessName && 
         businessAddress ===  b.businessAddress && 
         businessPhone ===  b.businessPhone && 
         businessCustomMessage === b.businessCustomMessage && 
         selectedImage === b.businessImage
      ) {
         setProfileButtonDisabled(true)
      } else {
         setProfileButtonDisabled(false)
      }
   }, [businessProfile, businessName, businessAddress, businessPhone, businessCustomMessage, selectedImage])

   const handleChange = (f,v) => {
      f(v)
      setMessage('')
   }
   const handleUpdateProfile = () => {
      // Name, Address and Phone cannot be blank
         if (businessName.length < 1 || businessAddress.length < 1 || businessPhone.length < 1) {
            return setMessage('Sorry, Name, Address and Phone are required.')
         }
      // set profile variables
         let profile = {
            image: selectedImage,
            name: businessName,
            address: businessAddress,
            phone: businessPhone,
            customMessage: businessCustomMessage
         }
      setLoading(true)
      updateBusinessInfo(profile)
      .then((image) => {
         setSelectedImage(image)
         //setProfileButtonDisabled(true)
         setLoading(false)
      })
      .catch((e) => {
         setMessage(e.message)
         setLoading(false)
      })
   }
   const handleUpdateTeam = () => {
      console.log('handleUpdateTeam')
   }
   const handleSetLocation = () => {
      if (navigator.geolocation) {         
         navigator.geolocation.getCurrentPosition((location) => {
            if (location) {
               //console.log(location.coords)
               updateLocation(location.coords.latitude, location.coords.longitude)
            } else {
               setLocationMessage('Location can not be retreved')  
            }  
         },(err) => {
            setLocationMessage('Please grant us permission to access your location.')
         })
      } else {
         setLocationMessage('Geolocation is not supported by this browser.')
      }
   }

/*
function showError(error) {
  switch(error.code) {
    case error.PERMISSION_DENIED:
      x.innerHTML = "User denied the request for Geolocation."
      break;
    case error.POSITION_UNAVAILABLE:
      x.innerHTML = "Location information is unavailable."
      break;
    case error.TIMEOUT:
      x.innerHTML = "The request to get user location timed out."
      break;
    case error.UNKNOWN_ERROR:
      x.innerHTML = "An unknown error occurred."
      break;
  }
}
*/
   const updateLocation = (lat, lng) => {
      setLoading(true)
      updateBusinessLocation(lat, lng)
      .then((action) => {
         if (action === 'reset') { 
            setLocationLatitude('')
            setLocationLongitude('')
         } else {
            setLocationLatitude(lat)
            setLocationLongitude(lng)
         }
         setLoading(false)
      })
      .catch((e) => {
         setLocationMessage(e.message)
         setLoading(false)
      })
   }

   return (
      <div style={{marginBottom:50}}>
         <Loader loading={loading} title='Profile Update' text='Updating business profile, please wait...'/>
         <div style={{maxWidth:600, margin:'0 auto', marginTop:10}}>
            <Text variant='h6' color='text.secondary'>Business Profile</Text>
            <Divider/>
         </div>
         <div style={{margin:'0 auto', padding:10, maxWidth:350}}>
            <Stack spacing={2}>
               <div style={{marginTop:10, marginBottom:10}}>
                  {/** ImageSelector Starts Here */}
                  <div align='center' style={{ margin:'0 auto'}}>
                     <ImageSelector 
                        imgBorder='1px solid #ccc'
                        imgBorderRadius={10}
                        imgPadding={5}
                        imgRatio='16:9' 
                        images={images}
                        currentImage={selectedImage}
                        selectImage={handleSelectImage}
                        hideSelector={true}
                     />
                  </div>
                  {/** ImageSelector Ends Here */} 
               </div>
               <TextField fullWidth size='small' label='Business Name' name='businessName' margin='dense'
                  value={businessName} onChange={(e) => {handleChange(setBusinessName, e.target.value) }}
               />
               <TextField fullWidth size='small' label='Business Address' name='businessAddress' margin='dense'
                  value={businessAddress} onChange={(e) => {handleChange(setBusinessAddress, e.target.value) }}
               />
               <TextField fullWidth size='small' label='Business Phone #' name='businessPhone' margin='dense'
                  value={businessPhone} onChange={(e) => {handleChange(setBusinessPhone, e.target.value) }}
               />
               <TextField fullWidth size='small' label='Business Custom Message' name='businessCustomMessage' margin='dense'
                  value={businessCustomMessage} 
                  onChange={(e) => {handleChange(setBusinessCustomMessage, e.target.value) }}
                  multiline
                  rows={3}
                  placeholder='example: Yes we have it. Get 10% off if you mention TellTommi.'
               />
               <Button disabled={profileButtonDisabled} onClick={() => handleUpdateProfile()} color='primary' variant='contained'>Update Business Profile</Button>
            </Stack>
         </div>
            <Text>{message}</Text>
         <div style={{maxWidth:600, margin:'0 auto', marginTop:10}}>
            <Text variant='h6' color='text.secondary'>Location</Text>
            <Divider/>
         </div>
         <div align='center' style={{margin:'0 auto', padding:20, maxWidth:350}}>
            {(locationLatitude === '') ? (
               <div align='center' style={{padding:50, backgroundColor:'#f1f1f1'}}>
                  <Text variant='body1' color='textSecondary'>Business Location Not Set</Text>
                  <Text variant='body1' style={{color:'red', lineHeight:1}}>{locationMessage}</Text>
                  <Button onClick={() => handleSetLocation()} style={{marginTop:10}} variant='outlined'>click to set location</Button>
               </div>
            ):(
               <div align='center' style={{height: '300px', width: '100%', padding:1, backgroundColor:'#f1f1f1'}}>
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyCShnSE8rsYBi9lwHlXrDUMDL1D0fiDlnU' }}
                    defaultCenter={{lat:locationLatitude, lng:locationLongitude}}
                    defaultZoom={11}
                  >
                    <MapMarker
                      lat={locationLatitude}
                      lng={locationLongitude}
                      text={businessName}
                    />
                  </GoogleMapReact>
                  <div align='right'>
                     <Button onClick={() => updateLocation(0,0)} variant='text' size='small'>Reset Location</Button>
                  </div>
               </div>
            )}
         </div>
         {/*
         <div style={{maxWidth:600, margin:'0 auto', marginTop:10}}>
            <Text variant='h6' color='text.secondary'>Subscription</Text>
            <Divider/>
            <Subscription/>
         </div>
         */}
      </div>
   )
}
export default Account
