import { useEffect, useState } from 'react' 
import { Routes, Route, Navigate, Outlet } from 'react-router-dom'
import { connect } from 'react-redux'
import { monitorFeedRequests, stopMonitorFeedRequests, wakeupFeed, closeRequest, deleteRequest } from '../../redux/feed/actions'
import { selectorFilterRequests } from '../../redux/feed/selectors'

import Archives from './Archives'
import Requests from './Requests'
import Responses from './Responses'
import LocationPopup from '../components/LocationPopup/_Container'
import ImagePopup from '../components/ImagePopup'

import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'

function Feed({handleLogin, newRequest, loggedIn, userId, requests, archives, monitorFeedRequests, stopMonitorFeedRequests, wakeupFeed, closeRequest, deleteRequest}) {
   const [openLocationWindow, setOpenLocationWindow] = useState(false)
   const [openImageWindow, setOpenImageWindow] = useState(false)
   const [openImage, setOpenImage] = useState('')
   const [location, setLocation] = useState(null)

   useEffect(() => {
      wakeupFeed(loggedIn)
      monitorFeedRequests()
      return () => {
         stopMonitorFeedRequests()
      }
   },[userId])

   const handleOpenImage = (img) => {
      setOpenImage(img) 
      setOpenImageWindow(true) 
   }
   const handleNewRequest = () => {
      newRequest(true)  
   }
   const handleLocation = (location) => {
      setLocation(location)
      setOpenLocationWindow(true)  
   }

   return (
      <Container maxWidth='lg' style={{ padding: 10, marginTop:50, marginBottom:50 }}>
         {(openLocationWindow) ? (
            <LocationPopup openWindow={openLocationWindow} setOpenWindow={setOpenLocationWindow} location={location}/>
         ):( null )}
         {(openImageWindow) ? (
            <ImagePopup openWindow={openImageWindow} setOpenWindow={setOpenImageWindow} image={openImage}/>
         ):( null )}
         <Grid container justifyContent='center' spacing={2}>
            <Grid item xs={12} sm={12} md={10} >
               <div style={{textAlign:'center', maxWidth:500, margin:'20px auto'}}>
                  <img src='/images/logo-big.png' style={{width:'100%'}}/>
               </div>
               {(loggedIn) ? (
                  <div>
                  <Routes>
                     <Route path='/requests' element={<Requests 
                        requests={requests}
                        handleNewRequest={handleNewRequest}
                        hasArchives={archives.length}
                        closeRequest={closeRequest} 
                        deleteRequest={deleteRequest}
                        handleOpenImage={handleOpenImage}
                     />} />
                     <Route path='/responses' element={<Responses 
                        requests={requests}
                        archives={archives}
                        closeRequest={closeRequest} 
                        handleLocation={handleLocation}
                        handleOpenImage={handleOpenImage}
                     />} />
                     <Route path='/archives' element={<Archives 
                        archives={archives}
                        handleOpenImage={handleOpenImage}
                     />} />
                     <Route path='*' element={<Navigate to='/feed/requests' />} />
                  </Routes>
                  <Outlet />
                  </div>
               ):(
                  <Paper elevation={6} style={{border:'2px solid black', maxWidth:700, margin:'0 auto' }} >
                     <div align='center' style={{margin:0, padding:50}}>
                        <Text variant='body1'>Please Log In to view your Requests & Responses</Text>
                        <Button style={{marginTop:20}} variant='outlined' onClick={() => {handleLogin()}}>click to login</Button>
                     </div>
                  </Paper>
               )}
            </Grid>
         </Grid>
      </Container>
   )
}
const mapStateToProps = (state) => ({
   loggedIn: state.auth.loggedIn || false,
   userId: (state.auth.userInfo) ? state.auth.userInfo.id : 0,
   requests: selectorFilterRequests(state.feed.requests,'active') || [],
   archives: selectorFilterRequests(state.feed.requests,'closed') || []
})
const mapDispatchToProps = {
   monitorFeedRequests,
   stopMonitorFeedRequests,
   wakeupFeed,
   closeRequest, 
   deleteRequest
}
export default connect(mapStateToProps, mapDispatchToProps)(Feed)
