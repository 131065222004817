import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Routes, Route } from 'react-router-dom'
import { monitorLoginStatus, stopMonitoringLoginStatus, logOut } from '../redux/auth/actions'

import Admin from './Admin/_Container'
import AboutUs from './AboutUs'
import Business from './Business/_Container'
import ConfirmEmail from './ConfirmEmail'
//import FBMessages from './components/FBMessaging/Messages'
import ContactUs from './ContactUs'
import Error404 from './Error404'
import Feed from './Feed/_Container'
import Home from './Home'
import LoginPopup from './components/LoginPopup/_Container'
import MailingList from './MailingList'
import RequestPopup from './components/RequestPopup/_Container'
import TopNav from './components/TopNav'
import SplashScreen from './components/SplashScreen'
import Subscription from './Subscription/_Container'

function App({loggedIn, userInfo, monitorLoginStatus, stopMonitoringLoginStatus, logOut}) {
   const [openLoginWindow, setOpenLoginWindow] = useState(false)
   const [openRequestWindow, setOpenRequestWindow] = useState(false)

   useEffect(() => {
      monitorLoginStatus()
      return () => {
         stopMonitoringLoginStatus()
      }
   },[])

   const handleLogin = () => {
      setOpenLoginWindow(true)  
   }
   const handleNewRequest = () => {
      setOpenRequestWindow(true)  
   }

   return (
      <>
         {/*
         <FBMessages/>
         */}
         <SplashScreen />
         {(openLoginWindow) ? (
            <LoginPopup openLoginWindow={openLoginWindow} setOpenLoginWindow={setOpenLoginWindow}/>
         ):( null )}
         {(openRequestWindow) ? (
            <RequestPopup openRequestWindow={openRequestWindow} setOpenRequestWindow={setOpenRequestWindow}/>
         ):( null )}
         <TopNav loggedIn={loggedIn} userInfo={userInfo} handleLogin={handleLogin} newRequest={handleNewRequest} logOut={logOut}/>
         <Routes>
            <Route path='/admin/*' element={<Admin />} />
            <Route path='/about' element={<AboutUs />} />
            <Route path='/business/*' element={<Business handleLogin={handleLogin}/>} />
            <Route path='/confirm-email' element={<ConfirmEmail />} />
            <Route path='/contact' element={<ContactUs />} />
            <Route path='/feed/*' element={<Feed handleLogin={handleLogin} newRequest={handleNewRequest}/>} />
            <Route path='/home' element={<Home handleNewRequest={handleNewRequest}/>} />
            <Route path='/subscription' element={<Subscription />} />
            <Route path='*' element={<Error404 />} />     
            <Route path='/' element={<Home handleNewRequest={handleNewRequest}/>} />
         </Routes>
      </>
   )
}
const mapStateToProps = (state) => ({
   loggedIn: state.auth.loggedIn || false,
   userInfo: state.auth.userInfo || {}
})
const mapDispatchToProps = {
   monitorLoginStatus, 
   stopMonitoringLoginStatus,
   logOut 
}
export default connect(mapStateToProps, mapDispatchToProps)(App)

