import { useState } from 'react' 
import swal from 'sweetalert2'
import { chkPassword }  from '../../../includes/functions'

import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Button from '@mui/material/Button'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Info from '@mui/icons-material/InfoOutlined'
import InputAdornment from '@mui/material/InputAdornment'
import LinearProgress from '@mui/material/LinearProgress'
import Stack from '@mui/material/Stack'
import Text from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

function ProfileUpdate({setOpenLoginWindow, userInfo, updateUserInfo, updateUserPassword}){
   const [updateStep, setUpdateStep] = useState('password') // password, profile, processing, error 
   const [resetFormStep, setResetFormStep] = useState('password') // password, profile
   const [getOldPassword, setGetOldPassword] = useState(false)
   const [oldPassword, setOldPassword] = useState('')
   const [showPassword, setShowPassword] = useState(false) 
   const [showInfo, setShowInfo] = useState(false)
   const [password, setPassword] = useState('')
   const [confirm, setConfirm] = useState('')
   const [firstName, setFirstName] = useState('')
   const [lastName, setLastName] = useState('')
   const [cell, setCell] = useState('')
   const [message, setMessage] = useState('')

   const handleChange = (f,v) => {
      f(v)
      setMessage('')
   }
   const handleResetForm = () => {
      setOldPassword('')
      setPassword('')
      setConfirm('')
      setMessage('') 
      setUpdateStep(resetFormStep)
   }
   const handleUpdatePassword = (e) => {
      e.preventDefault()
      if (getOldPassword && !chkPassword(oldPassword)) {
        return setMessage('Please enter your Current Password.')
      }
      if (!chkPassword(password)) {
        return setMessage('Please enter a valid Password.')
      }
      if (password !== confirm) {
        return setMessage('Password and Confirm must match.')
      }
      setUpdateStep('processing')
      updateUserPassword(oldPassword, password)
      .then(() => {
         setUpdateStep('profile')
         swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Password Updated',
            showConfirmButton: false,
            timer: 2000
         })
      })
      .catch((error) => {
         setResetFormStep('password')
         setUpdateStep('error')
         setMessage(error)
         if (error === 'Please enter your Current Password.') {
           setGetOldPassword(true)
         }
      })      
   }   
   const handleUpdateProfile = (e) => {
      e.preventDefault()
      // firstName and lastName cannot be blank
      if (firstName.length < 1 || lastName.length < 1) {
         return setMessage('Sorry, both First and Last names are required.')
      }
      // set user variables
      let user = {
         firstName: firstName,
         lastName: lastName,
         cell: cell
      }
      setUpdateStep('processing')
      updateUserInfo(user)
      .then(() => {
         swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Profile Updated',
            showConfirmButton: false,
            timer: 2000
         })
         setOpenLoginWindow(false)
      })
      .catch((e) => {
         setResetFormStep('profile')
         setUpdateStep('error')
         setMessage(e.message)
      })
   }   
   return (
      <div>
         {(updateStep === 'password') ? (
            <div style={{paddingTop:20, paddingBottom:20, maxWidth:400, margin:'0 auto'}}>
               <Text variant='h6'>PASSWORD</Text>
               <Text color='text.secondary'> 
                  Would you like to set or update the password for your account?
                  If not, skip this step. 
               </Text>
               <form onSubmit={handleUpdatePassword}>
                  <Grid container justify='center'>
                     {(getOldPassword) ? (
                        <Grid item xs={12} sm={12} style={{ padding: 5 }}>
                          <TextField fullWidth size='small' margin='normal' label='Current Password'
                              type={showPassword ? 'text' : 'password'}
                              value={oldPassword}
                              onChange={(e) => { handleChange(setOldPassword, e.target.value) }}
                              InputProps={{
                                 endAdornment: (
                                    <InputAdornment position='end'>
                                       <IconButton  onClick={() => { setShowPassword(!showPassword) }}>
                                       {showPassword ? <Visibility /> : <VisibilityOff />}
                                       </IconButton>
                                    </InputAdornment>
                                 )
                              }}
                          />
                        </Grid>
                     ):( null )}
                     <Grid item xs={12} sm={6} style={{ padding: 5 }}>
                        <TextField fullWidth size='small' margin='normal' label='New Password'
                           type={showPassword ? 'text' : 'password'}
                           value={password}
                           onChange={(e) => { handleChange(setPassword, e.target.value) }}
                           InputProps={{
                              endAdornment: (
                                 <InputAdornment position='end'>
                                    <IconButton  onClick={() => { setShowPassword(!showPassword) }}>
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                 </InputAdornment>
                              )
                           }}
                        />
                     </Grid>
                     <Grid item xs={12} sm={6} style={{ padding: 5 }}>
                        <TextField fullWidth size='small' margin='normal' label='Confirm'
                           type={showPassword ? 'text' : 'password'}
                           value={confirm}
                           onChange={(e) => { handleChange(setConfirm, e.target.value) }}
                           InputProps={{
                              endAdornment: (
                                 <InputAdornment position='end'>
                                    <ClickAwayListener onClickAway={() => { setShowInfo(false) }}>
                                       <span>
                                          <Tooltip
                                             PopperProps={{ disablePortal: false }}
                                             onClose={() => { setShowInfo(false) }}
                                             open={showInfo}
                                             disableFocusListener
                                             disableHoverListener
                                             disableTouchListener
                                             placement='top-end'
                                             title='password must be at least 6 characters long.'
                                          > 
                                             <IconButton aria-label='password must be at least 6 characters long.' onClick={() => { setShowInfo(!showInfo) }}>
                                                <Info />
                                             </IconButton>
                                          </Tooltip>
                                       </span>
                                    </ClickAwayListener>
                                 </InputAdornment>
                              )
                           }}
                        />                    
                     </Grid>
                  </Grid>
                  <Stack spacing={2}>
                     <Text style={{ marginTop: 10, color: 'red', height: 15 }} component='p' align='center'>
                        <i>{ message }</i>
                     </Text>
                     <Stack direction='row' spacing={2} justifyContent='flex-end' divider={<Divider orientation='vertical' flexItem />} >
                        <Button onClick={() => setUpdateStep('profile')} color='primary' autoFocus>
                           Skip this Step
                        </Button>
                        <Button variant='contained' color='primary' type='submit'>
                           Save Password
                        </Button>
                     </Stack>
                  </Stack>              
               </form>
            </div>
         ):( null )}

         {(updateStep === 'profile') ? (
            <div style={{paddingTop:20, paddingBottom:20, maxWidth:400, margin:'0 auto'}}>
               <Text variant='h6'>PROFILE</Text>
               <Text color='text.secondary'> 
                  Would you like to tell us a little about yourself?
                  If not, skip this step. 
               </Text>
               <form onSubmit={handleUpdateProfile}>
                  <Stack spacing={2} style={{maxWidth:300, margin:'0 auto'}}>
                     <TextField fullWidth size='small' label='First Name' name='firstName' margin='dense'
                        value={firstName} onChange={(e) => {handleChange(setFirstName, e.target.value) }}
                     />
                     <TextField fullWidth size='small' label='Last Name' name='lastName' margin='dense'
                        value={lastName} onChange={(e) => {handleChange(setLastName, e.target.value) }}
                     />
                     <TextField fullWidth size='small' label='Cell #' name='lastName' margin='dense'
                        value={cell} onChange={(e) => {handleChange(setCell, e.target.value) }}
                     />
                  </Stack>

                  <Stack spacing={2}>
                     <Text style={{ marginTop: 10, color: 'red', height: 15 }} component='p' align='center'>
                        <i>{ message }</i>
                     </Text>
                     <Stack direction='row' spacing={2} justifyContent='flex-end' divider={<Divider orientation='vertical' flexItem />} >
                        <Button onClick={() => setOpenLoginWindow(false)} color='primary' autoFocus>
                           Skip this Step
                        </Button>
                        <Button variant='contained' color='primary' type='submit'>
                           Save Profile
                        </Button>
                     </Stack>
                  </Stack>    
               </form>     
            </div>
         ):( null )}
         
         {(updateStep === 'processing') ? (
            <div style={{paddingTop:50, paddingBottom:50, maxWidth:400, margin:'0 auto'}}>
              Processing your request...
              <LinearProgress color='inherit' />
            </div>
         ):( null )}

         {(updateStep === 'error') ? (
            <div style={{paddingTop:50, paddingBottom:50, maxWidth:400, margin:'0 auto'}}>
              <Alert severity='error'>
                  <AlertTitle>Login Error</AlertTitle>
                  {message || 'Sorry, an error occured, please try again.'}
               </Alert>
               <div style={{ marginTop:20 }}><Button onClick={() => { handleResetForm() }} variant='outlined' color='secondary'>try again</Button></div>
            </div>
         ):( null )}
      </div>
   )
}
export default ProfileUpdate
