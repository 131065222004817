import { APP_WAKEUP_TIMESTAMP, APP_LAST_UPDATED, APP_LOAD_SETTINGS } from '../types'

const initialState = {
	currentVersion: '03.11.23-bata', // 1. set currentVersion and version, to the new version. then build app and upload.
	version: '03.11.23-bata', // 2. update the server (app/settings/version) to new version.
	admins: [], // app admins
	live: true,
	splashTitle: '',
	splashMessage: '',
	splashFooter: '',
	buttonShow: false,
	buttonText: '',
	buttonLink: '',
	updateIntervals: {      // update intervals in seconds
		memberProfile: 600,	// member/profile/profile (10 minute update interval - 600 seconds )
      default: 300			// default interval (5 minute default update interval - 300 seconds - used if supplied interval is not valid)
	},
	lastUpdated: {},        // track last time data was updated 
	wakeupTimestamps: {}    // track last time firebase functions were used
}

export default function reducer(state = initialState, action) {
	switch (action.type) {
      case APP_WAKEUP_TIMESTAMP:
         return {
            ...state,
            wakeupTimestamps: {
                ...state.wakeupTimestamps,
                [action.payload.key]: action.payload.value
            }
         }		
		case APP_LAST_UPDATED:
        	return {
            ...state,
            lastUpdated: {
               ...state.lastUpdated,
               [action.payload.key]: action.payload.value
            }
        	}
      case APP_LOAD_SETTINGS:
			return { 
				...state,
				version: action.payload.version,
				admins: action.payload.admins,
				live: action.payload.live,
				splashTitle: action.payload.splashTitle,
				splashMessage: action.payload.splashMessage,
				splashFooter: action.payload.splashFooter,
				buttonShow: action.payload.buttonShow,
				buttonText: action.payload.buttonText,
				buttonLink: action.payload.buttonLink
			}
		default:
			return state
	}
}
