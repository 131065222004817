import { useEffect, useState } from 'react' 
import { Link, useSearchParams, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import format from 'date-fns/format'

import Loader from '../components/Loader'
import ReplyPopup from '../components/ReplyPopup/_Container'
import ReportPopup from '../components/ReportPopup/_Container'

import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Grid from '@mui/material/Grid'
import IconArrowBack from '@mui/icons-material/ArrowBackIos'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Text from '@mui/material/Typography'

function Responses({ requests, archives, closeRequest, handleLocation, handleOpenImage }) {
   const conditionColor = {'New':'#40be25', 'Used':'#D8B522', 'Both':'#1cc7de'}
   const conditionText = {'New':'New Only', 'Used':'Used Only', 'Both':'New or Used'}
   const [loading, setLoading] = useState(false)
   const [openReplyWindow, setOpenReplyWindow] = useState(false)
   const [openReportWindow, setOpenReportWindow] = useState(false)
   const [replyType, setReplyType] = useState('')
   const [businessId, setBusinessId] = useState('')
   const [requestId, setRequestId] = useState('')
   const [responseId, setResponseId] = useState('')
   const [request, setRequest] = useState({})
   const [responses, setResponses] = useState([])
   const [searchParams] = useSearchParams()
   const id = searchParams.get('id') || null 
   const navigate = useNavigate()

   useEffect(() => {
      filterResponses()
   },[requests,archives,id])

   const filterResponses = () => {
      let request = requests.find(x => x.requestId === id) || {}
      if (!Object.keys(request).length) {
         request = archives.find(x => x.requestId === id) || {}
      }
      let responses = (request && request.responses) ? Object.values(request.responses) : []
      responses.sort((a,b) => b.responseTimestamp - a.responseTimestamp) // b - a for reverse sort
      setRequest(request)
      setResponses(responses)
   }
   const handleCloseRequest = (id) => {
      Swal.fire({
         title: 'Close Request',
         text: 'Are you sure you would like to close this request.',
         showCancelButton: true,
         showConfirmButton: true,
         confirmButtonText: 'Close'
      }).then((result) => {
         if (result.isConfirmed) { 
            setLoading(true)
            closeRequest(id)
            .then(() => {
               setLoading(false)
               navigate('/feed/requests')
            })
            .catch((e) => {
               setLoading(false)
               Swal.fire({
                  title: e.title, 
                  text: e.message,
                  showConfirmButton: true
               })
            })
         }
      })
   }
   const handleReply = (businessId, responseId) => {
      let replyType = 'Reply To Response'
      let requestId = id
      setReplyType(replyType)
      setBusinessId(businessId)
      setRequestId(requestId)
      setResponseId(responseId)
      setOpenReplyWindow(true)
   }
   const handleReport = (businessId, responseId) => {
      alert('coming soon')
      /*
      let replyType = 'Reply To Response'
      let requestId = id
      setReplyType(replyType)
      setBusinessId(businessId)
      setRequestId(requestId)
      setResponseId(responseId)
      setOpenReplyWindow(true)
      */
   }
   return (
      <div>
         {(openReplyWindow) ? (
            <ReplyPopup openWindow={openReplyWindow} setOpenWindow={setOpenReplyWindow} replyType={replyType} businessId={businessId} requestId={requestId} responseId={responseId}/>
         ):( null )}
         {(openReportWindow) ? (
            <ReportPopup openWindow={openReportWindow} setOpenWindow={setOpenReportWindow} replyType={replyType} businessId={businessId} requestId={requestId} responseId={responseId}/>
         ):( null )}
         <Loader loading={loading} title='Processing' text='processing your request...'/>
         <div align='right' style={{maxWidth:600, margin:'0 auto'}}>
            <Button variant='text' size='small' component={Link} to='/feed/requests' style={{color:'#fff'}}>View All Active Requests</Button>    
         </div>
         <Card elevation={6} style={{border:'2px solid black', maxWidth:600, margin:'0 auto', marginTop:5}}>
            <CardContent>
               <Text variant='h6'>Request</Text>
               <Grid container justifyContent='center' alignItems='center' spacing={0}>
                  <Grid item xs={12} sm={6} style={{textAlign:'center'}}>
                     <Grid container justifyContent='center' alignItems='center' spacing={0.5}>
                        <Grid item xs={4} style={{textAlign:'right'}}>
                           <Text variant='body2' color='text.secondary'>VIN/Year:</Text> 
                        </Grid>
                        <Grid item xs={8} style={{textAlign:'left'}}>       
                           <Text variant='body2'>{request.year}</Text>           
                        </Grid> 
                        <Grid item xs={4} style={{textAlign:'right'}}>
                           <Text variant='body2' color='text.secondary'>Make:</Text> 
                        </Grid>
                        <Grid item xs={8} style={{textAlign:'left'}}>       
                           <Text variant='body2'>{request.make}</Text>           
                        </Grid> 
                        <Grid item xs={4} style={{textAlign:'right'}}>
                           <Text variant='body2' color='text.secondary'>Model:</Text> 
                        </Grid>
                        <Grid item xs={8} style={{textAlign:'left'}}>       
                           <Text variant='body2'>{request.model}</Text>           
                        </Grid> 

                     </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{textAlign:'center'}}>
                     <Grid container justifyContent='center' alignItems='center' spacing={0.5}>
                        <Grid item xs={4} style={{textAlign:'right'}}>
                           <Text variant='body2' color='text.secondary'>Request ID:</Text> 
                        </Grid>
                        <Grid item xs={8} style={{textAlign:'left'}}>       
                           <Text variant='body2'><i>{request.requestId}</i></Text>            
                        </Grid>
                        <Grid item xs={4} style={{textAlign:'right'}}>
                           <Text variant='body2' color='text.secondary'>Condition:</Text> 
                        </Grid>
                        <Grid item xs={8} style={{textAlign:'left'}}>       
                           <Text variant='body2' component='span' style={{paddingLeft:5, paddingRight:5, borderRadius:5, backgroundColor:conditionColor[request.condition], color:'#fff'}}>{conditionText[request.condition]}</Text>            
                        </Grid> 
                        <Grid item xs={4} style={{textAlign:'right'}}>
                           <Text variant='body2' color='text.secondary'>Responses:</Text> 
                        </Grid>
                        <Grid item xs={8} style={{textAlign:'left'}}>       
                           <Text variant='body2' style={{color:'red'}}><b>{request.responsesCount || 0}</b></Text>          
                        </Grid> 
                     </Grid>
                  </Grid>                              
                  <Grid item xs={12}>
                     <div style={{display:'flex', margin:5, padding:5, borderTop:'1px solid gray', borderBottom:'2px solid darkgray'}}>
                        {(request.image !== '') ? (
                           <img onClick={() => {handleOpenImage(request.image)}} src={request.image} style={{float:'left', cursor: 'pointer', maxWidth:100, margin:5, padding:2, border:'1px solid gray'}}/>  
                        ):(null)}
                        <div>
                           <Text variant='body2' color='text.secondary'><b><i>Part Description</i></b></Text>
                           <Text variant='body2'>{request.part}</Text> 
                        </div>
                     </div> 
                     {(request.status !== 'closed') ? (
                        <div align='right'><Button onClick={() => { handleCloseRequest(request.requestId) }} size='small' style={{color:'lightgray'}}>Close this Request</Button></div>  
                     ):(null)}
                  </Grid>
               </Grid>                     
            </CardContent>
         </Card> 

         <Paper elevation={6} style={{border:'2px solid black', maxWidth:600, margin:'0 auto', marginTop:25, padding:20 }} >
            <Text variant='h6'>Responses</Text>
            <Text variant='body2'>Below are the Responses to your Request. Stay on this page and Responses would be automatically loaded.</Text>
            <div style={{textAlign:'right', paddingTop:10}}>
               <Button variant='contained' size='small' component={Link} to='/feed/requests'><IconArrowBack />View All Active Requests</Button> 
            </div>
         </Paper>

         {(responses.length) ? (
            <div style={{marginBottom:100}}>
               {responses.map((rec, key) => { 
                  return (
                     <Card key={key} elevation={6} style={{border:'2px solid black', margin:'0 auto', marginTop:25, maxWidth:600}}>
                        <CardMedia image={rec.businessImage} alt={rec.businessName} component='img' height='140' />
                        <CardContent>
                           <Grid container justifyContent='center' alignItems='flex-start' spacing={0.2}>
                              <Grid item xs={4} style={{textAlign:'right'}}>
                                 <Text variant='body2' color='text.secondary'>Business:&nbsp;</Text> 
                              </Grid>
                              <Grid item xs={8} style={{textAlign:'left'}}>       
                                 <Text variant='body2'><b>{rec.businessName}</b></Text>           
                              </Grid> 
                              <Grid item xs={4} style={{textAlign:'right'}}>
                                 <Text variant='body2' color='text.secondary'>Address:&nbsp;</Text> 
                              </Grid>
                              <Grid item xs={8} style={{textAlign:'left'}}>       
                                 <Text variant='body2'>{rec.businessAddress}</Text>           
                              </Grid> 
                              <Grid item xs={4} style={{textAlign:'right'}}>
                                 <Text variant='body2' color='text.secondary'>Phone:&nbsp;</Text> 
                              </Grid>
                              <Grid item xs={8} style={{textAlign:'left'}}>       
                                 <Text variant='body2'>{rec.businessPhone}</Text>           
                              </Grid> 
                              <Grid item xs={12} style={{textAlign:'left'}}> 
                                 <div style={{marginTop:10, marginBottom:10}}>     
                                 {rec.dialogue.map((d,key)=>{
                                    return(
                                       <div key={key} style={{ margin:5, padding:5, borderTop:'1px solid gray'}}>
                                          <Text variant='subtitle1' style={{color:'gray'}}>
                                             <b>{(d.type === 'request') ? 'Your Reply' : 'Vendor Response'}</b>
                                          </Text>
                                          <div style={{display:'flex', marginBottom:15}}>
                                             {(d.image) ? (
                                                <img onClick={() => {handleOpenImage(d.image)}} src={d.image} style={{float:'left', cursor: 'pointer', maxWidth:100, margin:5, padding:2, border:'1px solid gray'}}/>  
                                             ):( null )}
                                             <div>
                                                <Text color='text.secondary' variant='caption'><i>{format(new Date(d.timestamp), 'eee do MMM y - h:mm a')}</i></Text> 
                                                <Text variant='body2'>{d.message}</Text>
                                             </div>                                            
                                          </div> 
                                       </div>
                                    )
                                 })}  
                                 </div> 
                                 {/*<div align='right' style={{marginTop:50}}><Button onClick={handleReport} size='small' style={{color:'lightgray'}}>Report this Vendor</Button></div> */}    
                              </Grid>
                           </Grid>
                        </CardContent>
                        <CardActions>
                           <Stack direction='row' spacing={2} style={{margin:'0 auto'}}>
                              {(rec.responseStatus === 'complete' && request.status !== 'closed') ? (
                                 <Button onClick={() => { handleReply(rec.businessId, rec.responseId) }} variant='outlined' color='secondary' size='small'>Reply to Vendor</Button>
                              ):( null )}
                              {(rec.businessLocation) ? (
                                 <Button onClick={() => { handleLocation(rec.businessLocation) }} variant='contained' color='primary' size='small'>View on Map</Button>
                              ):( null )}
                              {(rec.businessPhone) ? (
                                 <Button href={'tel:'+rec.businessPhone} variant='contained' color='secondary' size='small'>Call Business</Button>
                              ):( null )} 
                           </Stack>
                        </CardActions>
                     </Card>
                  )
               })}
            </div>
         ) : (
            <Paper elevation={6} style={{border:'2px solid black', maxWidth:600, margin:'0 auto', padding:20, marginTop:20, marginBottom:100 }} >
               <div align='center'>
                  <Text variant='h6' style={{color:'#ff0000'}}>Responses Would Be Listed Here</Text>
               </div>
            </Paper> 
         )}
      </div>
   )
}
export default Responses
