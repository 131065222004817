import { useState } from 'react'

import Fees from './Fees'
import Privacy from './Privacy'
import Refunds from './Refunds'
import Terms from './Terms'

import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import OpenInNew from '@mui/icons-material/OpenInNew'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

function Index({ tab, text, size, color }) {
   const [open, setOpen] = useState(false)
   const [value, setValue] = useState(tab)
   const theme = useTheme()
   const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
   const selectedTab = ['Terms of Service', 'Privacy Policy', 'Refund Policy', 'Fees & Charges']
   const buttonText = ['I agree with the Terms of Service', 'Fees & Terms of Service', 'Terms of Service', 'Privacy Policy', 'Refund Policy']

   const handleOpen = (tab) => {
      let val = (tab >= 3) ? 0 : tab
      setOpen(true)
      setValue(val)
   }

   return (
      <span>
         <Button 
            size={(size) ? size : 'small'} 
            style={{'textTransform':'none', color:(color) ? color : '#000', margin:0, padding:0}}
            onClick={() => {handleOpen(tab)}}
            endIcon={<OpenInNew />}
         >
            {buttonText[text]}
         </Button>
     
         <Dialog onClose={() => { setOpen(false) }} fullScreen={fullScreen} open={open} keepMounted>
            <DialogContent style={{ padding:0, margin: 0}}>
            <AppBar position='static' color='default'>
               <Tabs
                  variant='fullWidth' 
                  value={value}
                  onChange={(e,v) => {setValue(v)}}
                  indicatorColor='primary'
                  textColor='primary'
               >
                  <Tab label='Terms of Service' />
                  <Tab label='Privacy Policy' />
                  <Tab label='Refund Policy' />
                  <Tab label='Fees & Charges' />
               </Tabs>
            </AppBar>
            <div style={{padding: 10}}>
               <div style={{margin:'0 auto', padding:5, maxWidth:'500'}}>
                  <img style={{maxWidth: '100%'}} src='/images/finder-wide-logo.png'/> 
               </div>
               {(value === 0) ? (
                  <Terms />
               ) : ( null )}
               {(value === 1) ? (
                  <Privacy />
               ) : ( null )}
               {(value === 2) ? (
                  <Refunds />
               ) : ( null )}
               {(value === 3) ? (
                  <Fees />
               ) : ( null )}
            </div>
            </DialogContent>
            <DialogActions>
               <Button onClick={() => { setOpen(false) }} color='primary' variant='contained'>Close</Button>
            </DialogActions>
         </Dialog>
      </span> 
   )
}
export default Index
