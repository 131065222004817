import { initializeApp, getApp } from 'firebase/app'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getStorage, connectStorageEmulator } from 'firebase/storage'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'

const localConfig = {
   apiKey: "AIzaSyBUa2Bm3O_gTRo2AGKpHcYyz6MVijd6FhA",
   projectId: "tommi-admin", //"tommi-admin",
   messagingSenderId: '50170278330',
   appId: "1:50170278330:web:9ba72f02fe750aa4b96d83"
}

const stageConfig = {
   apiKey: "AIzaSyBUa2Bm3O_gTRo2AGKpHcYyz6MVijd6FhA",
   authDomain: "bootstrap-d19a7.firebaseapp.com",
   databaseURL: "https://bootstrap-d19a7.firebaseio.com",
   projectId: "bootstrap-d19a7", //"tommi-admin",
   storageBucket: "bootstrap-d19a7.appspot.com",
   messagingSenderId: "50170278330",
   appId: "1:50170278330:web:9ba72f02fe750aa4b96d83"
}

const liveConfig = {
  apiKey: "AIzaSyCShnSE8rsYBi9lwHlXrDUMDL1D0fiDlnU",
  authDomain: "droplet-56446.firebaseapp.com",
  databaseURL: "https://droplet-56446.firebaseio.com",
  projectId: "droplet-56446",
  storageBucket: "droplet-56446.appspot.com",
  messagingSenderId: "577276011320",
  appId: "1:577276011320:web:36f12ff8470bde7ef0d7cd"
}


// Set variables :::::::::::::::::::::::::::::::::::::::
   let hostname = window.location.hostname
   let config = stageConfig

// Set config based on hostname ::::::::::::::::::::::::
   if (hostname.includes('localhost')) {
      config = localConfig
   } else if (hostname.includes('dev.')) {
      config = stageConfig
   } else {
      config = liveConfig
   }

// Initialize Firebase :::::::::::::::::::::::::::::::::
   const firebase = initializeApp(config)

// If on localhost, setup and use Emulator
   if (hostname.includes('localhost')) {
      const auth = getAuth(firebase)
      const db = getFirestore()
      const storage = getStorage()
      const functions = getFunctions(getApp())
      connectAuthEmulator(auth, 'http://localhost:9099')
      connectFirestoreEmulator(db, 'localhost', 8080)
      connectStorageEmulator(storage, 'localhost', 9199)
      connectFunctionsEmulator(functions, 'localhost', 5001)
   }

// Export Firebase :::::::::::::::::::::::::::::::::::::
export default firebase
