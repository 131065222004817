import { BUSINESS_INFO, BUSINESS_RESET, BUSINESS_SET, BUSINESS_LOCATION_SET, BUSINESS_FEED_DELETE, BUSINESS_FEED_HIDE, BUSINESS_FEED_UPDATE, BUSINESS_RESPONSES_UPDATE, BUSINESS_SUBSCRIPTION_UPDATE } from '../types'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { getFirestore, doc, collection, query, where, onSnapshot } from 'firebase/firestore'
import { wakeupServer, wakeupTimestamp } from '../app/actions'

// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// [START]: ALL LISTENERS HERE :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
      let monitorFeed, monitorResponses, monitorBusiness
      export function monitorBusinessFeed() { 
         return (dispatch, getstate, firebase) => {
            console.log('monitorBusinessFeed')
            dispatch({ type: BUSINESS_FEED_DELETE })
            const businessId = getstate().business.id || 0
            if (businessId) {
               dispatch(monitorBusinessInfo(businessId))
               dispatch(monitorBusinessResponses(businessId))
               const db = getFirestore(firebase)
               const qRequests = query(collection(db, 'requests'), where('activated', '==', true), where('status', '==', 'active'))
               monitorFeed = onSnapshot(qRequests, querySnapshot => {
                  querySnapshot.forEach((doc) => {
                     dispatch({ type: BUSINESS_FEED_UPDATE, payload:{id: doc.id, details: doc.data()} })
   		  		   })	
               }, err => {
                  console.log(`monitorBusinessFeed Encountered error: ${err}`);
               })              
            } 
         }
      }
      export function stopMonitoringBusinessFeed() { 
      	return (dispatch) => {
            console.log('stopMonitoringBusinessFeed')
				monitorFeed && monitorFeed()
            monitorResponses && monitorResponses()
            monitorBusiness && monitorBusiness()
         }
      }

   // [START]: Business Responses Listener :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
      export function monitorBusinessResponses(bid) {
         return (dispatch, getstate, firebase) => {  
            const db = getFirestore(firebase)
            const qResponses = query(collection(db, 'responses'), where('businessId', '==', bid), where('requestStatus', '==', 'active'))
            monitorResponses = onSnapshot(qResponses, querySnapshot => {
               querySnapshot.forEach((doc) => {
                  dispatch({ type: BUSINESS_RESPONSES_UPDATE, payload:{id: doc.id, details: doc.data()} })
                 })   
            }, err => {
               console.log(`monitorBusinessResponses Encountered error: ${err}`);
            }) 
         }
      }
   // [END]: Business Responses Listener :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

   // [START]: Business Profile Listener :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
      export function monitorBusinessInfo(bid) {
         return (dispatch, getstate, firebase) => {  
            const db = getFirestore(firebase)
            const docRef = doc(db, 'businesses', bid)
            monitorBusiness = onSnapshot(docRef, querySnapshot => { 
               if (querySnapshot.data()) {
                  let data = querySnapshot.data()
                  dispatch({ type: BUSINESS_INFO, payload: data })
               }
            }, err => {
               console.log('monitorBusinessInfo error:', err)
            })
         }
      }
   // [END]: Business Profile Listener :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

// [END]: ALL LISTENERS HERE :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// [START]: ALL WAKEUP FUNCTIONS ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++      
   export function wakeupBusiness() {
      return (dispatch) => {  
         console.log('wakeupBusiness') 
         dispatch(wakeupServer('ttNewBusiness'))
         dispatch(wakeupServer('ttSelectBusiness'))         
         dispatch(wakeupServer('ttRespondToRequest'))         
         return null
      }
   }
   export function wakeupBusinessUpdate() {
      return (dispatch) => { 
         console.log('wakeupBusinessUpdate')
         dispatch(wakeupServer('ttUpdateSubscription'))        
         dispatch(wakeupServer('ttUpdateBusinessProfile'))
         dispatch(wakeupServer('ttUpdateBusinessLocation')) 
         dispatch(wakeupServer('ttUpdateBusinessTeam')) 
         return null
      }
   }
// [END]: ALL WAKEUP FUNCTIONS ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

export function createBusiness(name) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
		const functions = getFunctions(firebase)
		const newBusiness = httpsCallable(functions, 'ttNewBusiness')
      newBusiness({ name })
      .then((returned) => {
         // returns businessId, businessProfile
         dispatch(wakeupTimestamp('ttNewBusiness')) // record last time function was used
         let payload = {
         	id: returned.data.businessId,
         	profile: returned.data.businessProfile
         }
         dispatch({ type:BUSINESS_SET, payload:payload })
      	resolve()
      })
      .catch((e) => {
      	let error = {title:'Error',message:''}
      	try { error = JSON.parse(e.message) } 
      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
      	reject(error)
      })
	})
}
export function selectBusiness(id) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
		if (id === null) {
         dispatch({ type: BUSINESS_RESET})
         resolve()
		} else {
			const functions = getFunctions(firebase)
			const select = httpsCallable(functions, 'ttSelectBusiness')
	      select({ id })
	      .then((returned) => {
	         // returns businessProfile
            dispatch(wakeupTimestamp('ttSelectBusiness')) // record last time function was used
	         let payload = {
	         	id: id,
	         	profile: returned.data
	         }
	         dispatch({ type:BUSINESS_SET, payload:payload })
	      	resolve()
	      })
	      .catch((e) => {
	      	let error = {title:'Error',message:''}
	      	try { error = JSON.parse(e.message) } 
	      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	      	reject(error)
	      })
		}		
	})
}

export function respondToRequest(requestId, response={}) {
   return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
      const businessId = getstate().business.id || 0
      const functions = getFunctions(firebase)
      const respond = httpsCallable(functions, 'ttRespondToRequest')
      respond({ businessId:businessId, requestId:requestId, response:response })
      .then((returned) => {
         // returns responseId, response
         dispatch(wakeupTimestamp('ttRespondToRequest')) // record last time function was used
         let payload = {
            id: returned.data.responseId,
            details: returned.data.response
         }
         dispatch({ type:BUSINESS_RESPONSES_UPDATE, payload:payload })
         resolve()
      })
      .catch((e) => {
         let error = {title:'Error',message:''}
         try { error = JSON.parse(e.message) } 
         catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
         reject(error)
      })
   })
}

export function hideRequest(requestId) {
   return (dispatch, getstate, firebase) => {
      let hiddenRequests = JSON.parse(localStorage.getItem('hiddenRequests')) || [] //getstate().business.hidden || []
      hiddenRequests.push(requestId)
      return dispatch({ type:BUSINESS_FEED_HIDE, payload:hiddenRequests })
   }
}

export function updateSubscription(key) {
   return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
      const businessId = getstate().business.id || 0
      const functions = getFunctions(firebase)
      const update = httpsCallable(functions, 'ttUpdateSubscription')
      update({ businessId, key })
      .then((returned) => {
         // returns expiryDate
         dispatch(wakeupTimestamp('ttUpdateSubscription')) // record last time function was used
         dispatch({ type:BUSINESS_SUBSCRIPTION_UPDATE, payload:returned.data })
         resolve()
      })
      .catch((e) => {
         let error = {title:'Error',message:''}
         try { error = JSON.parse(e.message) } 
         catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
         reject(error)
      })      
   })
}

export function updateBusinessInfo(profile) {
   return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
      const businessId = getstate().business.id || 0
      const functions = getFunctions(firebase)
      const update = httpsCallable(functions, 'ttUpdateBusinessProfile')
      update({ businessId, profile })
      .then((returned) => {
         // returns profile
         dispatch(wakeupTimestamp('ttUpdateBusinessProfile')) // record last time function was used
         let payload = {
            id: businessId,
            profile: returned.data
         }
         dispatch({ type:BUSINESS_SET, payload:payload })
         resolve(returned.data.businessImage)
      })
      .catch((e) => {
         let error = {title:'Error',message:''}
         try { error = JSON.parse(e.message) } 
         catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
         reject(error)
      })     
   })
}
export function updateBusinessLocation(lat, lng) {
   return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
      const businessId = getstate().business.id || 0
      const functions = getFunctions(firebase)
      const update = httpsCallable(functions, 'ttUpdateBusinessLocation')
      update({ businessId, lat, lng })
      .then((returned) => {
         // returns businessLocation
         dispatch(wakeupTimestamp('ttUpdateBusinessLocation')) // record last time function was used
         let action = (!lat && !lng) ? 'reset' : 'update'
         if (action === 'reset') {
            let profile = getstate().business.profile
            delete profile.businessLocation
            let payload = { id: businessId, profile: profile }
            dispatch({ type:BUSINESS_SET, payload:payload })
         } else {
            dispatch({ type:BUSINESS_LOCATION_SET, payload:returned.data })
         }
         resolve(action)
      })
      .catch((e) => {
         let error = {title:'Error',message:''}
         try { error = JSON.parse(e.message) } 
         catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
         reject(error)
      })    
   })
}
export function updateBusinessTeam(key) {
   return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
      //const businessId = getstate().business.id || 0
      console.log('updateBusinessTeam')    
   })
}

export function replyToRequest(reply) {
   return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
      const functions = getFunctions(firebase)
      const dReply = httpsCallable(functions, 'ttReplyToRequest')
      dReply({ reply:reply })
      .then(() => {
         // returns 'complete'
         dispatch(wakeupTimestamp('ttReplyToRequest')) // record last time function was used
         resolve()
      })
      .catch((e) => {
         let error = {title:'Error',message:''}
         try { error = JSON.parse(e.message) } 
         catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
         reject(error)
      })
   })
}