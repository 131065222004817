//import { APP_WAKEUP_TIMESTAMP, APP_LAST_UPDATED, APP_LOAD_SETTINGS } from '../types'

const initialState = {
	fbm: 'hi'
}

export default function reducer(state = initialState, action) {
	switch (action.type) {
      case 'APP_WAKEUP_TIMESTAMP':
         return {
            ...state,
            fbm: 'bye!'
         }		
		default:
			return state
	}
}
