import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { wakeupNewRequest, newRequest, submitEmail } from '../../../redux/feed/actions'

import MakeRequest from './MakeRequest'
import RequestSubmitted from './RequestSubmitted'
import SubmitEmail from './SubmitEmail'

import { useTheme } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import IconClose from '@mui/icons-material/Close'
import Text from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'


function RequestPopup({openRequestWindow, setOpenRequestWindow, loggedIn, wakeupNewRequest, newRequest, submitEmail }){
   const [step, setStep] = useState('request') // request, response
   const [requestId, setRequestId] = useState(null)
   const theme = useTheme()
   const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

   useEffect(() => {
      wakeupNewRequest()
   },[])

   const handleAction = (action='cancel', id=null) => {
      if (action === 'cancel') {
         setStep('request')
         setOpenRequestWindow(false)
      }
      if (action === 'response') {
         setStep('response')
         setRequestId(id)
      }
      return null
   }
   const handleClose = (event,reason) => {
      if(reason === 'backdropClick') {
         return false
      }   
      setStep('request')
      setOpenRequestWindow(false)
   }
   return (
      <Dialog fullWidth fullScreen={fullScreen} scroll='body' open={openRequestWindow} onClose={handleClose} style={{maxWidth:'100%', margin:0}}>
         <DialogTitle>
            <div style={{height:40}}>
               <img src='/images/logo-tommi.png' style={{height:'50px', float:'left', marginRight:10}}/>
               <Text variant='h6' color='textSecondary' style={{lineHeight:2.5}}>New Request</Text>
            </div>
            <IconButton onClick={() => handleAction('cancel')} sx={{ position: 'absolute', right: 8, top: 8, color: '#999'}}>
               <IconClose />
            </IconButton>
         </DialogTitle >
         <DialogContent style={{padding:10}} dividers>
         
            {(step === 'request') ? (
               <MakeRequest handleAction={handleAction} newRequest={newRequest} />   
            ):( null )}

            {(step === 'response') ? (
               <div>
                  {(loggedIn) ? (
                     <RequestSubmitted handleAction={handleAction} requestId={requestId} />
                  ):(
                     <SubmitEmail handleAction={handleAction} submitEmail={submitEmail} requestId={requestId} />
                  )}   
               </div>   
            ):( null )}

         </DialogContent>
      </Dialog>
   )
}

const mapStateToProps = (state) => ({
   loggedIn: state.auth.loggedIn || false
})
const mapDispatchToProps = {
   wakeupNewRequest,
   newRequest,
   submitEmail
}
export default connect(mapStateToProps, mapDispatchToProps)(RequestPopup)
