import { combineReducers } from 'redux'
import { AUTH_LOGOUT_USER } from './types'
import adminReducer from './admin/reducer'
import appReducer from './app/reducer'
import authReducer from './auth/reducer'
import businessReducer from './business/reducer'
import cartReducer from './cart/reducer'
import fbmReducer from './fbm/reducer'
import feedReducer from './feed/reducer'

const allReducers = combineReducers({
	admin: adminReducer,
	app: appReducer,
	auth: authReducer,
	business: businessReducer,
	cart: cartReducer,
	fbm: fbmReducer,
	feed: feedReducer
})

const rootReducer = (state, action) => {
	if (action.type === AUTH_LOGOUT_USER) {
		state = undefined
		//this resets all of state...
		//might have to re code so it only resets some state (member, group, etc)
	}
	return allReducers(state, action)
}

export default rootReducer