import { useState } from 'react' 
import { connect } from 'react-redux'
import { addItemToCart } from '../../redux/cart/actions'

import Packages from './Packages'
import Review from './Review'
import Cart from '../components/Cart/_Container'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'

function SubscriptionContainer() {
   const [step, setStep] = useState('packages') // packages, review, cart
   const [selectedPackage, setSelectedPackage] = useState(0)

   const packages = [
      {name:'Package #1', description:'Month to Month', cost:100},
      {name:'Package #2', description:'Quarterly', cost:250},
      {name:'Package #3', description:'Quarterly', cost:450},
   ]

   const handleSelectPackage = (id) => {
      setSelectedPackage(id)
      setStep('review')
   }
   const handleMakePayment = () => {
      //packages[selectedPackage]

      setStep('cart')
      //let sPackage = packages[selectedPackage]
      //console.log(sPackage)
      
   }
   //return(null)
   return (
      <Container maxWidth='lg' style={{ padding: 10, marginTop:50, marginBottom:50 }}>
         <Grid container justifyContent='center' spacing={2}>
            <Grid item xs={12} sm={12} md={10} >
               <Paper elevation={6} style={{ border:'2px solid black', padding: 20, minHeight:700, marginBottom:50 }} >
                  
                  {/*
                  <Grid container justifyContent='center' spacing={2}>
                     <Grid item xs={12} sm={4}>
                        <img style={{maxWidth: '100%'}} src='/images/main-logo.jpg'/> 
                     </Grid>
                     <Grid item xs={12} sm={8} > 
                        <Text variant='h4'>
                           <b>Subscription</b>
                        </Text>
                        <Text variant='body1'> 
                           Subscription Info
                        </Text>

                        {(step === 'packages') ? (
                           <Packages selectPackage={handleSelectPackage}/>   
                        ):( null )} 
                        
                        {(step === 'review') ? (
                           <Review selectedPackage={packages[selectedPackage]} makePayment={handleMakePayment} />   
                        ):( null )} 

                        {(step === 'cart') ? (
                           <Cart />   
                        ):( null )} 
                        
                     </Grid>
                  </Grid>
                  */}

               </Paper>
            </Grid>
         </Grid>
      </Container>
   )
}
const mapStateToProps = (state) => ({
   loggedIn: state.auth.loggedIn || false,
   userInfo: state.auth.userInfo || {},
   businessId: state.business.id || null,
   businessProfile: state.business.profile || {},
})
const mapDispatchToProps = {
   addItemToCart
}
export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionContainer)
