import Text from '@mui/material/Typography'

function Terms() {
   return (
      <div>
         <div style={{marginTop:10, marginBottom:100}}>
         <Text variant='h6'>TERMS OF SERVICE AGREEMENT</Text>



<Text variant='body1'>
Welcome to our website ("Site"). By using our Site, you agree to be bound by the following terms of service ("Agreement"). Please read this Agreement carefully before using the Site.
</Text><br/>
<Text variant='h6'>Acceptance of Terms</Text>
<Text variant='body1'>
By accessing or using our Site, you agree to be bound by this Agreement, our Privacy Policy, and any other guidelines, rules, or additional terms referenced herein or made available on our Site. If you do not agree to any of these terms, you may not access or use our Site.
</Text><br/>

<Text variant='h6'>Description of Service</Text>
<Text variant='body1'>
Our Site provides a platform for users to request vehicle parts, and for vendors to connect with them if they can supply the requested vehicle part. Our Site does not provide or sell vehicle parts, and we are not responsible for any transactions that occur between users and vendors.
</Text><br/>

<Text variant='h6'>User Conduct</Text>
<Text variant='body1'>
You agree to use our Site in a manner consistent with all applicable laws and regulations. You are solely responsible for any content you submit or post on our Site, and you represent and warrant that any content you submit or post does not violate any third-party rights, including intellectual property rights or privacy rights.
</Text><br/>

<Text variant='h6'>Vendor Conduct</Text>
<Text variant='body1'>
Vendors who use our Site must comply with all applicable laws and regulations, including those related to the sale of vehicle parts. Vendors are solely responsible for any transaction they enter into with a user, including the quality and condition of the vehicle parts provided.
</Text><br/>

<Text variant='h6'>Fees</Text>
<Text variant='body1'>
Our Site does not charge any fees for users to request vehicle parts or for vendors to connect with them. However, vendors may charge fees for the sale and delivery of vehicle parts.
</Text><br/>

<Text variant='h6'>Disclaimers and Limitations of Liability</Text>
<Text variant='body1'>
Our Site is provided on an "as-is" and "as available" basis. We make no representations or warranties of any kind, express or implied, regarding the use or the results of the use of our Site in terms of its correctness, accuracy, reliability, or otherwise. We are not liable for any direct, indirect, incidental, consequential, or punitive damages arising out of your use or inability to use our Site.
</Text><br/>

<Text variant='h6'>Indemnification</Text>
<Text variant='body1'>
You agree to indemnify and hold us harmless from any claim or demand, including reasonable attorneys' fees, made by any third-party due to or arising out of your use of our Site, your violation of this Agreement, or your violation of any rights of another.
</Text><br/>


<Text variant='h6'>Termination</Text>
<Text variant='body1'>
We reserve the right to terminate or suspend your access to our Site at any time and without notice, for any reason or no reason, including if we believe that you have violated this Agreement or any applicable law or regulation.
</Text><br/>

<Text variant='h6'>Governing Law and Jurisdiction</Text>
<Text variant='body1'>
This Agreement shall be governed by and construed in accordance with the laws of the state in which we are located, without regard to its conflict of laws principles. Any legal action or proceeding arising under this Agreement shall be brought in the federal or state courts located in the state in which we are located.
</Text><br/>

<Text variant='h6'>Miscellaneous</Text>
<Text variant='body1'>
This Agreement constitutes the entire agreement between you and us with respect to the use of our Site. If any provision of this Agreement is found to be invalid or unenforceable, the remaining provisions shall remain in full force and effect. Our failure to enforce any right or provision of this Agreement shall not constitute a waiver of such right or provision.
</Text><br/>

<Text variant='h6'>Contact Information</Text>
<Text variant='body1'>
If you have any questions or comments about this Agreement, please contact us at [insert contact information].
</Text><br/>

<Text variant='body1'>
By using our Site, you acknowledge that you have read and understand this Agreement, and agree to be bound by its terms and conditions.
</Text><br/>



         </div>
      </div>
   )
}
export default Terms