import { useEffect, useState } from 'react'

import LinearProgress from '@mui/material/LinearProgress'
import Text from '@mui/material/Typography'

function Emails({subscriberEmails, businessEmails, loadEmails}) {
   const [step, setStep] = useState('loading') // loading, error, display
   const [e, setE] = useState({})

   useEffect(() => {
      loadEmails()
      .then(()=> {
         setStep('display')
      })
      .catch((e) => {
         setE(e)
         setStep('error') 
      })
   },[])

   return (
      <div style={{paddingTop:20}}>
         {(step === 'loading') ? (
            <div style={{paddingTop:20, paddingBottom:50, maxWidth:400, minHeight:300, margin:'0 auto'}}>
              Loading Emails...
              <LinearProgress color='inherit' />
            </div>
         ):( null )}

         {(step === 'error') ? (
            <div style={{paddingTop:20, paddingBottom:50, maxWidth:400, minHeight:300, margin:'0 auto'}}>
              <Text variant='h6'>{e.title}</Text>
              <Text>{e.message}</Text>
            </div>
         ):( null )}

         {(step === 'display') ? (  
            <>
            <div style={{paddingTop:20}}>     
               {(subscriberEmails.length) ? (
                  <>
                     <div align='center'><Text variant='h6'>Total Subscriber Emails: {subscriberEmails.length}</Text></div>
                     <div style={{ margin:'0px auto', padding:5}}>
                        {subscriberEmails.map((rec, key) => {
                           return (
                              <span key={key}>{rec}, </span>   
                           )
                        })}
                     </div>
                  </>
               ) : (
                  <div>No Subscriber Emails Listed</div>  
               )}
            </div>
            <div style={{paddingTop:20}}>
               {(businessEmails.length) ? (
                  <>
                     <div align='center'><Text variant='h6'>Total Business Emails: {businessEmails.length}</Text></div>
                     <div style={{ margin:'0px auto', padding:5}}>
                        {businessEmails.map((rec, key) => {
                           return (
                              <span key={key}>{rec}, </span>   
                           )
                        })}
                     </div>
                  </>
               ) : (
                  <div>No Business Emails Listed</div>
               )}
            </div>
            </>
         ):( null )}
      </div>
   )
}
export default Emails
