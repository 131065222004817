import { useState } from 'react'
import { connect } from 'react-redux'
import { submitToMailingList } from '../redux/app/actions'
import { chkEmail }  from '../includes/functions'

import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import IconFacebook from '@mui/icons-material/Facebook'
import IconInstagram from '@mui/icons-material/Instagram'
import IconTwitter from '@mui/icons-material/Twitter'
import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'
import TextField from '@mui/material/TextField'

function MailingList({submitToMailingList}) {
   const [message, setMessage] = useState('')
   const [email, setEmail] = useState('')
   const [step, setStep] = useState('submitEmail')

   const handleChange = (f,v) => {
      f(v)
      setMessage('')
   }
   const handleSubmitEmail = () => {
      if (!chkEmail(email)) {
         return setMessage('Sorry, please enter a valid email address.')
      }
      setStep('processing')
      submitToMailingList(email)
      .then(() => {
         setStep('submitted')
      })
      .catch((err) => {
         setMessage(err)
         setStep('submitEmail')
      }) 
   }
   return (
      <Container maxWidth='lg' style={{ padding: 10, marginTop:50, marginBottom:50 }}>
         <Grid container justifyContent='center' spacing={2}>
            <Grid item xs={12} sm={12} md={10} >        
               <div style={{textAlign:'center', maxWidth:500, margin:'40px auto'}}>
                  <img src='/images/logo-big.png' style={{width:'100%'}}/>
               </div>
               <Paper elevation={6} style={{border:'2px solid black', maxWidth:700, margin:'0 auto', padding: 20 }} >
                  <Text variant='body1'>
                     Welcome to <b>TellTommi.com</b> - the local auto parts finder.
                     We'll be up and running soon, join our mailing list and be among the first to test drive our service. 
                  </Text> 
                  <Text style={{ marginTop: 10, color: 'red', height: 15 }} component='p' align='center'>
                     <i>{ message }</i>
                  </Text>
      
                  {(step === 'submitEmail') ? (
                     <div style={{maxWidth:500, margin:'0 auto', padding:20}}>
                        <Grid container justifyContent='center' spacing={2}>
                           <Grid item xs={12} sm={7}>
                             <TextField onChange={(e) => {handleChange(setEmail, e.target.value) }} fullWidth size='small' label="Enter your email" variant="outlined" />  
                           </Grid>
                           <Grid item xs={12} sm={5} >  
                             <Button onClick={handleSubmitEmail} fullWidth variant="contained">Join Mailing List</Button>
                           </Grid>
                        </Grid>
                     </div>
                  ):( null )}

                  {(step === 'processing') ? (
                     <div style={{maxWidth:500, margin:'0 auto', padding:20}}>
                        Processing your submission...
                        <LinearProgress color='inherit' />
                     </div>
                  ):( null )}

                  {(step === 'submitted') ? (
                     <div style={{maxWidth:500, margin:'0 auto', padding:20}}>
                        <Text variant='subtitle1'>
                           <b>Congratulations!</b> you'll be amongst the first to be notified when we're up and running!
                        </Text> 
                     </div>
                  ):( null )}
    
               </Paper>
               <div style={{textAlign:'center', maxWidth:500, margin:'10px auto', marginTop:100}}>
                  <Text variant='h6' color='white'>@TellTommi&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;#TellTommi</Text>
                  <a href='https://www.twitter.com/telltommi' target='_blank'>
                     <IconButton><IconTwitter style={{fontSize:'2em', color:'white'}} /></IconButton>
                  </a>
                  <a href='https://www.instagram.com/telltommi' target='_blank'>
                     <IconButton><IconInstagram style={{fontSize:'2em', color:'white'}} /></IconButton>
                  </a>
                  <a href='https://www.facebook.com/telltommi' target='_blank'>
                     <IconButton><IconFacebook style={{fontSize:'2em', color:'white'}} /></IconButton>
                  </a>
               </div>
            </Grid>
         </Grid>
      </Container>
   )
}
const mapDispatchToProps = {
  submitToMailingList
}
export default connect(null, mapDispatchToProps)(MailingList)
