import { useEffect } from 'react'
import { connect } from 'react-redux'
import { wakeupLoginPopup, logIn, shortCodeLogin, shortCodeConfirmation, updateUserInfo, updateUserPassword } from '../../../redux/auth/actions'

import LoginStatus from './LoginStatus'
import ProfileUpdate from './ProfileUpdate'

import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import IconClose from '@mui/icons-material/Close'
import Text from '@mui/material/Typography'

function LoginPopup({openLoginWindow, setOpenLoginWindow, loggedIn, userInfo, wakeupLoginPopup, logIn, shortCodeLogin, shortCodeConfirmation, updateUserInfo, updateUserPassword }){
   useEffect(() => {
      wakeupLoginPopup()
   },[])

   const handleClose = (event,reason) => {
      if(reason === 'backdropClick') {
         return false
      }   
      setOpenLoginWindow(false)
   }
   return (
      <Dialog fullWidth scroll='body' open={openLoginWindow} onClose={handleClose} style={{maxWidth:'100%', margin:0}}>
         <DialogTitle>
            <div style={{height:40}}>
               <img src='/images/logo-tommi.png' style={{height:'50px', float:'left', marginRight:10}}/>
               <Text variant='h6' color='textSecondary' style={{lineHeight:2.5}}>Login To Account</Text>
            </div>
            <IconButton onClick={() => setOpenLoginWindow(false)} sx={{ position: 'absolute', right: 8, top: 8, color: '#999'}}>
               <IconClose />
            </IconButton>
         </DialogTitle >
         <DialogContent style={{padding:10}} dividers>
            {(loggedIn) ? (
               <ProfileUpdate 
                  setOpenLoginWindow={setOpenLoginWindow}
                  userInfo={userInfo}
                  updateUserInfo={updateUserInfo}
                  updateUserPassword={updateUserPassword}
               />
            ):(
               <LoginStatus 
                  setOpenLoginWindow={setOpenLoginWindow}
                  logIn={logIn}
                  shortCodeLogin={shortCodeLogin}
                  shortCodeConfirmation={shortCodeConfirmation}
               />
            )}
         </DialogContent>
      </Dialog>
   )
}
const mapStateToProps = (state) => ({
   loggedIn: state.auth.loggedIn || false,
   userInfo: state.auth.userInfo || {}
})
const mapDispatchToProps = {
   wakeupLoginPopup,
   logIn,
   shortCodeLogin,
   shortCodeConfirmation, 
   updateUserInfo,
   updateUserPassword   
}
export default connect(mapStateToProps, mapDispatchToProps)(LoginPopup)
