import { useEffect, useState } from 'react' 
import { Routes, Route, Navigate, Outlet, useLocation, useNavigate, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { monitorBusinessFeed, stopMonitoringBusinessFeed, wakeupBusiness, wakeupBusinessUpdate, createBusiness, selectBusiness, respondToRequest, hideRequest, updateBusinessInfo, updateBusinessLocation, updateBusinessTeam } from '../../redux/business/actions'
import { selectorFilterFeed, selectorFilterResponses } from '../../redux/business/selectors'

import Account from './Account'
import Feed from './Feed'
import ImagePopup from '../components/ImagePopup'
import ResponsePopup from '../components/ResponsePopup/_Container'
import Responses from './Responses'
import SelectBusiness from './SelectBusiness'
import SubscriptionMessage from './SubscriptionMessage'
//import Permission from '../components/FBMessaging/Permission'

import Alert from '@mui/material/Alert'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import IconChange from '@mui/icons-material/ChangeCircleOutlined'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Paper from '@mui/material/Paper'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Text from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'

function Business({handleLogin, loggedIn, userInfo, businessId, businessProfile, businessFeed, businessResponses, monitorBusinessFeed, stopMonitoringBusinessFeed, wakeupBusiness, wakeupBusinessUpdate, createBusiness, selectBusiness, respondToRequest, hideRequest, updateBusinessInfo, updateBusinessLocation, updateBusinessTeam}) {
   const [openResponseWindow, setOpenResponseWindow] = useState(false)
   const [activeTab, setActiveTab] = useState(false) 
   const [requestId, setRequestId] = useState(undefined) 
   const [openImageWindow, setOpenImageWindow] = useState(false)
   const [openImage, setOpenImage] = useState('')
   const location = useLocation()
   const navigate = useNavigate()

   useEffect(() => {
      wakeupBusiness()
      monitorBusinessFeed()
      return () => {
         stopMonitoringBusinessFeed()
      }
   },[businessId]) // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      switch (true) {
         case location.pathname.includes('/business/feed'):
            setActiveTab(0)
         break
         case location.pathname.includes('/business/responses'):
            setActiveTab(1)
         break
         case location.pathname.includes('/business/account'):
            setActiveTab(2)
         break
         default:
            setActiveTab(false)
      }
   },[location]) // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      if (businessId === null) {
         navigate('/business/select')
      } else {
         navigate('/business/feed')
      }      
   },[businessId]) // eslint-disable-line react-hooks/exhaustive-deps

   const handleOpenImage = (img) => {
      setOpenImage(img) 
      setOpenImageWindow(true) 
   }
   const handleChangeBusiness = () => {
       selectBusiness(null)       
   }
   const handleNewResponse = (id) => {
      setRequestId(id)
      setOpenResponseWindow(true)  
   }
   const handlePermissionCB = (nextStep) => {
      // nextStep - tells this page what to do next.
      console.log('nextStep:', nextStep) 
   }

   return (
      <Container maxWidth='lg' style={{ padding: 10, marginTop:50, marginBottom:50 }}>
         {(openResponseWindow) ? (
            <ResponsePopup openWindow={openResponseWindow} setOpenWindow={setOpenResponseWindow} requestId={requestId}/>
         ):(null)}
         {(openImageWindow) ? (
            <ImagePopup openWindow={openImageWindow} setOpenWindow={setOpenImageWindow} image={openImage}/>
         ):( null )}
         <Grid container justifyContent='center' spacing={2}>
            <Grid item xs={12} sm={12} md={10} >
               <div style={{textAlign:'center', maxWidth:500, margin:'20px auto'}}>
                  <img src='/images/logo-big.png' alt='TellTommi' style={{width:'100%'}}/>
               </div>
               {/*
               <Permission
                  cb={handlePermissionCB} // ()=>{}
                  //pageId='notificationsPopupPermission' // notificationsPopupPermission
                  page={'popup'} // popup, default
                  //pageTitle={''}
                  //pageMessage={''}
               />
               */}

               {(loggedIn) ? (
                  <Paper elevation={6} style={{border:'2px solid black', maxWidth:700, margin:'0 auto', marginBottom:100, padding:10, paddingBottom:100 }} >
                     <div align='center'><Text variant='h6'>Business Account</Text></div> 
                     <div style={{display: 'flex', borderBottom: '2px solid #eee'}}>
                        <Box justifyContent='bottom' sx={{flex: 1}}>
                           <Tabs value={activeTab} variant='fullWidth' indicatorColor='primary' textColor='primary'>
                              <Tab label='Feed' component={Link} to='/business/feed'/>
                              <Tab label='Responses' component={Link} to='/business/responses' />
                              <Tab label='Account' component={Link} to='/business/account' />
                           </Tabs>
                        </Box>   
                     </div> 
                     {(businessId === null) ? (
                        <SelectBusiness  
                           userInfo={userInfo} 
                           createBusiness={createBusiness} 
                           selectBusiness={selectBusiness}
                        />
                     ):(                     
                        <div style={{marginTop:20}}>
                           <div align='center' style={{margin:0, padding:0, backgroundColor:'#f1f1f1'}}>
                              <List style={{maxWidth:300, margin:'0 auto'}}>
                                 <ListItem secondaryAction={
                                    <Tooltip title='Change Business'>
                                       <IconButton edge='end' onClick={() => {handleChangeBusiness() }}>
                                          <IconChange />
                                       </IconButton>
                                    </Tooltip>
                                 }>
                                    <ListItemAvatar>
                                       <Avatar alt={businessProfile.businessName} src={businessProfile.businessImage}/> 
                                    </ListItemAvatar>
                                    <ListItemText primary={<Text><b>{businessProfile.businessName}</b></Text>} secondary={businessProfile.businessAddress}/>
                                 </ListItem>                               
                              </List>
                              <div style={{padding:10, maxWidth:600, textAlign:'left' }}>
                                 <Alert severity='info' style={{border:'1px solid #46c4fa'}}>
                                    Please be patient with us. We are ramping up our marketing so that
                                    we can bring more customers to you daily. Please tell us your suggestions
                                    in order for TellTommi to work better for you.
                                 </Alert>
                              </div>
                           </div>
                           <SubscriptionMessage
                              businessId={businessId}
                              businessProfile={businessProfile}
                           />
                           <Routes>
                              <Route path='/feed' element={<Feed 
                                 businessFeed={businessFeed}
                                 respondToRequest={respondToRequest} 
                                 handleNewResponse={handleNewResponse}
                                 handleOpenImage={handleOpenImage}
                                 hideRequest={hideRequest}
                              />} /> 
                              <Route path='/responses' element={<Responses 
                                 businessId={businessId}
                                 businessResponses={businessResponses}
                                 handleNewResponse={handleNewResponse}
                                 handleOpenImage={handleOpenImage}
                              />} />                  
                              <Route path='/account' element={<Account  
                                 userInfo={userInfo}
                                 businessId={businessId}
                                 businessProfile={businessProfile}
                                 wakeupBusinessUpdate={wakeupBusinessUpdate}
                                 updateBusinessInfo={updateBusinessInfo}
                                 updateBusinessLocation={updateBusinessLocation}
                                 updateBusinessTeam={updateBusinessTeam}
                              />} />
                              <Route path='/select' element={<div></div>} />
                              <Route path='*' element={<Navigate to='/business/feed' />} />
                           </Routes>
                           <Outlet />
                        </div>
                     )}                     
                  </Paper>
               ):(
                  <Paper elevation={6} style={{border:'2px solid black', maxWidth:700, margin:'0 auto' }} >
                     <div align='center' style={{margin:0, padding:50}}>
                        <Text variant='body1' color='textSecondary'>Please login to view your Business Profile</Text>
                        <Button style={{marginTop:20}} variant='outlined' onClick={() => {handleLogin()}}>click to login</Button>
                     </div>
                  </Paper>
               )}
            </Grid>
         </Grid>
      </Container>
   )
}
const mapStateToProps = (state) => ({
   loggedIn: state.auth.loggedIn || false,
   userInfo: state.auth.userInfo || {},
   businessId: state.business.id || null,
   businessProfile: state.business.profile || {},
   businessFeed: selectorFilterFeed(state.business.feed, state.business.responses, state.business.hidden) || [],
   businessResponses: selectorFilterResponses(state.business.responses) || []
})
const mapDispatchToProps = {
   monitorBusinessFeed, 
   stopMonitoringBusinessFeed, 
   wakeupBusiness,
   wakeupBusinessUpdate,
   createBusiness,
   selectBusiness,
   respondToRequest,
   hideRequest,
   updateBusinessInfo,
   updateBusinessLocation,
   updateBusinessTeam
}
export default connect(mapStateToProps, mapDispatchToProps)(Business)
