import { useEffect, useState } from 'react' 

import Alert from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'
import IconInfo from '@mui/icons-material/InfoOutlined'

function SubscriptionMessage({businessId, businessProfile}) {
   const [showMessage, setShowMessage] = useState(false)
   const [message, setMessage] = useState('')
   const currentTime = Date.now()
   const tenDays = currentTime - 864000000 // 1 day = 84000000 miliseconds

   useEffect(() => {
      //checkStatus()
   },[businessId, businessProfile])
  
   const checkStatus = () => {
      let m = 'Hi ' + businessProfile.businessName + ', '
   
      // message for new users
         if(businessProfile.newSubscription) {
            m = m + 'you must have an active subscription to respond to requests '
            m = m + 'you can update your subscription on your account page.'
            setMessage(m)
            setShowMessage(true)
         }

      // current subscription expires within 10 days 
         if(!businessProfile.newSubscription && currentTime > tenDays && currentTime < businessProfile.subscriptionExpiryDate) {
            m = m + 'your subscription expires within 10 days, please go to your account page and update your subscription to avoid interuption.'
            setMessage(m)
            setShowMessage(true)  
         }

      // current subscription has expired
         if(!businessProfile.newSubscription && currentTime > tenDays && currentTime > businessProfile.subscriptionExpiryDate) {
            m = m + 'your subscription has expired, please go to your account page to renew your subscription.'
            setMessage(m)
            setShowMessage(true)   
         }
   }

   return (
      <Collapse in={showMessage}>
         <div style={{padding:5}}>
            <Alert icon={<IconInfo fontSize='inherit' />} severity='error' onClose={() => { setShowMessage(false) }}>
               {message}
            </Alert> 
         </div>
      </Collapse>
   )
}
export default SubscriptionMessage
