import { FEED_REQUESTS_UPDATE, FEED_REQUESTS_DELETE } from '../types'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { getFirestore, collection, query, where, onSnapshot } from 'firebase/firestore'
import { wakeupServer, wakeupTimestamp } from '../app/actions'

// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// [START]: ALL LISTENERS HERE :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
      let monitorRequests
      export function monitorFeedRequests() {       
         return (dispatch, getstate, firebase) => {
            console.log('monitorFeedRequests')
            const userId = getstate().auth.userInfo.id || 0
            if (userId) {
	            const db = getFirestore(firebase)
	            const q = query(collection(db, 'requests'), where('userId', '==', userId))
	            monitorRequests = onSnapshot(q, querySnapshot => {
	               querySnapshot.forEach((doc) => {
	                  dispatch({ type: FEED_REQUESTS_UPDATE, payload:{id: doc.id, details: doc.data()} })
			  		   })	
	            }, err => {
	               console.log(`Encountered error: ${err}`);
	            })
            }
         }
      }
		export function stopMonitorFeedRequests(){
			return (dispatch) => {
			   console.log('stopMonitorFeedRequests')
				monitorRequests && monitorRequests()
			}
		}
// [END]: ALL LISTENERS HERE :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// [START]: ALL WAKEUP FUNCTIONS ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++		
   export function wakeupNewRequest() {
      return (dispatch) => {     
         dispatch(wakeupServer('ttNewRequest'))
         dispatch(wakeupServer('ttEmailLogin'))
         return null
      }
   }
   export function wakeupFeed(loggedIn) {
      return (dispatch) => {
      	if(loggedIn) {
            dispatch(wakeupServer('ttCloseRequest'))
            dispatch(wakeupServer('ttDeleteRequest'))
         } 
         return null
      }
   }
// [END]: ALL WAKEUP FUNCTIONS ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

export function newRequest(request) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
		const functions = getFunctions(firebase)
		const openRequest = httpsCallable(functions, 'ttNewRequest')
      openRequest({ request })
      .then((returned) => {
         // returns requestId
         dispatch(wakeupTimestamp('ttNewRequest')) // record last time function was used
      	resolve(returned.data)
      })
      .catch((e) => {
      	let error = {title:'Error',message:''}
      	try { error = JSON.parse(e.message) } 
      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
      	reject(error)
      })
	})
}

export function submitEmail(email, requestId) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
		// Setup return link
		let link = 'https://'+window.location.hostname+'/confirm-email'
		if (window.location.hostname === 'localhost') {
			link = 'http://localhost:3000/confirm-email'
		}
		let returnUrl = '/feed/responses?id=' + requestId

		// Setup actionCodeSettings
		let actionCodeSettings = {
			url: link + '?requestId=' + requestId + '&next=' + returnUrl,
			handleCodeInApp: true
		}
		const functions = getFunctions(firebase)
      const submitEmailConfirmation = httpsCallable(functions, 'ttEmailLogin')
	   submitEmailConfirmation({email:email.toLowerCase(), actionCodeSettings:actionCodeSettings, template:'feed-requests'})
      .then(() => {
      	// returns 'complete'
      	window.localStorage.setItem('emailForSignIn', email.toLowerCase())
         resolve()
      })
      .catch((e) => {
      	let error = {title:'Error',message:''}
      	try { error = JSON.parse(e.message) } 
      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
      	reject(error.message)
      })
	})
}

export function closeRequest(id) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
		const functions = getFunctions(firebase)
      const cRequest = httpsCallable(functions, 'ttCloseRequest')
	   cRequest({id})
      .then(() => {
      	// returns timestamp
      	dispatch(wakeupTimestamp('ttCloseRequest')) // record last time function was used
         resolve()
      })
      .catch((e) => {
      	let error = {title:'Error',message:''}
      	try { error = JSON.parse(e.message) } 
      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
      	reject(error)
      })
	})
}

export function deleteRequest(id) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
		const functions = getFunctions(firebase)
      const dRequest = httpsCallable(functions, 'ttDeleteRequest')
	   dRequest({id:id})
      .then(() => {
      	// returns 'complete'
      	dispatch(wakeupTimestamp('ttDeleteRequest')) // record last time function was used
      	dispatch({ type: FEED_REQUESTS_DELETE, id:id })      	
         resolve()
      })
      .catch((e) => {
      	let error = {title:'Error',message:''}
      	try { error = JSON.parse(e.message) } 
      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
      	reject(error)
      })
	})
}

export function replyToResponse(reply) {
   return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
      const functions = getFunctions(firebase)
      const dReply = httpsCallable(functions, 'ttReplyToResponse')
	   dReply({ reply:reply })
      .then(() => {
      	// returns 'complete'
      	dispatch(wakeupTimestamp('ttReplyToResponse')) // record last time function was used
         resolve()
      })
      .catch((e) => {
      	let error = {title:'Error',message:''}
      	try { error = JSON.parse(e.message) } 
      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
      	reject(error)
      })
   })
}

export function sendMessage(message) {
   return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
      const functions = getFunctions(firebase)
      const dReply = httpsCallable(functions, 'ttMessage')
      dReply({ message:message })
      .then(() => {
         // returns 'complete'
         dispatch(wakeupTimestamp('ttMessage')) // record last time function was used
         resolve()
      })
      .catch((e) => {
         let error = {title:'Error',message:''}
         try { error = JSON.parse(e.message) } 
         catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
         reject(error)
      })
   })
}