import { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import IconAccountCircle from '@mui/icons-material/AccountCircle'
import IconAbout from '@mui/icons-material/InfoOutlined'
import IconBusiness from '@mui/icons-material/StorefrontOutlined'
import IconButton from '@mui/material/IconButton'
import IconContact from '@mui/icons-material/ConnectWithoutContactOutlined'
import IconLogout from '@mui/icons-material/Logout'
import IconMoreVert from '@mui/icons-material/MoreVert'
import IconNewRequest from '@mui/icons-material/NoteAddOutlined'
import IconViewRequests from '@mui/icons-material/NoteAltOutlined'
import ListItemIcon from '@mui/material/ListItemIcon'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Slide from '@mui/material/Slide'
import Text from '@mui/material/Typography'
import Toolbar from '@mui/material/Toolbar'
import useScrollTrigger from '@mui/material/useScrollTrigger'

function HideOnScroll(props) {
  const { children } = props
  return (
    <Slide appear={false} direction='down' in={!useScrollTrigger()}>
      {children}
    </Slide>
  );
}

function TopNav(props) {
   if (props.loggedIn) {
      return ( <LoggedIn userInfo={props.userInfo} newRequest={props.newRequest} logOut={props.logOut}/> )
   } else {
      return ( <PublicNav handleLogin={props.handleLogin}/> )
   }
}

function LoggedIn({userInfo, newRequest, logOut}) {
   const [menuEl, setMenuEl] = useState(null)
   let navigate = useNavigate()
   let location = useLocation()

   const handleClickLink = (link) => {
      setMenuEl(null)
      navigate(link)  
   }
   const handleNewRequest = () => {
      setMenuEl(null)
      newRequest()  
   }
   const handleLogOutUser = () => {
      setMenuEl(null)
      logOut()  
   }
   return (
      <HideOnScroll>
      <AppBar>
         <Container maxWidth='xl'>
            <Toolbar disableGutters variant='dense'>
               <Box sx={{ flexGrow: 1 }}>
                  <Button size='small' color='inherit' component={ Link } to={'/'}>
                     <img src='/images/logo-small.png' style={{width:'150px'}} alt='TellTommi'/>
                  </Button>
               </Box>
               <Button size='large' sx={{padding:0}} color='inherit' endIcon={<IconMoreVert />} onClick={ (event) => { setMenuEl(event.currentTarget) }}>
                  Menu
               </Button>
               <Menu dense='true' anchorEl={menuEl} open={Boolean(menuEl)} onClose={() => { setMenuEl(null) }}>

                  <MenuItem onClick={() => { handleNewRequest() }}>
                     <ListItemIcon><IconNewRequest fontSize='medium'/></ListItemIcon>
                     <Text variant='h6'>Make New Request</Text>
                  </MenuItem>
                  <Divider style={{margin:0}}/> 
                  <MenuItem onClick={() => { handleClickLink('/feed/requests') }} 
                     sx={{ backgroundColor: (location.pathname.includes('/feed/requests')) ? '#E8E8E8' : 'ffffff' }}
                  >
                     <ListItemIcon><IconViewRequests fontSize='medium'/></ListItemIcon>
                     <Text variant='h6'>View Requests</Text>
                  </MenuItem>
                  <Divider style={{margin:0}}/> 
                  <MenuItem onClick={() => { handleClickLink('/about') }} 
                     sx={{ backgroundColor: (location.pathname.includes('/about')) ? '#E8E8E8' : 'ffffff' }}
                  >
                     <ListItemIcon><IconAbout fontSize='medium'/></ListItemIcon>
                     <Text variant='h6'>About Us</Text>
                  </MenuItem>
                  <Divider style={{margin:0}}/> 
                  <MenuItem onClick={() => { handleClickLink('/contact') }} 
                     sx={{ backgroundColor: (location.pathname.includes('/contact')) ? '#E8E8E8' : 'ffffff' }}
                  >
                     <ListItemIcon><IconContact fontSize='medium'/></ListItemIcon>
                     <Text variant='h6'>Contact Us</Text>
                  </MenuItem>
                  <Divider style={{margin:0}}/> 
                  <MenuItem onClick={() => { handleClickLink('/business') }} 
                     sx={{ backgroundColor: (location.pathname.includes('/business')) ? '#E8E8E8' : 'ffffff' }}
                  >
                     <ListItemIcon><IconBusiness fontSize='medium'/></ListItemIcon>
                     <Text variant='h6'>Business Account</Text>
                  </MenuItem>
                  <Divider style={{margin:0}}/> 

                  <MenuItem onClick={handleLogOutUser} >
                     <ListItemIcon><IconLogout fontSize="medium" /></ListItemIcon>
                     <Text variant='h6'>Logout</Text>
                  </MenuItem>
               </Menu>  
            </Toolbar>
         </Container>
      </AppBar>  
      </HideOnScroll>
   )
}   

function PublicNav({handleLogin}) {
   return (
      <HideOnScroll>
         <AppBar>
            <Container maxWidth='xl'>
               <Toolbar disableGutters variant='dense'>
                  <Box sx={{ flexGrow: 1 }}>
                     <Button size='small' color='inherit' component={ Link } to={'/'}>
                        <img src='/images/logo-small.png' style={{width:'150px'}} alt='TellTommi'/>
                     </Button>
                  </Box>
                  <Button  size='small' color='inherit'  startIcon={<IconAccountCircle />} onClick={() => {handleLogin()}}>login / register</Button >
               </Toolbar>
            </Container>
         </AppBar> 
      </HideOnScroll>
   )
}  
export default TopNav
