import { ADMIN_SET_ISADMIN, ADMIN_LOAD_STATS, ADMIN_LOAD_USERS, ADMIN_LOAD_REQUESTS, ADMIN_LOAD_BUSINESSES, ADMIN_LOAD_EMAILS } from '../types'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { getFirestore, doc, collection, query, orderBy, getDoc, getDocs, setDoc, deleteDoc, where, onSnapshot } from 'firebase/firestore'

export function checkAdminStatus() {
   return (dispatch, getstate) => {
      let admins = getstate().app.admins || []
      let userId = getstate().auth.userInfo.id || 0
      let admin = admins.includes(userId)
      dispatch({ type:ADMIN_SET_ISADMIN, payload:admin })
   }
}

export function loadStats() {
   return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
      try{
         let stats = {}
         let isAdmin = getstate().admin.isAdmin || false
         if(!isAdmin) {
            dispatch({ type: ADMIN_LOAD_STATS, payload:stats }) 
            let error = {title:'Access Denied',message:'Sorry, you are not an Admin.'}
            reject(error)
         }

         const db = getFirestore(firebase)
         const docRef = doc(db, 'app', 'stats')
         const docSnap = await getDoc(docRef)
       
         if (docSnap.exists()) {
            stats = docSnap.data()
            dispatch({ type: ADMIN_LOAD_STATS, payload:stats }) 
            resolve()
         } else {
            let error = {title:'Error',message:'Sorry, stats not found.'}
            reject(error)
         }
      }
      catch(e){
         let error = {title:'Error',message:'Sorry, error connecting to database.'}
         reject(error)
      }    
   })
}

export function loadUsers() {
   return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
      try {
         let users = []
         let isAdmin = getstate().admin.isAdmin || false
         if(!isAdmin) {
            dispatch({ type: ADMIN_LOAD_USERS, payload:users }) 
            let error = {title:'Access Denied',message:'Sorry, you are not an Admin.'}
            reject(error)
         }

         const db = getFirestore(firebase)
         const q = query(collection(db, 'users'), orderBy('timestampLastUpdated'))
         const querySnapshot = await getDocs(q)

         querySnapshot.forEach((doc) => {
            users.push(doc.data())
         })
         dispatch({ type: ADMIN_LOAD_USERS, payload:users }) 
         resolve()
      }
      catch(e){
         let error = {title:'Error',message:'Sorry, error connecting to database.'}
         reject(error)
      }     
   })
}

export function loadBusinesses() {
   return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
      try {
         let businesses = []   
         let isAdmin = getstate().admin.isAdmin || false
         if(!isAdmin) {
            dispatch({ type: ADMIN_LOAD_BUSINESSES, payload:businesses }) 
            let error = {title:'Access Denied',message:'Sorry, you are not an Admin.'}
            reject(error)
         }

         const db = getFirestore(firebase)
         const q = query(collection(db, 'businesses'), orderBy('dateTimeCreated'))
         const querySnapshot = await getDocs(q)

         querySnapshot.forEach((doc) => {
            businesses.push(doc.data())
         })
         dispatch({ type: ADMIN_LOAD_BUSINESSES, payload:businesses }) 
         resolve()
      }
      catch(e){
         let error = {title:'Error',message:'Sorry, error connecting to database.'}
         reject(error)
      } 
   })
}

export function loadRequests() {
   return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
      try {
         let requests = []   
         let isAdmin = getstate().admin.isAdmin || false
         if(!isAdmin) {
            dispatch({ type: ADMIN_LOAD_REQUESTS, payload:requests }) 
            let error = {title:'Access Denied',message:'Sorry, you are not an Admin.'}
            reject(error)
         }

         const db = getFirestore(firebase)
         const q = query(collection(db, 'requests'), where('status', '==', 'active')) //, orderBy('dateTimeLastUpdated')
         const querySnapshot = await getDocs(q)

         querySnapshot.forEach((doc) => {
            requests.push(doc.data())
         })
         dispatch({ type: ADMIN_LOAD_REQUESTS, payload:requests }) 
         resolve()
      }
      catch(e){
         console.log(JSON.stringify(e))
         let error = {title:'Error',message:'Sorry, error connecting to database.'}
         reject(error)
      }  
   })
}

export function loadEmails() {
   return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
      try {
         let subscribers = [] 
         let ids = []  
         let businesses = [] 
         let isAdmin = getstate().admin.isAdmin || false
         if(!isAdmin) {
            let emails = {
               subscriberEmails: subscribers,
               businessEmails: businesses
            }
            dispatch({ type: ADMIN_LOAD_EMAILS, payload:emails })
            let error = {title:'Access Denied',message:'Sorry, you are not an Admin.'}
            reject(error)
         }

         const db = getFirestore(firebase)
         const sSnapshot = await getDocs(collection(db, 'subscribers'))
         sSnapshot.forEach((doc) => {
            subscribers.push(doc.data().email)
         })
         const usersSnapshot = await getDocs(collection(db, 'users'))
         usersSnapshot.forEach((doc) => {
            subscribers.push(doc.data().email)
         })
         const idsSnapshot = await getDocs(collection(db, 'businesses'))
         idsSnapshot.forEach((doc) => {
            ids.push(doc.data().createdById)
         })

         const bSnapshot = await getDocs(collection(db, 'users'), where('id', 'in', ids))
         bSnapshot.forEach((doc) => {
            businesses.push(doc.data().email)
         })
         let emails = {
            subscriberEmails: [...new Set(subscribers)],
            businessEmails: [...new Set(businesses)]
         }
         dispatch({ type: ADMIN_LOAD_EMAILS, payload:emails })
         resolve()
      }
      catch(e){
         let error = {title:'Error',message:'Sorry, error connecting to database.'}
         reject(error)
      } 
   })
}

export function approveRequests(requestId) {
   return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
      try {
         let requests = []   
         let isAdmin = getstate().admin.isAdmin || false
         if(!isAdmin) {
            dispatch({ type: ADMIN_LOAD_REQUESTS, payload:requests }) 
            let error = {title:'Access Denied',message:'Sorry, you are not an Admin.'}
            reject(error)
         }

         const db = getFirestore(firebase)
         const reqRef = doc(db, 'requests', requestId);
         setDoc(reqRef, { approved: true }, { merge: true });

         let allRequests = getstate().admin.requests
         let searchIndex = allRequests.findIndex((r) => r.requestId == requestId);
         allRequests[searchIndex].approved = true

         dispatch({ type: ADMIN_LOAD_REQUESTS, payload:allRequests }) 
         resolve()
      }
      catch(e){
         let error = {title:'Error',message:'Sorry, error connecting to database.'}
         reject(error)
      }  
   })
}

export function deleteRequests(requestId) {
   return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
      try {
         let requests = []   
         let isAdmin = getstate().admin.isAdmin || false
         if(!isAdmin) {
            dispatch({ type: ADMIN_LOAD_REQUESTS, payload:requests }) 
            let error = {title:'Access Denied',message:'Sorry, you are not an Admin.'}
            reject(error)
         }

         // delete request
         const db = getFirestore(firebase)
         deleteDoc(doc(db, 'requests', requestId))

         // delete all responses to this request
         const q = query(collection(db, 'responses'), where("requestId", "==", requestId));
         const resSnapshot = await getDocs(q);
         resSnapshot.forEach((r) => {
            deleteDoc(doc(db, 'responses', r.id))
         })

         // update redux - requests
         let allRequests = getstate().admin.requests
         let filteredRequests = allRequests.filter((r) => r.requestId != requestId)

         dispatch({ type: ADMIN_LOAD_REQUESTS, payload:filteredRequests }) 
         resolve()
      }
      catch(e){
         let error = {title:'Error',message:'Sorry, error connecting to database.'}
         reject(error)
      } 
   })
}
