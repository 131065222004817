import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { monitorApp, stopMonitoringApp } from '../../redux/app/actions'
import { logOut } from '../../redux/auth/actions'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Divider from '@mui/material/Divider'
import Text from '@mui/material/Typography'

function SplashScreen({currentVersion, version, live, splashTitle, splashMessage, splashFooter, buttonShow, buttonText, buttonLink, monitorApp, stopMonitoringApp, logOut}) {
   const [open, setOpen] = useState(false)
   const [disabled, setDisabled] = useState(false)
   const navigate = useNavigate()

   useEffect(() => {
      setOpen(!live)
   },[live])  

   useEffect(() => {
      monitorApp()
      return () => {
         stopMonitoringApp()
      }
   },[])

   const handleUpdateApp = () => {
      setDisabled(true)
      //logOut()
      localStorage.removeItem("mData")
      //localStorage.removeItem("hiddenRequests")    
      setTimeout(() => { 
         //window.location.href = window.location
         document.location.reload(true) 
      }, 1000)
   }
   const handleButtonClick = () => {
      setOpen(false)
      if (buttonLink) {
         navigate(buttonLink)
      }
   }

   if (currentVersion !== version) {
      return (
         <Dialog open={true} disableEscapeKeyDown={true}>
            <DialogContent>
               <div align='center'>
                  <Text variant='h6'>New Version Ready</Text>
                  <Divider />
                  <div style={{padding:10}}>
                     <Text variant='body1'>
                      A new version of TellTommi is ready.<br/> 
                      We've made a few updates to impove your experience.<br/>
                      Please click update to continue.
                     </Text>
                  </div>
                  <div><Text variant='caption' color='textSecondary'>Current Version: {currentVersion} - New Version: {version}</Text></div>
               </div>
            </DialogContent>
            <DialogActions>
               <Button disabled={disabled} onClick={handleUpdateApp} variant='contained' color='primary'>update</Button>
            </DialogActions>
         </Dialog>
      )
   }
   return (
      <Dialog open={open} disableEscapeKeyDown={true}>
         <DialogContent>
            <div align='center'>
               <Text variant='h6'>{splashTitle}</Text>
               <Divider />
               <div style={{padding:10}}><Text variant='body1'>{splashMessage}</Text></div>
               <div><Text variant='caption' color='textSecondary'>{splashFooter}</Text></div>
            </div>
         </DialogContent>
         {(buttonShow) ? (
            <DialogActions>
               <Button onClick={handleButtonClick} variant='contained' color='primary'>{buttonText}</Button>
            </DialogActions>
         ) :  ( null )}
      </Dialog>
   )
}
const mapStateToProps = (state) => ({
  currentVersion: state.app.currentVersion,
  version: state.app.version,
  live: state.app.live,
  splashTitle: state.app.splashTitle,
  splashMessage: state.app.splashMessage,
  splashFooter: state.app.splashFooter,
  buttonShow: state.app.buttonShow,
  buttonText: state.app.buttonText,
  buttonLink: state.app.buttonLink
})
const mapDispatchToProps = {
   monitorApp, 
   stopMonitoringApp,
   logOut 
}
export default connect(mapStateToProps, mapDispatchToProps)(SplashScreen)