import { useEffect, useState } from 'react'
import format from 'date-fns/format'
import Swal from 'sweetalert2'

import ImagePopup from '../components/ImagePopup'
import PopupBusinessProfile from './PopupBusinessProfile'
import PopupUserProfile from './PopupUserProfile'

import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import LinearProgress from '@mui/material/LinearProgress'
import Text from '@mui/material/Typography'

function Requests({requests, loadRequests, approveRequests, deleteRequests}) {
   const conditionColor = {'New':'#40be25', 'Used':'#D8B522', 'Both':'#1cc7de'}
   const conditionText = {'New':'New Only', 'Used':'Used Only', 'Both':'New or Used'}  
   const [openImageWindow, setOpenImageWindow] = useState(false)
   const [openImage, setOpenImage] = useState('') 
   const [step, setStep] = useState('loading') // loading, error, display
   const [e, setE] = useState({})
   const [filtered, setFiltered] = useState([])
   const [responseToggle, setResponseToggle] = useState('') 
   const [openUserProfile, setOpenUserProfile] = useState(false)
   const [userProfileId, setUserProfileId] = useState('') 
   const [openBusinessProfile, setOpenBusinessProfile] = useState(false)
   const [businessProfileId, setBusinessProfileId] = useState('') 

   useEffect(() => {
      loadRequests()
      .then(()=> {
         setStep('display')
      })
      .catch((e) => {
         setE(e)
         setStep('error') 
      })
   },[])

   useEffect(() => {
      setFiltered(requests)
   },[requests])

 
   const handleApprove = (id) => {
      setStep('loading') 
      approveRequests(id)
      .then(()=> {
         setStep('display')
      })
      .catch((e) => {
         setE(e)
         setStep('error') 
      })
   }
   const handleDelete = (id) => {
      Swal.fire({
         title: 'Delete Request',
         text: 'Are you sure you would like to delete this request?',
         showCancelButton: true,
         showConfirmButton: true,
         confirmButtonText: 'Delete'
      }).then((result) => {
         if (result.isConfirmed) {
            setStep('loading') 
            deleteRequests(id)
            .then(()=> {
               setStep('display')
            })
            .catch((e) => {
               setE(e)
               setStep('error') 
            })            
         }
      })
   }
   const handleToggle = (id) => {
      if(responseToggle === id) {
         setResponseToggle('')
      } else {
         setResponseToggle(id) 
      }
   }  
   const handleOpenImage = (img) => {
      setOpenImage(img) 
      setOpenImageWindow(true) 
   }
   const handleViewVendor = (id) => {
      setBusinessProfileId(id)  
      setOpenBusinessProfile(true)
   }
   const handleViewUser = (id) => {
      setUserProfileId(id)  
      setOpenUserProfile(true) 
   }
   return (
      <div style={{paddingTop:20}}>
         {(openImageWindow) ? (
            <ImagePopup openWindow={openImageWindow} setOpenWindow={setOpenImageWindow} image={openImage}/>
         ):( null )}
         {(openBusinessProfile) ? (
            <PopupBusinessProfile openWindow={openBusinessProfile} setOpenWindow={setOpenBusinessProfile} profileId={businessProfileId}/>
         ):( null )}
         {(openUserProfile) ? (
            <PopupUserProfile openWindow={openUserProfile} setOpenWindow={setOpenUserProfile} profileId={userProfileId}/>
         ):( null )}


         <div align='center' style={{padding:5, marginBottom:25}}>
            <Text variant='h5'>Requests</Text>
            <Text variant='body2' style={{color:'gray'}}>Total Active Requests: {filtered.length}</Text>
            <Divider/>
         </div>

         {(step === 'loading') ? (
            <div style={{paddingTop:20, paddingBottom:50, maxWidth:400, minHeight:300, margin:'0 auto'}}>
              Loading Requests...
              <LinearProgress color='inherit' />
            </div>
         ):( null )}

         {(step === 'error') ? (
            <div style={{paddingTop:20, paddingBottom:50, maxWidth:400, minHeight:300, margin:'0 auto'}}>
              <Text variant='h6'>{e.title}</Text>
              <Text>{e.message}</Text>
            </div>
         ):( null )}

         {(step === 'display') ? (
            (filtered.length) ? (
               <List style={{ margin:'0px auto', padding:5}}>
                  {filtered.map((rec, key) => {
                     return (
                        <Grid container key={key} justifyContent='center' alignItems='center' spacing={.5}>
                           <Grid item  xs={12} sm={9}>
                              <span>
                                 <Text variant='body2' component='span' style={{paddingLeft:5, paddingRight:5, borderRadius:5, backgroundColor:conditionColor[rec.condition], color:'#fff'}}>{conditionText[rec.condition]}</Text> 
                                 <Text variant='body2' component='span' style={{paddingLeft:5, paddingRight:5}}><i>{rec.year}</i></Text>
                                 <Text variant='body2' component='span' style={{paddingLeft:5, paddingRight:5}}><b>{rec.make}</b></Text> 
                                 <Text variant='body2' component='span' style={{paddingLeft:5, paddingRight:5}}>{rec.model}</Text>
                              </span>
                              <div style={{display:'flex', margin:5, padding:5, borderTop:'1px solid lightgray', borderBottom:'1px solid lightgray'}}>
                                 <img onClick={() => {handleOpenImage(rec.image)}} src={rec.image} style={{float:'left', cursor: 'pointer', maxWidth:100, margin:5, padding:2, border:'1px solid gray'}}/>  
                                 <div>
                                    <Text color='text.secondary' variant='caption'><i>{format(new Date(rec.dateTimeCreated), 'eee do MMM y - h:mm a')}</i></Text>
                                    <Text variant='body2' color='text.secondary'><b><i>Part Description</i></b></Text>
                                    <Text variant='body2'>{rec.part}</Text> 
                                 </div>
                              </div> 
                              <div style={{textAlign:'right'}}>
                                 <Text component='span' style={{color:'gray', paddingLeft:5, paddingRight:5}}><i>[{rec.status}]</i></Text>
                                 <Button onClick={() => { handleViewUser(rec.userId) }}><i>by: {rec.userName}</i></Button>
                              </div>
                              <span>
                                 <Collapse in={responseToggle === rec.requestId}>
                                    {rec.responses && Object.values(rec.responses).map((r, key) => {
                                       return (
                                          <Grid container key={key} style={{border: '1px solid lightgray', padding:5, margin:5}} justifyContent='center' alignItems='center' spacing={.5}>
                                             <Grid item  xs={3} style={{textAlign:'center'}}>
                                                <Button onClick={() => { handleViewVendor(r.businessId) }}>{r.businessName}</Button> 
                                             </Grid>
                                             <Grid item  xs={9}>
                                                {r.dialogue.map((d, k) => {
                                                   return (
                                                      <div key={k}>
                                                         {(d.type === 'response') ? (
                                                            <Text variant='subtitle1' style={{color:'gray'}}><b>Vendor</b></Text>
                                                         ) : (
                                                            <Text variant='subtitle1' style={{color:'gray'}}><b>User</b></Text> 
                                                         )}
                                                         <div style={{display:'flex', marginBottom:15}}>
                                                            {(d.image !=='') ? (
                                                               <img onClick={() => {handleOpenImage(d.image)}} src={d.image} alt='TellTommi' style={{float:'left', cursor: 'pointer', maxWidth:100, margin:5, padding:2, border:'1px solid gray'}}/>  
                                                            ):(null)}
                                                            <div>
                                                               <Text color='text.secondary' variant='caption'><i>{format(new Date(d.timestamp), 'eee do MMM y - h:mm a')}</i></Text> 
                                                               <Text variant='body1'>{d.message}</Text>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   )
                                                })}   
                                             </Grid>
                                          </Grid>
                                       )
                                    })}
                                  </Collapse>
                              </span>
                           </Grid>
                           <Grid item  xs={12} sm={3} style={{textAlign:'center', padding:15}}>
                              <Button fullWidth onClick={() => { handleDelete(rec.requestId) }} size='small' variant='outlined' style={{margin:3}}>delete</Button>
                              {(!rec.approved) ? (
                              <Button fullWidth onClick={() => { handleApprove(rec.requestId) }} size='small' variant='contained' style={{margin:3}}>approve</Button>
                              ):(null)}
                              <Button fullWidth onClick={() => { handleToggle(rec.requestId) }} size='small' variant='outlined' style={{margin:3}}>Responses [{rec.responsesCount}]</Button>
                           </Grid>
                           <Grid item  xs={12}><Divider style={{color:'darkgray', borderTop:'3px solid black'}}/></Grid>
                        </Grid>
                     )
                  })}
               </List>
            ) : (
               <div>No Requests Listed</div>  
            )
         ):( null )}
      </div>
   )
}
export default Requests
