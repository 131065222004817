import { APP_WAKEUP_TIMESTAMP, APP_LOAD_SETTINGS } from '../types'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { getFirestore, doc, collection, addDoc, onSnapshot } from 'firebase/firestore'

// [START]: App Settings Listener :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
	let monitorAppSettings
	export function monitorApp() {
		return (dispatch, getstate, firebase) => {   
			const db = getFirestore(firebase)
         const docRef = doc(db, 'app', 'settings')
         monitorAppSettings = onSnapshot(docRef, querySnapshot => { 
				if (querySnapshot.data()) {
					let data = querySnapshot.data()
					dispatch({ type: APP_LOAD_SETTINGS, payload: data })
				}
			}, err => {
				console.log('monitorAppSettings error:', err)
			})
		}
	}
	export function stopMonitoringApp(){
		return () => {
			monitorAppSettings && monitorAppSettings()
		}
	}
// [END]: App Settings Listener :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

// [START]: App Utility that reduces firebase calls :::::::::::::::::::::::::::::::::::::::::
	export function chkExpiredData(force=true, reducer=null){
		return (dispatch, getstate) => new Promise((resolve, reject) => {
				// TRUE - resolve if date expired
				// TRUE - resolve if force is true
				// FALSE - reject if date is still good    
				let currentTime = Math.floor(Date.now() / 1000)
				if (force) {
					 return resolve(currentTime)
				}
				if (reducer) {
					 let lastUpdated = (getstate().app.lastUpdated.hasOwnProperty(reducer)) ? getstate().app.lastUpdated[reducer] : 0
					 let updateInterval = (getstate().app.updateIntervals.hasOwnProperty(reducer)) ?  getstate().app.updateIntervals[reducer] : getstate().app.updateIntervals.default
					 let expiresAt = lastUpdated + updateInterval
					 if (currentTime < expiresAt) {
							reject('current data good')
					 } else  {    
							resolve(currentTime)
					 }
				} else {
						resolve(currentTime)
				}
		})
	}
// [END]: App Utility that reduces firebase calls :::::::::::::::::::::::::::::::::::::::::

// [START]: Firebase Wakeup Functions ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// these functions are used to help eliminate firebase cold starts by calling functions before they are needed
// call wakeupServer to wake up future function; and call wakeupTimestamp to update functions in use :::::::::
   export function wakeupServer(fbFunction){
      return (dispatch, getState, firebase) => {
         let refreshLimit = 480 // 8 minutes (8 * 60 = 480)
         let wakeupTimestamps = getState().app.wakeupTimestamps
         let thisTimestamp = (wakeupTimestamps && wakeupTimestamps.hasOwnProperty(fbFunction)) ? wakeupTimestamps[fbFunction] : 0
         let expiresAt = thisTimestamp + refreshLimit
         let currentTime = Math.floor(Date.now() / 1000)
         if (currentTime >= expiresAt) {
            const functions = getFunctions(firebase)
		      const wakeFunction = httpsCallable(functions, fbFunction)        
            let count = 0
            let wakeupInterval = setInterval(() => {
                  // ======
                     wakeFunction({wakeup: true})
                     .then((result) => {
                        console.log('wakeup function: ', fbFunction)
                     })
                     .catch((error)=>{
                        console.log('wakeup error:',error)
                     })
                  // ======
                  count = count + 1
                  if (count >= 3) clearInterval(wakeupInterval)
            }, 5000) //
            let payload = { key:fbFunction, value:currentTime }
            return dispatch({ type:APP_WAKEUP_TIMESTAMP, payload:payload })
         } else {
            return null
         } 
      }
   }

   // this function DOES NOT call the firebase function. it only updates the last time the function was called.
   export function wakeupTimestamp(fbFunction){
      return (dispatch) => {
         let currentTime = Math.floor(Date.now() / 1000)
         let payload = { key:fbFunction, value:currentTime }
         return dispatch({ type:APP_WAKEUP_TIMESTAMP, payload:payload })
      }
   }
// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// [END]: Firebase Wakeup Functions ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
 

 	export function submitToMailingList(email){
		return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
			console.log('email submitted:', email)
      const db = getFirestore(firebase)
		  addDoc(collection(db, 'subscribers'), {
			  email: email, 
			  timestamp: new Date()
			})
			.then(function(docRef) {
				  resolve('done')
      })
      .catch(function(error) {
          reject(error.message)
      })
		})
	}

	export function submitToVisitorMessages(name, email, message){
		return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
      const db = getFirestore(firebase)
		  addDoc(collection(db, 'visitorMessages'), {
			  name: name,
			  email: email,
			  message: message, 
			  timestamp: new Date()
			})
			.then(function(docRef) {
				  resolve('done')
      })
      .catch(function(error) {
          reject(error.message)
      })
		})
	}