import { useEffect, useState } from 'react'
import format from 'date-fns/format'

import PopupUserProfile from './PopupUserProfile'

import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import Text from '@mui/material/Typography'

function Users({users, loadUsers}) {
   const [step, setStep] = useState('loading') // loading, error, display
   const [e, setE] = useState({})
   const [filtered, setFiltered] = useState([])
   const [openUserProfile, setOpenUserProfile] = useState(false)
   const [userProfileId, setUserProfileId] = useState('') 

   useEffect(() => {
      loadUsers()
      .then(()=> {
         setStep('display')
      })
      .catch((e) => {
         setE(e)
         setStep('error') 
      })
   },[])

   useEffect(() => {
      setFiltered(users)
   },[users])

   const handleViewUser = (id) => {
      setUserProfileId(id)  
      setOpenUserProfile(true) 
   }

   return (
      <div style={{paddingTop:20}}>
         {(openUserProfile) ? (
            <PopupUserProfile openWindow={openUserProfile} setOpenWindow={setOpenUserProfile} profileId={userProfileId}/>
         ):( null )}

         <div align='center' style={{padding:5, marginBottom:25}}>
            <Text variant='h5'>Users</Text>
            <Text variant='body2' style={{color:'gray'}}>Total Users: {filtered.length}</Text>
            <Divider/>
         </div>

         {(step === 'loading') ? (
            <div style={{paddingTop:20, paddingBottom:50, maxWidth:400, minHeight:300, margin:'0 auto'}}>
              Loading site users...
              <LinearProgress color='inherit' />
            </div>
         ):( null )}

         {(step === 'error') ? (
            <div style={{paddingTop:20, paddingBottom:50, maxWidth:400, minHeight:300, margin:'0 auto'}}>
              <Text variant='h6'>{e.title}</Text>
              <Text>{e.message}</Text>
            </div>
         ):( null )}

         {(step === 'display') ? (
            (filtered.length) ? (
               <div>
                  {filtered.map((rec, key) => {
                     return (
                        <Grid container key={key} justifyContent='center' alignItems='center' spacing={.5}>
                           <Grid item xs={12} sm={4} style={{textAlign:'left'}}>
                              <Text variant='body1'><b>{rec.name}</b></Text>
                              <Text variant='body2' style={{color:'gray'}}>
                                 <i>Last Update: {format(new Date(rec.timestampLastUpdated), 'd/M/y')}</i>
                              </Text>
                           </Grid> 
                           <Grid item xs={12} sm={4} style={{textAlign:'left'}}>
                              <Text variant='body1'>{rec.email}</Text>
                              <Text variant='body2' style={{color:'gray'}}>{rec.cell}</Text>                           
                           </Grid> 
                           <Grid item xs={12} sm={3} style={{textAlign:'left'}}>
                              {(rec.businesses && rec.businesses.length) ? (
                                 rec.businesses.map((r, k) => {
                                    return (
                                       <Text key={k} variant='body1'>{r.name}</Text> 
                                    )
                                 })
                              ):(
                                 <Text variant='body2' style={{color:'gray'}}>No Businesses Listed</Text> 
                              )}                     
                           </Grid> 
                           <Grid item xs={12} sm={1} style={{textAlign:'left'}}>
                              <Button onClick={() => { handleViewUser(rec.id) }} fullWidth size='small' variant='text'>view profile</Button>
                           </Grid>
                           <Grid item  xs={12}><Divider/></Grid>
                        </Grid>                       
                     )
                  })}
               </div>
            ) : (
               <div>No Users Listed</div>  
            )
         ):( null )}
      </div>
   )
}
export default Users
