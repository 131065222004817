import { useEffect, useState } from 'react'
import format from 'date-fns/format'

import PopupBusinessProfile from './PopupBusinessProfile'

import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import LinearProgress from '@mui/material/LinearProgress'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Text from '@mui/material/Typography'

function Businesses({businesses, loadBusinesses}) {
   const [step, setStep] = useState('loading') // loading, error, display
   const [e, setE] = useState({})
   const [filtered, setFiltered] = useState([])
   const [openBusinessProfile, setOpenBusinessProfile] = useState(false)
   const [businessProfileId, setBusinessProfileId] = useState('') 

   useEffect(() => {
      loadBusinesses()
      .then(()=> {
         setStep('display')
      })
      .catch((e) => {
         setE(e)
         setStep('error') 
      })
   },[])

   useEffect(() => {
      setFiltered(businesses)
   },[businesses])

   const handleViewVendor = (id) => {
      setBusinessProfileId(id)  
      setOpenBusinessProfile(true)
   }

   return (
      <div style={{paddingTop:20}}>
         {(openBusinessProfile) ? (
            <PopupBusinessProfile openWindow={openBusinessProfile} setOpenWindow={setOpenBusinessProfile} profileId={businessProfileId}/>
         ):( null )}

         <div align='center' style={{padding:5, marginBottom:25}}>
            <Text variant='h5'>Businesses</Text>
            <Text variant='body2' style={{color:'gray'}}>Total Businesses: {filtered.length}</Text>
            <Divider/>
         </div>

         {(step === 'loading') ? (
            <div style={{paddingTop:20, paddingBottom:50, maxWidth:400, minHeight:300, margin:'0 auto'}}>
              Loading businesses...
              <LinearProgress color='inherit' />
            </div>
         ):( null )}

         {(step === 'error') ? (
            <div style={{paddingTop:20, paddingBottom:50, maxWidth:400, minHeight:300, margin:'0 auto'}}>
              <Text variant='h6'>{e.title}</Text>
              <Text>{e.message}</Text>
            </div>
         ):( null )}

         {(step === 'display') ? (
            (filtered.length) ? (
               <List style={{ margin:'0px auto', padding:5}}>
                  {filtered.map((rec, key) => {
                     return (
                        <div key={key}>
                           <ListItem>
                              <div style={{marginLeft:10, paddingTop:5, width:'100%'}}>
                                 <Text variant='body1'><b>{rec.businessName}</b></Text>
                                 <Text variant='body2'>{rec.businessAddress}</Text>
                                 <Text variant='body2'>{rec.businessPhone}</Text>
                                 <Text color='text.secondary' variant='caption'><i>Created: {format(new Date(rec.dateTimeCreated), 'eee do MMM y - h:mm a')}</i></Text> 
                               <Button onClick={() => { handleViewVendor(rec.businessId) }} fullWidth size='small' variant='text'>view profile</Button>
                              </div>
                           </ListItem>
                           <Divider/>
                        </div>   
                     )
                  })}
               </List>
            ) : (
               <div>No Businesses Listed</div>  
            )
         ):( null )}

      </div>
   )
}
export default Businesses
