import { useState } from 'react' 
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import format from 'date-fns/format'

import Loader from '../components/Loader'

import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Text from '@mui/material/Typography'

function Requests({requests, handleNewRequest, hasArchives, closeRequest, deleteRequest, handleOpenImage}) {
   const conditionColor = {'New':'#40be25', 'Used':'#D8B522', 'Both':'#1cc7de'}
   const conditionText = {'New':'New Only', 'Used':'Used Only', 'Both':'New or Used'}
   const [loading, setLoading] = useState(false)
   const navigate = useNavigate()

   const handleViewResponses = (id) => {
      navigate('/feed/responses?id='+id)
   } 
   const handleCloseRequest = (id) => {
      Swal.fire({
         title: 'Close Request',
         text: 'Are you sure you would like to close this request.',
         showCancelButton: true,
         showConfirmButton: true,
         confirmButtonText: 'Close'
      }).then((result) => {
         if (result.isConfirmed) { 
            setLoading(true)
            closeRequest(id)
            .then(() => {
               setLoading(false)
            })
            .catch((e) => {
               setLoading(false)
               Swal.fire({
                  title: e.title, 
                  text: e.message,
                  showConfirmButton: true
               })
            })
         }
      })
   }
   const handleDeleteRequest = (id) => {
      Swal.fire({
         title: 'Delete Request',
         text: 'Are you sure you would like to delete this request.',
         showCancelButton: true,
         showConfirmButton: true,
         confirmButtonText: 'Delete'
      }).then((result) => {
         if (result.isConfirmed) { 
            setLoading(true)
            deleteRequest(id)
            .then(() => {
               setLoading(false)
            })
            .catch((e) => {
               setLoading(false)
               Swal.fire({
                  title: e.title, 
                  text: e.message,
                  showConfirmButton: true
               })
            })
         }
      })      
   }

   return (
      <div>
         <Loader loading={loading} title='Processing' text='processing your request...'/>
         <Paper elevation={6} style={{border:'2px solid black', maxWidth:600, margin:'0 auto', padding:20 }} >
            <Text variant='h6'>Requests</Text>
            <Text variant='body2'>This is a list of your Open Requests, closed requests are archived.</Text>
            <Alert severity='info' style={{margin:5, border:'1px solid #46c4fa'}}>
               Please be patient with us. We are adding new vendors daily so that we can make your parts
               search experience better. 
            </Alert>
            <div style={{textAlign:'right', paddingTop:10}}>
               <Button variant='contained' size='small' onClick={handleNewRequest}>Make New Request</Button> 
            </div>
         </Paper>         

         {(requests.length) ? (
            <div>
               {requests.map((rec, key) => {
                  return (
                     <Card key={key} elevation={6} style={{border:'2px solid black', maxWidth:600, margin:'0 auto', marginTop:25}}>
                        <CardContent>
                           <Grid container justifyContent='center' alignItems='center' spacing={0}>
                              <Grid item xs={12} sm={6} style={{textAlign:'center'}}>
                                 <Grid container justifyContent='center' alignItems='center' spacing={0.5}>
                                    <Grid item xs={4} style={{textAlign:'right'}}>
                                       <Text variant='body2' color='text.secondary'>VIN/Year:</Text> 
                                    </Grid>
                                    <Grid item xs={8} style={{textAlign:'left'}}>       
                                       <Text variant='body2'>{rec.year}</Text>           
                                    </Grid> 
                                    <Grid item xs={4} style={{textAlign:'right'}}>
                                       <Text variant='body2' color='text.secondary'>Make:</Text> 
                                    </Grid>
                                    <Grid item xs={8} style={{textAlign:'left'}}>       
                                       <Text variant='body2'>{rec.make}</Text>           
                                    </Grid> 
                                    <Grid item xs={4} style={{textAlign:'right'}}>
                                       <Text variant='body2' color='text.secondary'>Model:</Text> 
                                    </Grid>
                                    <Grid item xs={8} style={{textAlign:'left'}}>       
                                       <Text variant='body2'>{rec.model}</Text>           
                                    </Grid> 

                                 </Grid>
                              </Grid>
                              <Grid item xs={12} sm={6} style={{textAlign:'center'}}>
                                 <Grid container justifyContent='center' alignItems='center' spacing={0.5}>
                                    <Grid item xs={4} style={{textAlign:'right'}}>
                                       <Text variant='body2' color='text.secondary'>Request ID:</Text> 
                                    </Grid>
                                    <Grid item xs={8} style={{textAlign:'left'}}>       
                                       <Text variant='body2'><i>{rec.requestId}</i></Text>            
                                    </Grid>
                                    <Grid item xs={4} style={{textAlign:'right'}}>
                                       <Text variant='body2' color='text.secondary'>Condition:</Text> 
                                    </Grid>
                                    <Grid item xs={8} style={{textAlign:'left'}}>       
                                       <Text variant='body2' component='span' style={{paddingLeft:5, paddingRight:5, borderRadius:5, backgroundColor:conditionColor[rec.condition], color:'#fff'}}>{conditionText[rec.condition]}</Text>            
                                    </Grid> 
                                    <Grid item xs={4} style={{textAlign:'right'}}>
                                       <Text variant='body2' color='text.secondary'>Responses:</Text> 
                                    </Grid>
                                    <Grid item xs={8} style={{textAlign:'left'}}>       
                                       <Text variant='body2' style={{color:'red'}}><b>{rec.responsesCount || 0}</b></Text>          
                                    </Grid> 
                                 </Grid>
                              </Grid>                              
                              <Grid item xs={12}>
                                 <div style={{display:'flex', margin:5, padding:5, borderTop:'1px solid gray', borderBottom:'2px solid darkgray'}}>
                                    <img onClick={() => {handleOpenImage(rec.image)}} src={rec.image} style={{float:'left', cursor: 'pointer', maxWidth:100, margin:5, padding:2, border:'1px solid gray'}}/>  
                                    <div>
                                       <Text variant='body2' color='text.secondary'><b><i>Part Description</i></b></Text>
                                       <Text variant='body2'>{rec.part}</Text> 
                                    </div>
                                 </div>   
                              </Grid>
                           </Grid>                     
                        </CardContent>
                        <CardActions>
                           <Stack style={{marginTop:10, margin:'0 auto'}} direction="row" justifyContent="center" alignItems="center" spacing={2} >
                              {(!rec.responsesCount) ? (
                                 <Button size='small' onClick={() => { handleDeleteRequest(rec.requestId) }}>Delete request</Button>
                              ):( null )}
                              <Button size='small' onClick={() => { handleCloseRequest(rec.requestId) }} variant='outlined'>Close request</Button>
                              <Button size='small' onClick={() => {handleViewResponses(rec.requestId)}} variant='contained'>View Responses</Button>
                           </Stack>
                        </CardActions>
                     </Card>
                  )
               })}
            </div>
         ) : (
            <Paper elevation={6} style={{border:'2px solid black', maxWidth:600, margin:'0 auto', marginTop:20, padding:20 }} >
               <div align='center'>  
                  <Text variant='h6' style={{color:'#ff0000'}}>Open Requests Would Be Listed Here</Text>  
               </div>
            </Paper>
         )}    
         {(hasArchives) ? (
            <div style={{ margin:'0 auto', textAlign:'right', maxWidth:600, marginBottom:80, paddingTop:10}}>
               <Button component={Link} to='/feed/archives' size='small' variant='text' style={{color:'white'}}>View Archived Requests</Button>
            </div>
         ):( 
            <div style={{ margin:'0 auto', textAlign:'right', maxWidth:600, marginBottom:80, paddingTop:10}} />
         )}
      </div>
   )
}
export default Requests
