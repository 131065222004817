import { BUSINESS_INFO, BUSINESS_RESET, BUSINESS_SET, BUSINESS_LOCATION_SET, BUSINESS_FEED_DELETE, BUSINESS_FEED_HIDE, BUSINESS_FEED_UPDATE, BUSINESS_RESPONSES_UPDATE, BUSINESS_SUBSCRIPTION_UPDATE } from '../types'

const initialState = {
	id: null,
	profile: {},
	feed: {},
	responses: {},
	hidden: []
}

export default function reducer(state = initialState, action) {
  	switch (action.type) {
      case BUSINESS_RESET:
         return { 
				id: null,
				profile: {},
				feed: {},
				responses: {}
			}
      case BUSINESS_SET:
         return { 
				...state,
				id: action.payload.id,
				profile: action.payload.profile
			}
		case BUSINESS_INFO:
         return { 
				...state,
				profile: action.payload
			}	
      case BUSINESS_LOCATION_SET:
         return { 
				...state,
				profile: {
					...state.profile,
					businessLocation: action.payload
			   }
			}			
		case BUSINESS_FEED_DELETE:
         return { 
				...state,
				feed: {},
	         responses: {}
			}
	   case BUSINESS_FEED_HIDE:
         return { 
				...state,
				hidden: action.payload
			}		
		case BUSINESS_FEED_UPDATE:
         return { 
				...state,
				feed: {
               ...state.feed,
               [action.payload.id]: action.payload.details
            }
			}	
		case BUSINESS_RESPONSES_UPDATE:
         return { 
				...state,
				responses: {
               ...state.responses,
               [action.payload.id]: action.payload.details
            }
			}
		case BUSINESS_SUBSCRIPTION_UPDATE:
         return { 
				...state,
				profile: {
               ...state.profile,
               newSubscription: false,
               subscriptionExpiryDate: action.payload
            }
			}	
		default:
			return state
	}
}
