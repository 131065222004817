import { ADMIN_SET_ISADMIN, ADMIN_LOAD_STATS, ADMIN_LOAD_USERS, ADMIN_LOAD_REQUESTS, ADMIN_LOAD_BUSINESSES, ADMIN_LOAD_EMAILS } from '../types'

const initialState = {
	isAdmin: false,
	stats: {},
	users: [],
	businesses: [],
	requests: [],
	subscriberEmails: [],
	businessEmails: [],
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
		case ADMIN_SET_ISADMIN:
			return { 
				...state,
				isAdmin: action.payload
			}
		case ADMIN_LOAD_STATS:
			return { 
				...state,
				stats: action.payload
			}
		case ADMIN_LOAD_USERS:
			return { 
				...state,
				users: action.payload
			}	
		case ADMIN_LOAD_REQUESTS:
			return { 
				...state,
				requests: action.payload
			}
		case ADMIN_LOAD_BUSINESSES:
			return { 
				...state,
				businesses: action.payload
			}
		case ADMIN_LOAD_EMAILS:
			return { 
				...state,
				subscriberEmails: action.payload.subscriberEmails,
            businessEmails: action.payload.businessEmails
			}
		default:
			return state
	}
}
