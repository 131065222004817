import GoogleMapReact from 'google-map-react'

import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import IconClose from '@mui/icons-material/Close'
import IconMarker from '@mui/icons-material/Room'
import Text from '@mui/material/Typography'

const MapMarker = ({ text }) => <div style={{marginRight:20}}><IconMarker color='primary'/>{text}</div>

function LocationPopup({ openWindow, setOpenWindow, action, location, name }){
   return (
      <Dialog fullWidth scroll='body' open={openWindow} onClose={() => setOpenWindow(false) } style={{maxWidth:400, margin:'0 auto'}}>
         <DialogTitle>
            <Text style={{color:'#999'}}>Location</Text>
            <IconButton onClick={() => setOpenWindow(false) } sx={{ position: 'absolute', right: 8, top: 8, color: '#999'}}>
               <IconClose />
            </IconButton>
         </DialogTitle >
         <DialogContent style={{padding:10}} dividers>
            
            <div align='center' style={{height: '300px', width: '100%', padding:1, backgroundColor:'#f1f1f1'}}>
               <GoogleMapReact
                 bootstrapURLKeys={{ key: 'AIzaSyCShnSE8rsYBi9lwHlXrDUMDL1D0fiDlnU' }}
                 defaultCenter={{lat:location.lat, lng:location.lng}}
                 defaultZoom={11}
               >
                 <MapMarker
                   lat={location.lat}
                   lng={location.lng}
                   text={name}
                 />
               </GoogleMapReact>
            </div>

         </DialogContent>
      </Dialog>
   )
}
export default LocationPopup
