import { AUTH_LOGIN_USER, AUTH_LOGOUT_USER, AUTH_USER_INFO } from '../types'

const initialState = {
	loggedIn: false,
	userInfo: {}
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
		case AUTH_LOGIN_USER:
			return { 
				...state,
				loggedIn: true          
			}
		case AUTH_LOGOUT_USER:
			// this is also called in the reducers.js to reset the state completely
			return { 
				...initialState           
			}
		case AUTH_USER_INFO:
			return { 
				...state,
				userInfo: action.payload
			}
		default:
			return state
	}
}
