import { useEffect, useState } from 'react'

import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import Text from '@mui/material/Typography'

function Home({stats, loadStats}) {
   const [step, setStep] = useState('loading') // loading, error, display
   const [e, setE] = useState({})

   useEffect(() => {
      loadStats()
      .then(()=> {
         setStep('display')
      })
      .catch((e) => {
         setE(e)
         setStep('error') 
      })
   },[])

   return (
      <div style={{paddingTop:20}}>
         <div align='center' style={{padding:5, marginBottom:25}}>
            <Text variant='h5'>Stats</Text>
            <Divider/>
         </div>

         {(step === 'loading') ? (
            <div style={{paddingTop:20, paddingBottom:50, maxWidth:400, minHeight:300, margin:'0 auto'}}>
              Loading site stats...
              <LinearProgress color='inherit' />
            </div>
         ):( null )}

         {(step === 'error') ? (
            <div style={{paddingTop:20, paddingBottom:50, maxWidth:400, minHeight:300, margin:'0 auto'}}>
              <Text variant='h6'>{e.title}</Text>
              <Text>{e.message}</Text>
            </div>
         ):( null )}

         {(step === 'display') ? (
            (Object.keys(stats).length) ? (
               <div>
                  <Grid container justifyContent='center' alignItems='center' spacing={.5}>
                     <Grid item xs={6} sm={6} style={{textAlign:'right'}}>
                        <Text variant='body1'><b>Total Users:</b></Text>                     
                     </Grid> 
                     <Grid item xs={6} sm={6} style={{textAlign:'left'}}>
                        <Text variant='body2' style={{color:'gray'}}>{stats.totalUsersRegistered}</Text>                          
                     </Grid>                  
                     <Grid item  xs={12}><Divider/></Grid>
                     <Grid item xs={6} sm={6} style={{textAlign:'right'}}>
                        <Text variant='body1'><b>Total Businesses:</b></Text>                     
                     </Grid> 
                     <Grid item xs={6} sm={6} style={{textAlign:'left'}}>
                        <Text variant='body2' style={{color:'gray'}}>{stats.totalBusinesses}</Text>                          
                     </Grid>                  
                     <Grid item  xs={12}><Divider/></Grid>
                     <Grid item xs={6} sm={6} style={{textAlign:'right'}}>
                        <Text variant='body1'><b>Total Requests:</b></Text>                     
                     </Grid> 
                     <Grid item xs={6} sm={6} style={{textAlign:'left'}}>
                        <Text variant='body2' style={{color:'gray'}}>{stats.totalRequests}</Text>                          
                     </Grid>                  
                     <Grid item  xs={12}><Divider/></Grid>
                     <Grid item xs={6} sm={6} style={{textAlign:'right'}}>
                        <Text variant='body1'><b>Total Responses:</b></Text>                     
                     </Grid> 
                     <Grid item xs={6} sm={6} style={{textAlign:'left'}}>
                        <Text variant='body2' style={{color:'gray'}}>{stats.totalResponses}</Text>                          
                     </Grid>                  
                     <Grid item  xs={12}><Divider/></Grid>
                  </Grid>  
               </div>
            ) : (
               <div align='center'>No Stats Available</div>  
            )
         ):( null )}
      </div>
   )
}
export default Home
