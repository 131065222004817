import { Link, useNavigate } from 'react-router-dom'

import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'

function Archives({archives, handleOpenImage}) {
   const navigate = useNavigate()

   const handleViewResponses = (id) => {
      navigate('/feed/responses?id='+id)
   } 

   return (
      <div>
         <Paper elevation={6} style={{border:'2px solid black', maxWidth:600, margin:'0 auto', padding:20 }} >
            <Text variant='h6'>Archives</Text>
            <Text variant='body2'>This is a list of your Archived Requests.</Text>
            <div style={{textAlign:'right', paddingTop:10}}>
               <Button variant='contained' size='small' component={Link} to='/feed/requests'>View Open Request</Button> 
            </div>
         </Paper> 

         {(archives.length) ? (
            <List style={{border:'2px solid black', maxWidth:600, maxWidth:600, margin:'0px auto', marginTop:25, marginBottom:80, backgroundColor:'#fff', padding:5}}>
               {archives.map((rec, key) => {
                  return (
                     <div key={key}>
                        <ListItem alignItems='flex-start'>                         
                           <div style={{marginLeft:10, paddingTop:5, width:'100%'}}>
                              <Text variant='body2'>{rec.year + ' ' + rec.make + ' ' + rec.model}</Text>
                              <Text variant='body1' style={{color:'red'}}><b>{rec.requestId}</b></Text>
                              <Text variant='body2'><b>Condition:</b> {(rec.condition === 'Both') ? 'New & Used' : rec.condition}</Text>
                              <Text variant='body2'><b>Part:</b> {rec.part}</Text>
                              <Text variant='body2'><b>Response Count:</b> {rec.responsesCount || 0}</Text>
                              <div align='right'>
                                 <Button size='small' onClick={() => {handleViewResponses(rec.requestId)}} variant='outlined'>View Responses</Button>
                              </div>
                           </div>
                        </ListItem>
                        <Divider sx={{height:5}}/>  
                     </div>
                  )
               })}
            </List>
         ) : (
            <Paper elevation={6} style={{border:'2px solid black', maxWidth:600, margin:'0 auto', padding:20, marginTop:20, marginBottom:100 }} >
               <div align='center'>
                  <Text variant='h6' style={{color:'#ff0000'}}>Archived Requests Would Be Listed Here</Text>
               </div>
            </Paper>
         )}
      </div>
   )
}
export default Archives
