import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import IconClose from '@mui/icons-material/Close'
import Text from '@mui/material/Typography'

function ProfilePopup({ openWindow, setOpenWindow, profileId }){
   return (
      <Dialog fullWidth scroll='body' open={openWindow} onClose={() => setOpenWindow(false) } style={{margin:'0 auto'}}>
         <DialogTitle>
            <Text style={{color:'#999'}}>Business Profile</Text>
            <IconButton onClick={() => setOpenWindow(false) } sx={{ position: 'absolute', right: 8, top: 8, color: '#999'}}>
               <IconClose />
            </IconButton>
         </DialogTitle >
         <DialogContent style={{padding:10}} dividers>
            {profileId}
         </DialogContent>
      </Dialog>
   )
}
export default ProfilePopup
