import { useState } from 'react'
import ImageSelector from '../../components/ImageSelector'

import Alert from '@mui/material/Alert'
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import Stack from '@mui/material/Stack'
import Text from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

const vehicleMakes = [
	'Acura', 'Audi', 'Austin', 'Baic', 'Buick', 'Cadillac', 'Chevrolet', 'Chrysler', 'Daewoo', 'Daihatsu',  
	'Datsun', 'Dodge', 'Fiat', 'Ford', 'Often', 'GMC', 'Hinojosa', 'Honda', 'Hyundai', 'Isuzu', 'JAC', 'Jaguar', 
	'Jeep', 'Kia', 'Lada', 'Land Rover', 'Lexus', 'Lincoln', 'Mazda', 'Mercedes Benz', 'Mercury', 'MG', 'Mini', 
	'Mitsubishi', 'Nissan', 'Oldsmobile', 'Opel', 'Peugeot', 'Plymouth', 'Pontiac', 'Porsche', 'Renault', 'Rover', 
	'Saab', 'Seat', 'Skoda', 'Subaru', 'Suzuki', 'Tata', 'Sang Yong', 'Tesla', 'Toyota', 'Vauxhall', 'Volvo', 'Volkswagen', 'Yugo'
] 

function MakeRequest({handleAction, newRequest}) {
	const [step, setStep] = useState('request') // processing, error, request
	const [message, setMessage] = useState('')
   const [condition, setCondition] = useState('Both')
   const [make, setMake] = useState('')
   const [model, setModel] = useState('')
   const [year, setYear] = useState('')
   const [part, setPart] = useState('')

  // [START]: ImageSelector Variables & Function :::::::::
    const [selectedImage, setSelectedImage] = useState('/images/defaultBusinessImage.jpg') // ImageSelector variable
    const images = [ '/images/defaultBusinessImage.jpg' ] // ImageSelector variable
    const handleSelectImage = (src) => {
      setSelectedImage(src)
    }
  // [END]: ImageSelector Variables & Function ::::::::

   const handleChange = (f,v) => {
      f(v)
      setMessage('')
   }
   const handleTryAgain = () => {
      setMessage('') 
      setStep('request')
   } 
   const handleSubmitRequest = (e) => {
      e.preventDefault()
      if (make === '' || model === '' || part === '') {
         return setMessage('Sorry, the vehicle Make, Model and the Part you are looking for are required.')
      }
      let request = {
         condition:condition,
         make:make,
         model:model,
         year:year,
         part:part,
         image:selectedImage
      }
      setStep('processing')
      newRequest(request)
      .then((requestId) => {
         handleAction('response', requestId)
      })
      .catch((err) => {
         setMessage(err.message)
         setStep('error')
      })
   }
   return (
   	<div>
         {(step === 'processing') ? (
            <div style={{paddingTop:50, paddingBottom:50, maxWidth:400, margin:'0 auto'}}>
		         Processing your request...
		         <LinearProgress color='inherit' />
		      </div>
         ):( null )}            

         {(step === 'error') ? (
            <div style={{paddingTop:50, paddingBottom:50, maxWidth:400, margin:'0 auto'}}>
		         <Alert severity='error'>
		            {message || 'Sorry, an error occured, please try again.'}
		         </Alert>
		         <div style={{ marginTop:20 }}><Button onClick={() => { handleTryAgain() }} variant='outlined'>try again</Button></div>
		      </div>
         ):( null )} 

	      {(step === 'request') ? (
		      <div style={{paddingTop:0, paddingBottom:20, maxWidth:400, margin:'0 auto'}}>
		         <div style={{padding:5, marginBottom:20, borderBottom:'2px solid #ff0000'}}>
		            <Text variant='body2' color='text.secondary'>
		            Let's see how fast we can find what you're looking for. Enter some details below and let's get started.
		            </Text>
		         </div>

		         <form onSubmit={handleSubmitRequest}>
						<Grid container justifyContent='center' alignItems="center" spacing={1}>
						   <Grid item xs={4} style={{textAlign:'right'}}>
						      <Text color='text.secondary'>Condition</Text> 
						   </Grid>
						   <Grid item xs={8} style={{textAlign:'left'}}>       
	                     <ToggleButtonGroup color='primary' exclusive size='small'
	                        value={condition} onChange={(e) => {handleChange(setCondition, e.target.value) }}
	                     >
								   <ToggleButton value='New'>New</ToggleButton>
								   <ToggleButton value='Used'>Used</ToggleButton>
								   <ToggleButton value='Both'>Both</ToggleButton>
								</ToggleButtonGroup>             
						   </Grid> 
                     <Grid item xs={4} style={{textAlign:'right'}}>
						      <Text color='text.secondary'>Make</Text> 
						   </Grid>
						   <Grid item xs={8} style={{textAlign:'left'}}>       
	                     <Autocomplete
						         freeSolo
						          onChange={(e, newValue) => {handleChange(setMake, newValue) }}
						         selectOnFocus
						         options={vehicleMakes}
						         renderInput={(params) => 
						           <TextField {...params} fullWidth size='small' label='Vehicle Make' type='text' margin='none' 
                                value={make} onChange={(e) => {handleChange(setMake, e.target.value) }}
						           />
						         }
						      />           
						   </Grid>
						   <Grid item xs={4} style={{textAlign:'right'}}>
						      <Text color='text.secondary'>Model</Text> 
						   </Grid>
						   <Grid item xs={8} style={{textAlign:'left'}}>       
	                     <TextField fullWidth size='small' label='Vehicle Model' type='text' margin='none'
		                     value={model} onChange={(e) => {handleChange(setModel, e.target.value) }}
		                  />           
						   </Grid> 
						   <Grid item xs={4} style={{textAlign:'right'}}>
						      <Text color='text.secondary'>VIN/Year</Text> 
						   </Grid>
						   <Grid item xs={8} style={{textAlign:'left'}}>       
	                     <TextField fullWidth size='small' label='Vehicle VIN or Year if known' type='text' margin='none'
		                     value={year} onChange={(e) => {handleChange(setYear, e.target.value) }}
		                  />           
						   </Grid> 
						   <Grid item xs={4} style={{textAlign:'right'}}>
						      <Text color='text.secondary'>Part <i>(Description)</i></Text> 
						   </Grid>
						   <Grid item xs={8} style={{textAlign:'left'}}>       
	                     <TextField fullWidth size='small' label='What are you looking for?' type='text' margin='none'
		                     multiline rows={3}
		                     value={part} onChange={(e) => {handleChange(setPart, e.target.value) }}
		                  />           
						   </Grid> 

		               <Grid item xs={4} style={{textAlign:'right'}}>
								<Text color='text.secondary'>Add Image <i>(Optional)</i></Text> 
							</Grid>
							<Grid item xs={8} style={{textAlign:'left'}}>  
			               <div style={{marginTop:10, marginBottom:10}}>
			                  {/** ImageSelector Starts Here */}
			                  <div align='center' style={{ margin:'0 auto'}}>
			                     <ImageSelector 
			                        imgBorder='1px solid #ccc'
			                        imgBorderRadius={10}
			                        imgPadding={5}
			                        imgRatio='16:9' 
			                        images={images}
			                        currentImage={selectedImage}
			                        selectImage={handleSelectImage}
			                        hideSelector={true}
			                     />
			                  </div>
			                  {/** ImageSelector Ends Here */} 
			               </div>
		               </Grid>

						   <Grid item xs={12} style={{textAlign:'right'}}>
						      <Text style={{color:'red'}} component='p' align='center'>
			                  <i>{ message }</i>
			               </Text>
						   </Grid>
						   <Grid item xs={12} style={{textAlign:'right'}}>
						      <Stack direction='row' spacing={2} justifyContent='flex-end' divider={<Divider orientation='vertical' flexItem />} >
			                  <Button onClick={() => handleAction('cancel')} color='primary' autoFocus>
			                     Cancel
			                  </Button>
			                  <Button variant='contained' color='primary' type='submit'>
			                     submit request
			                  </Button>
			               </Stack> 
						   </Grid>
						</Grid>         		            
		         </form>
		      </div>
	      ):( null )}
	   </div>
   )
}
export default MakeRequest
