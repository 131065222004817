import { useState } from 'react'
import { connect } from 'react-redux'
import { submitToVisitorMessages } from '../redux/app/actions'
import { chkEmail }  from '../includes/functions'

import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Container from '@mui/material/Container'
import GoogleMapReact from 'google-map-react'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import IconEmail from '@mui/icons-material/Email'
import IconMarker from '@mui/icons-material/Room'
import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Text from '@mui/material/Typography'
import TextField from '@mui/material/TextField'

const MapMarker = ({ text }) => <div style={{marginRight:20}}><IconMarker color='primary'/>{text}</div>

function ContactUs({submitToVisitorMessages}) {
   const [message, setMessage] = useState('')
   const [userName, setUserName] = useState('')
   const [userEmail, setUserEmail] = useState('')
   const [userMessage, setUserMessage] = useState('')
   const [step, setStep] = useState('default')

   const handleChange = (f,v) => {
      f(v)
      setMessage('')
   }

   const handleSubmitMessage = () => {
      if (userName === '' || userEmail === '' || userMessage === '') {
         return setMessage('Sorry, all fields are required.')
      }
      if (!chkEmail(userEmail)) {
         return setMessage('Sorry, please enter a valid email address.')
      }
      setStep('processing')
      submitToVisitorMessages(userName, userEmail, userMessage)
      .then(() => {
         setStep('submitted')
      })
      .catch((err) => {
         setMessage(err)
         setStep('default')
      }) 
   }

   return (
      <Container maxWidth='lg' style={{ padding: 10, marginTop:50, marginBottom:50 }}>
         <Grid container justifyContent='center' spacing={2}>
            <Grid item xs={12} sm={12} md={10} >
               <Paper elevation={6} style={{ border:'2px solid black', padding: 20, minHeight:700 }} >
                  <Grid container justifyContent='center' spacing={2}>
                     <Grid item xs={12} sm={4}>
                        <img style={{maxWidth: '100%'}} src='/images/main-logo.jpg'/> 
                     </Grid>
                     <Grid item xs={12} sm={8} > 
                        <Text variant='h4'>
                           <b>Lets Connect..</b>
                        </Text>
                        <Text variant='body1'> 
                           Got a question or concern, or just wanna give us 
                           some feedback?
                        </Text>

                        <Card variant='outlined' raised={false} style={{margin:'25px auto', maxWidth:400, padding:10 }}>
                           <Stack direction='row' spacing={2} style={{margin:'0 auto'}}>
                              <span>
                                 <a href='mailto:contact@TellTommi.com'>
                                    <IconButton color='primary'><IconEmail /></IconButton>
                                 </a>
                              </span>
                              <span>
                                 <Text variant='body1'>Send us an email</Text>
                                 <a href='mailto:contact@TellTommi.com'>contact@TellTommi.com</a>
                              </span>
                           </Stack>
                        </Card>

                        <div style={{padding:5}}>
                           <Text variant='h6'>Send us a message</Text>
                        </div>  

                        {(step === 'default') ? (                           
                           <Stack spacing={1} style={{margin:'0 auto', maxWidth:450}}>
                              <TextField fullWidth size='small' variant='outlined'
                                 onChange={(e) => {handleChange(setUserName, e.target.value) }} 
                                 label='Full Name' 
                              />
                              <TextField fullWidth size='small' variant='outlined' 
                                 onChange={(e) => {handleChange(setUserEmail, e.target.value) }}
                                 label='Email Address'
                              />
                              <TextField fullWidth variant='outlined'size='small' 
                                 onChange={(e) => {handleChange(setUserMessage, e.target.value) }}
                                 label='Message' 
                                 multiline
                                 rows={4}
                              />
                              <Text style={{ color: 'red' }} component='p' align='center'>
                                 <i>{ message }</i>
                              </Text>
                              <Button onClick={handleSubmitMessage} fullWidth size='small'>Submit Message</Button>                            
                           </Stack>  
                        ):( null )}

                        {(step === 'processing') ? (
                           <div style={{maxWidth:500, margin:'0 auto', padding:20}}>
                              Processing your submission...
                              <LinearProgress color='inherit' />
                           </div>
                        ):( null )}

                        {(step === 'submitted') ? (
                           <div style={{maxWidth:500, margin:'0 auto', padding:20}}>
                              <Text variant='subtitle1'>
                                 Your message has been submitted, thanks for your feedback. 
                                 If your message requires a response, we will respond via email.
                              </Text> 
                           </div>
                        ):( null )}

                        <div style={{padding:5, marginTop:20}}>
                           <Text variant='h6'>Where we're located</Text>
                        </div> 

                        <div align='center' style={{border:'2px solid black', height: '300px', width: '100%', padding:1, backgroundColor:'#f1f1f1'}}>
                           <GoogleMapReact
                             bootstrapURLKeys={{ key: 'AIzaSyCShnSE8rsYBi9lwHlXrDUMDL1D0fiDlnU' }}
                             defaultCenter={{lat:10.66934, lng:-61.532582}}
                             defaultZoom={11}
                           >
                             <MapMarker
                               lat={10.66934}
                               lng={-61.532582}
                               text={'TellTommi.com'}
                             />
                           </GoogleMapReact>
                        </div>

                        <div style={{padding:10, textAlign:'center'}}>
                           <Text variant='subtitle1'><b>TellTommi.com</b></Text>
                           <Text variant='subtitle1'>
                              Port of Spain, Trinidad & Tobago<br/>
                              Phone: 1.(868).303.0204
                           </Text>
                        </div>
                     </Grid>
                  </Grid>
               </Paper>
            </Grid>
         </Grid>
      </Container>
   )
}
const mapDispatchToProps = {
  submitToVisitorMessages
}
export default connect(null, mapDispatchToProps)(ContactUs)
