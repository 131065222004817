export const selectorFilterFeed = (requests={}, responses={}, hidden=[]) => {
   let filtered = [] 
   let ids = []
   let uniqueIds = []
   let requestsId = []
   let hiddenIds = [...new Set(hidden)]
   
   // 1. get all ids of the requests, business has responded to
      Object.keys(responses).forEach(function (i) {
         ids.push(responses[i].requestId)
      })

   // 2. make sure requests ids are unique  
      uniqueIds = [...new Set(ids)]

   // 3. cycle through all requests and remove hidden and already responded to requests 
      if (requests && Object.keys(requests).length) {
         Object.keys(requests).forEach(function (i) {
            // save all unique requests ids
            requestsId.push(i)
            // remove hidden and responded to, requests from the list
            if(requests[i].status === 'active' && !uniqueIds.includes(i) && !hiddenIds.includes(i)) {
               filtered.push(requests[i])
            }
         })
      } 
   // 4. remove hidden ids that are not listed in requests ids
      hiddenIds = hiddenIds.filter( (el) => requestsId.includes(el) )    

   // 5. update hiddenIds state
      localStorage.setItem('hiddenRequests', JSON.stringify(hiddenIds))

   // 6. order results by newest first
      filtered.sort((a,b) => b.dateTimeLastUpdated - a.dateTimeLastUpdated) // b - a for reverse sort

   // 7. return filtered
   return filtered
}

export const selectorFilterResponses = (responses) => {
   let filtered = [] 
   // NEED TO SORT ::::::::::::::::
   // order responses by newest first
   filtered = Object.values(responses).sort((a,b) => b.requestDateTimeLastUpdated - a.requestDateTimeLastUpdated) // b - a for reverse sort
   return filtered
}