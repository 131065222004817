import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

import theme from './styles/theme-1'
import './styles/custom.css'

import App from './routes/App'
import store from './redux/store'

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
   <BrowserRouter>
      <Provider store={store}>
         <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
         </ThemeProvider>
      </Provider>
   </BrowserRouter>
)
