import { useState } from 'react' 

import { chkEmail, chkPassword }  from '../../../includes/functions'
import ServiceAndPolicies from '../Legal/index'

import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Text from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import LinearProgress from '@mui/material/LinearProgress'
import TextField from '@mui/material/TextField'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

function LoginStatus({setOpenLoginWindow, logIn, shortCodeLogin, shortCodeConfirmation}){
   const [loginStep, setLoginStep] = useState('login') // intro, processing, error, login, emailLogin, emailLoginConfirm 
   const [resetFormStep, setResetFormStep] = useState('login') // login, emailLogin, emailLoginConfirm
   const [message, setMessage] = useState('')
   const [checked, setChecked] = useState(false)
   const [email, setEmail] = useState('')
   const [password, setPassword] = useState('')
   const [showPassword, setShowPassword] = useState(false) 
   const [confirmationCode, setConfirmationCode] = useState('')
   
   const handleChange = (f,v) => {
      f(v)
      setMessage('')
   }
   const handleCheckboxToggle = () => {
      setChecked(!checked)
      setMessage('')
   }
   const handleResetForm = () => {
      setMessage('') 
      setChecked(false)
      setLoginStep(resetFormStep)
   } 
   const handleLoginSubmit = (e) => {
      e.preventDefault()
      if (!chkEmail(email)) {
         return setMessage('Sorry, please enter a valid email address.')
      }
      if (!chkPassword(password)) {
         return setMessage('Sorry, your password is invalid.')
      }
      if (!checked) {
         return setMessage('Sorry, please agree with the Terms of Service')
      }
      setLoginStep('processing')
      logIn(email, password)
      .catch((err) => {
         setMessage(err)
         setResetFormStep('login')
         setLoginStep('error')
      })
   }
   const handleShortCodeLogin = (e) => {
      e.preventDefault()
      if (!chkEmail(email)) {
         return setMessage('Sorry, please enter a valid email address.')
      }
      if (!checked) {
         return setMessage('Sorry, please agree with the Terms of Service')
      }
      setLoginStep('processing') 
      shortCodeLogin(email)
      .then(() => {
         setLoginStep('emailLoginConfirm')
      })
      .catch((err) => {
         setMessage(err)
         setResetFormStep('emailLogin')
         setLoginStep('error')
      }) 
   }
   const handleShortCodeLoginConfirm = (e) => {
      e.preventDefault()
      // confirm that it is a 4-digit code here
      setLoginStep('processing')
      shortCodeConfirmation(email, confirmationCode)
      .catch((err) => {
         setMessage(err)
         setResetFormStep('emailLoginConfirm')
         setLoginStep('error')
      }) 
   }

   return (
      <div>
         {(loginStep === 'processing') ? (
            <div style={{paddingTop:50, paddingBottom:50, maxWidth:400, margin:'0 auto'}}>
              Processing your request...
              <LinearProgress color='inherit' />
            </div>
         ):( null )}

         {(loginStep === 'error') ? (
            <div style={{paddingTop:50, paddingBottom:50, maxWidth:400, margin:'0 auto'}}>
              <Alert severity='error'>
                  <AlertTitle>Login Error</AlertTitle>
                  {message || 'Sorry, an error occured, please try again.'}
               </Alert>
               <div style={{ marginTop:20 }}><Button onClick={() => { handleResetForm() }} variant='outlined' color='secondary'>try again</Button></div>
            </div>
         ):( null )}

         {(loginStep === 'login') ? (
            <div style={{paddingTop:20, paddingBottom:20, maxWidth:400, margin:'0 auto'}}>
               <Text variant='h6'>PASSWORD LOGIN</Text>
               <Text color='text.secondary'> 
                  Login to your TellTommi account. If you are new, <Button size='small' onClick={() => { setLoginStep('emailRegister') }} variant='text' style={{padding:0}}>click here</Button> to sign up. 
               </Text>
               <form onSubmit={handleLoginSubmit}>
                  <Stack spacing={1}>
                     <TextField fullWidth size='small' label='Email' placeholder='Enter your email address'
                        value={email} onChange={(e) => {handleChange(setEmail, e.target.value) }} type='email' margin='normal'
                     />
                     <TextField fullWidth size='small' label='Password' placeholder='Enter your password'
                        type={showPassword ? 'text' : 'password'} margin='normal'
                        onChange={(e) => {handleChange(setPassword, e.target.value) }}
                        InputProps={{
                           endAdornment: (
                            <InputAdornment position='end'>
                            <IconButton  onClick={() => { setShowPassword(!showPassword) }}>
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                            </InputAdornment>
                           )
                        }}
                     />
                     <Stack direction='row' justifyContent='space-between'>
                        <div><Button size='small' onClick={() => { setLoginStep('emailRegister') }} variant='text' style={{padding:0}}>Register New Account</Button></div>
                        <div><Button size='small' onClick={() => { setLoginStep('emailLogin') }} variant='text' style={{padding:0}}>Forgot Password?</Button></div>
                     </Stack>
                     <div>
                        <Checkbox checked={checked} onChange={handleCheckboxToggle} color='primary' />
                        <ServiceAndPolicies tab={0} text={0} />
                     </div>
                     <Text style={{ marginTop: 10, color: 'red', height: 15 }} component='p' align='center'>
                        <i>{ message }</i>
                     </Text>
                     <Stack direction='row' spacing={2} justifyContent='flex-end' divider={<Divider orientation='vertical' flexItem />} >
                        <Button onClick={() => setOpenLoginWindow(false)} color='primary' autoFocus>
                           Close
                        </Button>
                        <Button variant='contained' color='primary' type='submit'>
                           login
                        </Button>
                     </Stack>                
                  </Stack>
               </form>
            </div>   
         ):( null )}

         {(loginStep === 'emailLogin') ? (
            <div style={{paddingTop:20, paddingBottom:20, maxWidth:400, margin:'0 auto'}}>
               <Text variant='h6'>SHORT CODE LOGIN</Text>
               <Text color='text.secondary'> 
                  Submit your email address to recieve a short code to access your account, 
                  or <Button onClick={() => { setLoginStep('login') }} variant='text' style={{padding:0}}>click here</Button> to login via Email & Password.
               </Text>
               <form onSubmit={handleShortCodeLogin}>
                  <Stack spacing={2}>
                     <div>
                        <TextField fullWidth size='small' label='Email' placeholder='Enter your email address'
                           value={email} onChange={(e) => {handleChange(setEmail, e.target.value) }} type='email' margin='normal'
                        />
                        <div align='right'><Button size='small' onClick={() => { setLoginStep('emailRegister') }} variant='text' style={{padding:0}}>Register New Account</Button></div>
                     </div>
                     <div>
                        <Checkbox checked={checked} onChange={handleCheckboxToggle} color='primary' />
                        <ServiceAndPolicies tab={0} text={0} />
                     </div>
                     <Text style={{ marginTop: 10, color: 'red', height: 15 }} component='p' align='center'>
                        <i>{ message }</i>
                     </Text>
                     <Button variant='contained' color='primary' type='submit'>
                        submit
                     </Button> 
                  </Stack>
               </form>
            </div>
         ):( null )}

         {(loginStep === 'emailLoginConfirm') ? (
            <div style={{paddingTop:50, paddingBottom:50, maxWidth:400, margin:'0 auto'}}>
               <Text variant='h5'>CONFIRM</Text>
               <Text color='text.secondary'> 
                  Please check your email ({email}) for the confirmation code that was sent to you and enter it below.
                  If you would like to use a different email address, <Button onClick={() => { setLoginStep('emailLogin') }} variant='text' style={{padding:0}}>click here</Button>.
               </Text>
               <form onSubmit={handleShortCodeLoginConfirm}>
                  <Stack spacing={2}>
                     <TextField fullWidth size='small' label='Confirmation Code' placeholder='Enter your 4-digit confirmation code'
                        onChange={(e) => {handleChange(setConfirmationCode, e.target.value) }} type='number' margin='normal'
                     />
                     <Text style={{ marginTop: 10, color: 'red', height: 15 }} component='p' align='center'>
                        <i>{ message }</i>
                     </Text>
                     <Button variant='contained' color='secondary' type='submit'>
                        confirm account
                     </Button> 
                  </Stack>
               </form>
            </div>
         ):( null )}

         {(loginStep === 'emailRegister') ? (
            <div style={{paddingTop:20, paddingBottom:20, maxWidth:400, margin:'0 auto'}}>
               <Text variant='h6'>REGISTER NEW ACCOUNT</Text>
               <Text color='text.secondary'> 
                  Submit your email address to recieve a short code to create your account, 
                  or <Button onClick={() => { setLoginStep('login') }} variant='text' style={{padding:0}}>click here</Button> to login via Email & Password.
               </Text>
               <form onSubmit={handleShortCodeLogin}>
                  <Stack spacing={2}>
                     <TextField fullWidth size='small' label='Email' placeholder='Enter your email address'
                        value={email} onChange={(e) => {handleChange(setEmail, e.target.value) }} type='email' margin='normal'
                     />
                     <div>
                        <Checkbox checked={checked} onChange={handleCheckboxToggle} color='primary' />
                        <ServiceAndPolicies tab={0} text={0} />
                     </div>
                     <Text style={{ marginTop: 10, color: 'red', height: 15 }} component='p' align='center'>
                        <i>{ message }</i>
                     </Text>
                     <Button variant='contained' color='primary' type='submit'>
                        submit
                     </Button> 
                  </Stack>
               </form>
            </div>
         ):( null )}
      </div>
   )
}
export default LoginStatus
