import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'

function AboutUs() {
   return (
      <Container maxWidth='lg' style={{ padding: 10, marginTop:50, marginBottom:50 }}>
         <Grid container justifyContent='center' spacing={2}>
            <Grid item xs={12} sm={12} md={10} >
               <Paper elevation={6} style={{ border:'2px solid black', padding: 20, minHeight:700 }} >

                     <Grid container justifyContent='center' spacing={2}>
                        <Grid item xs={12} sm={4}>
                           <img style={{maxWidth: '100%'}} alt='TellTommi' src='/images/main-logo.jpg'/> 
                        </Grid>
                        <Grid item xs={12} sm={8} > 
                           <Text variant='h4'>
                              <b>About Us</b>
                           </Text>
                           <Text variant='body1'> 
                              Having personally experienced the inability to source auto parts, the idea for 
                              <b>Tell Tommi</b> was born. <b>Tell Tommi</b> makes the automotive parts search 
                              simpler, faster and more effective for both the customer and the vendor.
                           </Text>
                           <Text mt={2} variant='body1'>
                              A free service for the customer, one search request goes to hundreds of vendors 
                              at the same time. The vendor has the ability to check their stock in real time 
                              and respond to the request, and in many cases, the customer has multiple options 
                              to choose where to purchase (distance, price, etc)
                           </Text> 
                           <Text mt={2} variant='body1'>   
                              At the same time, the vendor gets hundreds, if not thousands of virtual customers 
                              per day, which in many cases, would not usually have come to the vendor. Its a win win 
                              for both customers and vendors.
                           </Text>
                    
                           <Text mt={4} variant='h5'>
                              <b>Customer Benefits</b>
                           </Text>  
                           <Text variant='body1'>
                              <b>Tell Tommi</b> is a free service for the customer. After creating your account, you 
                              can make your parts request. This request then goes to hundreds of vendors with one 
                              click. This saves the need to spend hours on the phone or on the road searching for 
                              your parts.
                           </Text>  
                           <Text mt={2} variant='body1'>   
                              Your results can, and in many cases will come from vendors you never knew existed.
                              From your request results, you can choose where to purchase from (eg) by distance, price, etc
                           </Text> 
                              <ul>
                                 <li>You can make multiple requests and get responses to each request individually.</li> 
                                 <li>One search request can reach hundreds of vendors</li>
                                 <li>It's a free service</li>
                                 <li>You can go about your day while Tell Tommi works for you</li>
                                 <li>Saves you time, gas and money from having to physically search for parts</li>   
                                 <li>You can post your requests 24  hours a day</li>
                                 <li>Searches for your parts in places you may not even know exists</li>
                              </ul> 
                           
                           <Text mt={4} variant='h5'>
                              <b>Vendor Benefits</b>
                           </Text>  
                           <Text variant='body1'>
                              <b>Tell Tommi</b> increases sales to the vendor by bringing hundreds, if not thousands, 
                              of customers' parts requests, which would not normally be accessible by the vendor.
                           </Text> 
                           <Text mt={2} variant='body1'> 
                              The vendor has the option to reply at their convenience, however, the faster you reply, 
                              the better chance you have to secure the sale. Outside of business hours, you can still 
                              reply to customers' requests and can have sales waiting for you at the start of the next 
                              business day.
                           </Text> 
                           <Text mt={2} variant='body1'>     
                              Even if you don't have what some customers are asking for, you can take note of what's 
                              being requested and adjust your stock accordingly.
                           </Text> 
                           <Text mt={2} variant='body1'>    
                              It's a win win scenario!!
                           </Text>
                           <Text mt={2} variant='body1'>    
                              Key benefits summary:
                           </Text>
                           <ul>
                              <li>Knowing what parts the public is asking for</li>
                              <li>Bringing customers to you virtually 24 hours a day</li>
                              <li>Only costs about $10 per day</li>
                           </ul>
                        </Grid>
                     </Grid>
               </Paper>
            </Grid>
         </Grid>
      </Container>
   )
}
export default AboutUs
