import { useState } from 'react' 
import format from 'date-fns/format'
import ReplyPopup from '../components/ReplyPopup/_Container'

import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Grid from '@mui/material/Grid'
import Text from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

function Responses({ businessId, businessResponses, handleNewResponse, handleOpenImage }) {
   const conditionColor = {'New':'#40be25', 'Used':'#D8B522', 'Both':'#1cc7de'}
   const conditionText = {'New':'New Only', 'Used':'Used Only', 'Both':'New or Used'}
   const [openReplyWindow, setOpenReplyWindow] = useState(false)
   const [replyType, setReplyType] = useState('')
   const [requestId, setRequestId] = useState('')
   const [responseId, setResponseId] = useState('')

   const handleResponse = (id) => {
      handleNewResponse(id)
   }
   const handleReply = (requestId, responseId) => {
      let replyType = 'Reply To Request'
      setReplyType(replyType)
      setRequestId(requestId)
      setResponseId(responseId)
      setOpenReplyWindow(true)
   }
   return (
      <div>
         {(openReplyWindow) ? (
            <ReplyPopup openWindow={openReplyWindow} setOpenWindow={setOpenReplyWindow} replyType={replyType} businessId={businessId} requestId={requestId} responseId={responseId}/>
         ):( null )} 
         {(businessResponses.length) ? (
            <div>
               {businessResponses.map((rec, key) => {
                  let allowReply = false
                  return (
                     <Card key={key} elevation={0} style={{border:'2px solid gray', maxWidth:600, margin:'0 auto', marginTop:25, borderBottom:'4px solid #ff0099'}}>
                        <CardContent>
                           <Grid container justifyContent='center' alignItems='center' spacing={0}>
                              <Grid item xs={12} sm={6} style={{textAlign:'center'}}>
                                 <Grid container justifyContent='center' alignItems='center' spacing={0.5}>
                                    <Grid item xs={4} style={{textAlign:'right'}}>
                                       <Text variant='body2' color='text.secondary'>VIN/Year:</Text> 
                                    </Grid>
                                    <Grid item xs={8} style={{textAlign:'left'}}>       
                                       <Text variant='body2'>{rec.requestYear}</Text>           
                                    </Grid> 
                                    <Grid item xs={4} style={{textAlign:'right'}}>
                                       <Text variant='body2' color='text.secondary'>Make:</Text> 
                                    </Grid>
                                    <Grid item xs={8} style={{textAlign:'left'}}>       
                                       <Text variant='body2'>{rec.requestMake}</Text>           
                                    </Grid> 
                                    <Grid item xs={4} style={{textAlign:'right'}}>
                                       <Text variant='body2' color='text.secondary'>Model:</Text> 
                                    </Grid>
                                    <Grid item xs={8} style={{textAlign:'left'}}>       
                                       <Text variant='body2'>{rec.requestModel}</Text>           
                                    </Grid> 
                                 </Grid>
                              </Grid>
                              <Grid item xs={12} sm={6} style={{textAlign:'center'}}>
                                 <Grid container justifyContent='center' alignItems='center' spacing={0.5}>
                                    <Grid item xs={4} style={{textAlign:'right'}}>
                                       <Text variant='body2' color='text.secondary'>Date/Time:</Text> 
                                    </Grid>
                                    <Grid item xs={8} style={{textAlign:'left'}}>       
                                       <Text variant='body2'><i>{format(new Date(rec.requestDateTime), 'eee do MMM y - h:mm a')}</i></Text>            
                                    </Grid>
                                    <Grid item xs={4} style={{textAlign:'right'}}>
                                       <Text variant='body2' color='text.secondary'>Request ID:</Text> 
                                    </Grid>
                                    <Grid item xs={8} style={{textAlign:'left'}}>       
                                       <Text variant='body2'><i>{rec.requestId}</i></Text>            
                                    </Grid>
                                    <Grid item xs={4} style={{textAlign:'right'}}>
                                       <Text variant='body2' color='text.secondary'>Condition:</Text> 
                                    </Grid>
                                    <Grid item xs={8} style={{textAlign:'left'}}>       
                                       <Text variant='body2' component='span' style={{paddingLeft:5, paddingRight:5, borderRadius:5, backgroundColor:conditionColor[rec.requestCondition], color:'#fff'}}>{conditionText[rec.requestCondition]}</Text>            
                                    </Grid> 
                                 </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                 <div style={{display:'flex', margin:5, padding:5, borderTop:'1px solid gray', paddingBottom:25}}>
                                    {(rec.requestImage !=='') ? (
                                       <img onClick={() => {handleOpenImage(rec.requestImage)}} src={rec.requestImage} alt='TellTommi' style={{float:'left', cursor: 'pointer', maxWidth:100, margin:5, marginRight:10, padding:2, border:'1px solid gray'}}/>  
                                    ):(null)}
                                    <div>
                                       <Text variant='body2' color='text.secondary'><b><i>Part Description</i></b></Text>
                                       <Text variant='body2'>{rec.requestPart}</Text> 
                                    </div>
                                 </div>   
                              </Grid> 

                              {(rec.responseStatus === 'pending') ? ( 
                                 <>
                                    <Grid item xs={12} style={{textAlign:'center', padding:2, margin:5}}>
                                       <Alert severity='warning'>This request is pending your response</Alert>
                                    </Grid>
                                    <Grid item xs={12} style={{textAlign:'center', padding:2, margin:5}}>
                                       <Button onClick={() => { handleResponse(rec.requestId) }} variant='contained' color='secondary' size='small'>Respond to this Request</Button>
                                    </Grid> 
                                 </>
                              ):(
                                 <Grid item xs={12} style={{padding:5, margin:5}}>
                                    {rec.responses.map((r, key) => {
                                       if(r.type === 'response'){
                                             allowReply = false
                                             return (
                                                <div key={key} style={{ margin:5, padding:5, borderTop:'1px solid gray'}}> 
                                                   <Text variant='subtitle1' style={{color:'gray'}}><b>Response</b></Text>
                                                   <div style={{display:'flex', marginBottom:15}}>
                                                      {(r.image !=='') ? (
                                                         <img onClick={() => {handleOpenImage(r.image)}} src={r.image} alt='TellTommi' style={{float:'left', cursor: 'pointer', maxWidth:100, margin:5, padding:2, border:'1px solid gray'}}/>  
                                                      ):(null)}
                                                      <div>
                                                         <Text color='text.secondary' variant='caption'><i>{format(new Date(r.timestamp), 'eee do MMM y - h:mm a')}</i></Text> 
                                                         <Text variant='body1'>{r.message}</Text>
                                                      </div>
                                                   </div>
                                                   <div style={{textAlign:'right', marginTop:20}}>
                                                      <Divider/>
                                                      <Text color='text.secondary' variant='body1'>
                                                        <i>Response by: {r.userName}</i>
                                                      </Text>
                                                   </div>
                                                </div>
                                             ) 
                                       } else {
                                             allowReply = true
                                             return (
                                                <div key={key} style={{ margin:5, padding:5, borderTop:'1px solid gray'}}> 
                                                   <Text variant='subtitle1' style={{color:'gray'}}><b>Request</b></Text>
                                                   <div style={{display:'flex', marginBottom:15}}>
                                                      {(r.image !=='') ? (
                                                         <img onClick={() => {handleOpenImage(r.image)}} src={r.image} alt='TellTommi' style={{float:'left', cursor: 'pointer', maxWidth:100, margin:5, padding:2, border:'1px solid gray'}}/>  
                                                      ):(null)}
                                                      <div>
                                                         <Text color='text.secondary' variant='caption'><i>{format(new Date(r.timestamp), 'eee do MMM y - h:mm a')}</i></Text> 
                                                         <Text variant='body1'>{r.message}</Text>
                                                      </div>
                                                   </div>
                                                </div>
                                             ) 
                                       }
                                    })}
                                 </Grid>                                  
                              )}
                           </Grid>   
                        </CardContent>
                        {(allowReply) ? (
                           <CardActions>
                              <div style={{ width:'100%', textAlign:'right', padding:5, borderTop:'1px solid gray'}}>
                                 <Button onClick={() => { handleReply(rec.requestId, rec.responseId) }} variant='contained' color='secondary' size='small'>Respond to this Request</Button>
                              </div>
                           </CardActions>
                        ):( null )} 
                     </Card>
                  )
               })}
            </div>
         ) : (
            <div style={{marginTop:20}}>
               <div align='center' style={{margin:0, marginBottom:100, padding:50, backgroundColor:'#f1f1f1'}}>
                  <Text variant='body1' color='textSecondary'>Responses Would Be Listed Here</Text>
               </div>
            </div> 
         )} 
      </div>
   )
}
export default Responses
