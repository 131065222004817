import { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { emailLogInConfirm } from '../redux/auth/actions'

import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'

function ConfirmEmail({emailLogInConfirm}) {
   const [loginError, setLoginError] = useState(false)
   const [searchParams] = useSearchParams()
   const nextUrl = searchParams.get('next') || '/feed/requests' 
   const requestId = searchParams.get('requestId') || 0 
   const navigate = useNavigate()
   
   useEffect(() => {
      let userEmail = (window.localStorage.getItem('emailForSignIn')) ? window.localStorage.getItem('emailForSignIn') : null
      if (userEmail) {
         logInUser(userEmail)
      } else {
         setLoginError(true)
      }
   }, [])

   const logInUser = (emailAddress) => {
      emailLogInConfirm(emailAddress, window.location.href, requestId)
      .then(() => {
         navigate(nextUrl)
      })
      .catch((err) => {
         setLoginError(true)
      })    
   }   

   return (
    <Container maxWidth='xl' style={{ padding: 10, marginBottom: 150, minHeight:700, marginTop:80 }}>
      <Grid container justifyContent='center' spacing={2}>
         <Grid item xs={12} sm={5}>
            {(loginError) ? (
               <Paper style={{ padding: 20 }} >
                  <Text variant='h6' gutterBottom>Configuration Error</Text>
                  <Text variant='body1'>
                     Sorry, there was an error configuring your account.
                     Please return to our homepage and try again.
                  </Text>                   
                  <Button onClick={() => { navigate('/home') }} size='small' variant='outlined' color='primary' style={{marginTop:20}}>
                     go to homepage
                  </Button>    
               </Paper>
            ):(
               <Paper style={{ padding: 20 }} >
                  <Text variant='h6' gutterBottom>Configuring Account</Text>
                  <Text variant='body1' component='p'>Please give us a minute to configure your account.</Text>
                  <LinearProgress style={{margin:10}}/>
               </Paper>
            )}
         </Grid>
      </Grid>
    </Container>
   )
}
const mapDispatchToProps = {
  emailLogInConfirm
}
export default connect(null, mapDispatchToProps)(ConfirmEmail)
