export const ADMIN_SET_ISADMIN = 'admin:setIsAdmin'
export const ADMIN_LOAD_STATS = 'admin:loadStats'

export const ADMIN_LOAD_USERS = 'admin:loadUsers'
export const ADMIN_LOAD_REQUESTS = 'admin:loadRequests'
export const ADMIN_LOAD_BUSINESSES = 'admin:loadBusinesses'
export const ADMIN_LOAD_EMAILS = 'admin:loadEmails'

export const APP_WAKEUP_TIMESTAMP = 'app:wakeupTimestamps'
export const APP_LAST_UPDATED = 'app:lastUpdated'
export const APP_LOAD_SETTINGS = 'app:loadSettings'

export const AUTH_CHANGE_LOGIN_OPTION = 'auth:changeLoginOption'
export const AUTH_LOAD_LOGIN_OPTIONS = 'auth:loadLoginOptions'
export const AUTH_LOGIN_USER = 'auth:loginUser'
export const AUTH_LOGOUT_USER = 'auth:logoutUser'
export const AUTH_USER_INFO = 'auth:userInfo'

export const FEED_REQUESTS_UPDATE = 'feed:requestsUpdate'
export const FEED_REQUESTS_DELETE = 'feed:requestsDelete'

export const BUSINESS_INFO = 'business:info'
export const BUSINESS_RESET = 'business:reset'
export const BUSINESS_SET = 'business:set'
export const BUSINESS_LOCATION_SET = 'business:setLocation'
export const BUSINESS_FEED_DELETE = 'business:feedDelete'
export const BUSINESS_FEED_HIDE = 'business:feedHide'
export const BUSINESS_FEED_UPDATE = 'business:feedUpdate'
export const BUSINESS_RESPONSES_UPDATE = 'business:responsesUpdate'
export const BUSINESS_SUBSCRIPTION_UPDATE = 'business:subscriptionUpdate'
//--------------------------------------------