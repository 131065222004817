import { useNavigate } from 'react-router-dom'

//import Permission from '../FBMessaging/Permission'

import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Text from '@mui/material/Typography'

function RequestSubmitted({handleAction, requestId}) {
   const navigate = useNavigate()

   const handleViewResponses = () => {
      navigate('/feed/responses?id='+requestId)
      handleAction('cancel')
   }   
   
   return (
      <div style={{paddingTop:20, paddingBottom:20, maxWidth:400, margin:'0 auto'}}>
         <Text variant='h6'>REQUEST SUBMITTED</Text>
         <Text color='text.secondary'> 
            Your request has been submitted, responses would be posted on 
            the response page.
         </Text>

         {/*
         <Permission
            cb={()=>{}} // callback function, default=empty function 
            subscription='responses'
            page={'embedded'} // popup, embedded
            pageId='requestSubmittedEmbed'
            //pageTitle={''}
            //pageMessage={''}
         />
         */}


         <div style={{paddingTop:50}}>
         <Stack direction='row' spacing={2} justifyContent='flex-end' divider={<Divider orientation='vertical' flexItem />} >
            <Button onClick={() => { handleAction('cancel') }} color='primary' autoFocus>
               Close
            </Button>
            <Button onClick={() => { handleViewResponses() }} variant='contained' color='primary' type='submit'>
               go to response page
            </Button>
         </Stack> 
         </div>
      </div>
   )
}
export default RequestSubmitted
