import { FEED_REQUESTS_UPDATE, FEED_REQUESTS_DELETE } from '../types'

const initialState = {
	requests: {}
}

export default function reducer(state = initialState, action) {
  	switch (action.type) {
		case FEED_REQUESTS_UPDATE:
         return { 
				...state,
				requests: {
               ...state.requests,
               [action.payload.id]: action.payload.details
            }
			}
		case FEED_REQUESTS_DELETE:
	      const copy = {...state.requests}  // create copy of state object
         delete copy[action.id]  // remove key from object
         return { 
				...state,
				requests: copy
			}
		default:
			return state
	}
}
