import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { replyToRequest } from '../../../redux/business/actions'
import { replyToResponse } from '../../../redux/feed/actions'
import ImageSelector from '../../components/ImageSelector'

import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import IconClose from '@mui/icons-material/Close'
import LinearProgress from '@mui/material/LinearProgress'
import Stack from '@mui/material/Stack'
import Text from '@mui/material/Typography'
import TextField from '@mui/material/TextField'

function ReportPopup({ openWindow, setOpenWindow, replyType, businessId, requestId, responseId, replyToRequest, replyToResponse }){
   const [step, setStep] = useState('reply') // processing, error, reply
   const [disableReplyButton, setDisableReplyButton] = useState(true)
   const [headerText, setHeaderText] = useState('') 
   const [errorTitle, setErrorTitle] = useState('') 
   const [errorMessage, setErrorMessage] = useState('') 
   const [replyMessage, setReplyMessage] = useState('') 
   const [optionalImage, setOptionalImage] = useState(false) 

   // [START]: ImageSelector Variables & Function :::::::::
      const [selectedImage, setSelectedImage] = useState('/images/defaultBusinessImage.jpg') // ImageSelector variable
      const images = [ '/images/defaultBusinessImage.jpg' ] // ImageSelector variable
      const handleSelectImage = (src) => {
         setSelectedImage(src)
      }
   // [END]: ImageSelector Variables & Function ::::::::

   const handleChange = (f,v) => {
      f(v)
      if(v === ''){
         setDisableReplyButton(true)
      } else {
         setDisableReplyButton(false)
      }
   }
   const handleImage = (action) => {
      if(action === 'addImage'){
         setOptionalImage(true)
      } 
      if(action === 'removeImage'){
         setOptionalImage(false)
      }
      setSelectedImage('/images/defaultBusinessImage.jpg')
   }
   const handleSubmitReply = () => {
      let reply = {
         businessId: businessId,
         requestId: requestId, 
         responseId: responseId,
         message: replyMessage,
         image: (selectedImage === '/images/defaultBusinessImage.jpg') ? '' : selectedImage
      }
      setStep('processing')
      
      if (replyType === 'Reply To Response') {
         replyToResponse(reply)
         .then(()=> {
            setOpenWindow(false) 
         })
         .catch((e) => {
            setErrorTitle(e.title)
            setErrorMessage(e.message)
            setStep('error') 
         })
      } else {
         replyToRequest(reply)
         .then(()=> {
            setOpenWindow(false) 
         })
         .catch((e) => {
            setErrorTitle(e.title)
            setErrorMessage(e.message)
            setStep('error') 
         })
      }
   }

   const handleClose = (event,reason) => {
      if(reason === 'backdropClick') {
         return false
      }   
      setOpenWindow(false)
   }
   return (
      <Dialog fullWidth scroll='body' open={openWindow} onClose={handleClose} style={{maxWidth:400, margin:'0 auto'}}>
         <DialogTitle>
            <Text style={{color:'#999'}}>{replyType}</Text>
            <IconButton onClick={() => setOpenWindow(false) } sx={{ position: 'absolute', right: 8, top: 8, color: '#999'}}>
               <IconClose />
            </IconButton>
         </DialogTitle >
         <DialogContent style={{padding:10}} dividers>
            
            {(step === 'processing') ? (
               <div style={{paddingTop:20, paddingBottom:50, maxWidth:400, minHeight:300, margin:'0 auto'}}>
                 Processing your reply...
                 <LinearProgress color='inherit' />
               </div>
            ):( null )}
         
            {(step === 'error') ? (
               <div style={{paddingTop:20, paddingBottom:50, maxWidth:400, minHeight:300, margin:'0 auto'}}>
                 <Alert severity='error'>
                     <AlertTitle>{errorTitle || 'Error'}</AlertTitle>
                     {errorMessage || 'Sorry, an error occured, please try again.'}
                  </Alert>
                  <div style={{ marginTop:20 }}>
                     <Button onClick={() => setStep('reply') } variant='outlined' color='secondary'>try again</Button>
                  </div>
               </div>
            ):( null )}  
           
            {(step === 'reply') ? (
               <div style={{ paddingBottom:50, maxWidth:400, margin:'0 auto'}}>
                  <Stack spacing={2}>
                     <div>
                        <TextField 
                           fullWidth multiline rows={3}
                           size='small' label='Enter Reply' type='text' margin='normal'
                           value={replyMessage} onChange={(e) => {handleChange(setReplyMessage, e.target.value) }}
                        />
                        {(optionalImage) ? (
                           <div align='right'>
                              <div style={{marginTop:10, marginBottom:0}}>
                                 {/** ImageSelector Starts Here */}
                                 <div align='center' style={{ margin:'0 auto'}}>
                                    <ImageSelector 
                                       imgBorder='1px solid #ccc'
                                       imgBorderRadius={10}
                                       imgPadding={5}
                                       imgRatio='16:9' 
                                       images={images}
                                       currentImage={selectedImage}
                                       selectImage={handleSelectImage}
                                       hideSelector={true}
                                    />
                                 </div>
                                 {/** ImageSelector Ends Here */} 
                              </div>
                              <Button onClick={()=>{ handleImage('removeImage') }} variant='text' size='small'>Remove Image</Button>
                           </div>
                        ):(
                           <div align='right'><Button onClick={()=>{ handleImage('addImage') }} variant='text' size='small'>Add Image</Button></div>
                        )}
                     </div>
                     <Button style={{marginTop:50}} disabled={disableReplyButton} onClick={() => handleSubmitReply() } color='primary' variant='contained'>
                        submit reply
                     </Button>
                     <Button onClick={() => setOpenWindow(false) } color='secondary' variant='outlined'>
                        cancel
                     </Button>
                  </Stack>
               </div>
            ):( null )} 

         </DialogContent>
      </Dialog>
   )
}
const mapDispatchToProps = {
   replyToRequest, 
   replyToResponse
}
export default connect(null, mapDispatchToProps)(ReportPopup)
