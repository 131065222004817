import { useState } from 'react' 

import Loader from '../components/Loader'

import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import IconHide from '@mui/icons-material/VisibilityOff'
import Stack from '@mui/material/Stack'
import Text from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'

function Feed({businessFeed, respondToRequest, handleNewResponse, handleOpenImage, hideRequest}) {
   const conditionColor = {'New':'#40be25', 'Used':'#D8B522', 'Both':'#1cc7de'}
   const conditionText = {'New':'New Only', 'Used':'Used Only', 'Both':'New or Used'}
   const [loading, setLoading] = useState(false)

   const handleResponse = (id) => {
      handleNewResponse(id)
   }
   const handleRespondLater = (id) => {
      setLoading(true)
      respondToRequest(id)
      .finally(() => {
         setLoading(false)
      })
   }
   const handleHideRequest = (id) => {
      hideRequest(id)
   }

   return (
      <div>
         <Loader loading={loading} title='Processing' text='processing your request...'/>
         {(businessFeed.length) ? (
            <div>
               {businessFeed.map((rec, key) => {
                  return (
                     <Card key={key} elevation={0} style={{border:'2px solid gray', maxWidth:600, margin:'0 auto', marginTop:25}}>
                        <CardContent>
                           <div style={{position: 'relative'}}>
                              <div style={{position:'absolute', right:0, top:-15}}>                              
                                 <Tooltip title='Hide This Request'>
                                    <IconButton style={{color:'#d3d3d3'}} edge='end' onClick={() => {handleHideRequest(rec.requestId) }}>
                                       <IconHide />
                                    </IconButton>
                                 </Tooltip>
                              </div>
                           </div>
                           <Grid container justifyContent='center' alignItems='center' spacing={0}>
                              <Grid item xs={12} sm={6} style={{textAlign:'center'}}>
                                 <Grid container justifyContent='center' alignItems='center' spacing={0.5}>
                                    <Grid item xs={4} style={{textAlign:'right'}}>
                                       <Text variant='body2' color='text.secondary'>VIN/Year:</Text> 
                                    </Grid>
                                    <Grid item xs={8} style={{textAlign:'left'}}>       
                                       <Text variant='body2'>{rec.year}</Text>           
                                    </Grid> 
                                    <Grid item xs={4} style={{textAlign:'right'}}>
                                       <Text variant='body2' color='text.secondary'>Make:</Text> 
                                    </Grid>
                                    <Grid item xs={8} style={{textAlign:'left'}}>       
                                       <Text variant='body2'>{rec.make}</Text>           
                                    </Grid> 
                                    <Grid item xs={4} style={{textAlign:'right'}}>
                                       <Text variant='body2' color='text.secondary'>Model:</Text> 
                                    </Grid>
                                    <Grid item xs={8} style={{textAlign:'left'}}>       
                                       <Text variant='body2'>{rec.model}</Text>           
                                    </Grid> 
                                 </Grid>
                              </Grid>
                              <Grid item xs={12} sm={6} style={{textAlign:'center'}}>
                                 <Grid container justifyContent='center' alignItems='center' spacing={0.5}>
                                    <Grid item xs={4} style={{textAlign:'right'}}>
                                       <Text variant='body2' color='text.secondary'>Request ID:</Text> 
                                    </Grid>
                                    <Grid item xs={8} style={{textAlign:'left'}}>       
                                       <Text variant='body2'><i>{rec.requestId}</i></Text>            
                                    </Grid>
                                    <Grid item xs={4} style={{textAlign:'right'}}>
                                       <Text variant='body2' color='text.secondary'>Condition:</Text> 
                                    </Grid>
                                    <Grid item xs={8} style={{textAlign:'left'}}>       
                                       <Text variant='body2' component='span' style={{paddingLeft:5, paddingRight:5, borderRadius:5, backgroundColor:conditionColor[rec.condition], color:'#fff'}}>{conditionText[rec.condition]}</Text>            
                                    </Grid> 
                                 </Grid>
                              </Grid>                              
                              <Grid item xs={12}>
                                 <div style={{display:'flex', margin:5, padding:5, borderTop:'1px solid gray', borderBottom:'2px solid darkgray'}}>
                                    <img onClick={() => {handleOpenImage(rec.image)}} src={rec.image} alt='TellTommi' style={{float:'left', cursor: 'pointer', maxWidth:100, margin:5, padding:2, border:'1px solid gray'}}/>  
                                    <div>
                                       <Text variant='body2' color='text.secondary'><b><i>Part Description</i></b></Text>
                                       <Text variant='body2'>{rec.part}</Text> 
                                    </div>
                                 </div>   
                              </Grid>
                           </Grid>                     
                        </CardContent>
                        <CardActions>
                           <Stack style={{marginTop:10, margin:'0 auto'}} direction="row" justifyContent="center" alignItems="center" spacing={2} >
                              <Button onClick={() => { handleResponse(rec.requestId) }} variant='contained' color='primary' size='small'>Respond to this Request</Button>
                              {(!rec.hasResponse) ? (
                                 <Button onClick={() => { handleRespondLater(rec.requestId) }} variant='outlined' color='primary' size='small'>Respond Later</Button>
                              ):(null)}
                           </Stack>
                        </CardActions>
                     </Card>
                  )
               })}
            </div>
         ) : (
            <div style={{marginTop:20}}>
               <div align='center' style={{margin:0, marginBottom:100, padding:50, backgroundColor:'#f1f1f1'}}>
                  <Text variant='body1' color='textSecondary'>Active Requests Would Be Listed Here</Text>
               </div>
            </div> 
         )} 
      </div>
   )
}
export default Feed
